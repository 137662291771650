import React from 'react';
import styled from 'styled-components';

import { t } from '../../../assets/settings';

const FadeLink = (props) => {

  const {
    index,
    pivot
  } = props;

  const getSize = (index, pivot) => {

    if (pivot) {

      return {
        opacity: 1 - (Math.abs(pivot - index) * .4),
        scale: 1 - (Math.abs(pivot - index) * .1)
      }
    }

    return {
      opacity: 1,
      scale: 1
    }
  }

  return (
    <StyledFadeLink
      size={getSize(index, pivot)}
    >
      {props.children}
    </StyledFadeLink>
  );
}


const StyledFadeLink = styled.div`
  opacity: ${props => props.size.opacity > 0 ? props.size.opacity : .1};
  transform: scale(${props => props.size.scale > .7 ? props.size.scale : .7});
  transform-origin: left;
  transition: .5s ${t.e};
`;

export default FadeLink;
