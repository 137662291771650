import React from 'react';
import Component from '../../Component';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { at, position } from '../../../assets/utilities';

// context
import MobileContext from '../../../context/mobile';

const getRotation = (value, capacity) => {
  const mid = capacity / 2,
        ratio = value <= mid ? (value - mid) / capacity : Math.abs(mid - value) / capacity;

  return (ratio / 10).toFixed(3);
}

class IntroShape extends Component {

  static contextType = MobileContext;

  constructor(props) {
    super(props);
    this.state = {
      winHeight: null,
      winWidth: null,
      rotation: ''
    };
  }

  getWindowDimensions = () => {
    const { innerHeight, innerWidth } = this.window;
    this.setState({
      winHeight: innerHeight,
      winWidth: innerWidth
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.context) return;

    // remaps mouse coordinates into quadrants
    // x dependent on pageY; y on pageX
    const { x, y } = nextProps.mouse,
          { winWidth, winHeight } = this.state,
          rX = getRotation(y, winHeight),
          rY = getRotation(x, winWidth),
          rotation = `perspective(1000px) rotateX(${rX}turn) rotateY(${rY}turn)`;

    this.setState({ rotation });
  }

  componentDidMount() {
    this.getWindowDimensions();
    this.window.addEventListener('resize', this.getWindowDimensions);
  }

  componentWillUnmount() {
    this.window.removeEventListener('resize', this.getWindowDimensions);
  }

  render() {

    const {
      image,
      ...props
    } = this.props;

    return (
      <StyledIntroShape
        style={{ transform: this.state.rotation }}
        {...props}
      >
        <Image media={image} />
      </StyledIntroShape>
    );
  }
}


const StyledIntroShape = styled.div`
  ${position('absolute', -25, -100, 'auto', 'auto')};
  transition: 1s ease-out;
  width: 100%;

  @media ${at('desktop')} {
    right: ${props => props.shapeRight ? props.shapeRight : 'auto'};
    left: ${props => props.shapeLeft ? props.shapeLeft : 'auto'};
    min-width: 0;
    top: ${props => props.shapeTop ? props.shapeTop : 'auto'};
    width: ${props => props.shapeWidth ? props.shapeWidth : 'auto'};
    bottom: ${props => props.shapeBottom ? props.shapeBottom : 'auto'};
  }

  & > img {
    width: 100%;
    height: auto;
  }
`;

export default IntroShape;
