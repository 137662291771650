import React from 'react';
import Component from '../Component';
import { Image, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import parallax from '../../animations/general/parallax';

import { type, spacing, t, colors } from '../../assets/settings';
import { at, icon, hasProp, position, webpize } from '../../assets/utilities';

import Tag from '../_internal/Tag';
import SplitText from '../_internal/SplitText';
import ActionTag from '../_internal/ActionTag';
import SiteLink from '../_internal/SiteLink';
import Observe from '../_internal/Observe';

class BlockSplit extends Component {

  constructor(props) {
    super(props);
    this.parallaxVideoRef = React.createRef();
    this.state = {
      baseline: null
    }
  }

  handleScroll = (e) => {
    const { baseline } = this.state;

    if (baseline) {
      parallax(this.parallaxVideoRef.current, -((this.window.pageYOffset - baseline) / 3));
    }
  }

  positiveObserved = () => {
    if (!this.state.baseline) {
      this.setState({
        baseline: this.window.pageYOffset
      });
    }
  }

  componentDidMount() {
    if (this.parallaxVideoRef.current) {
      this.window.addEventListener('scroll', this.handleScroll);
      this.window.addEventListener('resize', this.handleScroll);
    }
  }

  componentWillUnmount() {
    this.window.removeEventListener('scroll', this.handleScroll);
    this.window.removeEventListener('resize', this.handleScroll);
  }

  render() {

    const {
      bgColor,
      bgImage,
      heading1,
      heading2,
      description,
      image,
      mobileImage,
      bgStyle,
      tagText,
      separator,
      link,
      parallaxVideo
    } = this.props.fields;

    const mobileWebp = webpize(mobileImage),
          desktopWebp = webpize(image);

    const imageLeft = getFieldValue(this.props.fields, 'imageLeft'),
          whiteText = getFieldValue(this.props.fields, 'whiteText'),
          overlapTop = getFieldValue(this.props.fields, 'overlapTop'),
          overlapBottom = getFieldValue(this.props.fields, 'overlapBottom');

    return (
      <StyledBlockSplit
        bgColor={hasProp(bgColor) && bgColor.value}
        bgStyle={hasProp(bgStyle) && bgStyle.value}
        imageLeft={imageLeft}
        whiteText={whiteText}
        overlapTop={overlapTop}
        overlapBottom={overlapBottom}
        parallaxVideo={hasProp(parallaxVideo)}
      >
        {
          hasProp(bgImage) &&
          <div className="background">
            <Image media={bgImage} />
          </div>
        }
        <Tag text={tagText} location={imageLeft ? 'right' : 'left'} />

        <div className="text">
          <SplitText
            tag={'h3'}
            heading1={heading1}
            separator={separator}
            heading2={heading2}
            description={description}
          />
          {
            hasProp(link) &&
            <ActionTag
              className={'link'}
              reverse={true}
              lineColor={colors.orange}
            >
              <SiteLink to={link.value.href}>
                {link.value.text}
              </SiteLink>
            </ActionTag>
          }
        </div>

        <div className="image">
          <picture className="image-main">
            {
              hasProp(mobileImage) &&
              <>
                <source srcSet={mobileWebp} media={`(max-width: 800px)`} type="image/webp" />
                <source srcSet={mobileImage.value.src} media={`(max-width: 800px)`} />
              </>
            }
            <source srcSet={desktopWebp} type="image/webp" />
            <Image media={image} />
          </picture>
          {
            hasProp(parallaxVideo) &&
            <Observe
              options={{ rootMargin: '0px' }}
              callback={this.positiveObserved}
            >
              <div className="video-parallax" ref={this.parallaxVideoRef}>
                <div className="video-parallax-inner">
                  <video src={parallaxVideo.value.src} loop muted autoPlay playsInline></video>
                </div>
              </div>
            </Observe>
          }
        </div>
      </StyledBlockSplit>
    );
  }
}

const StyledBlockSplit = styled.div`
  position: relative;
  padding-top: 40px;
  background: ${props => props.bgColor};
  color: ${props => props.whiteText ? 'white' : 'auto'};

  @media ${at('desktop')} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${props => props.imageLeft ? 'row-reverse' : 'row'};
    padding-top: 0;
  }

  + div {

    @media ${at('desktop')} {
      padding-top: ${props => props.overlapBottom ? '100px' : 'auto'};
    }
  }

  & > .background {
    ${position('absolute', 0, 0, 0, 0)};
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
  }

  .image {
    position: relative;
    z-index: 2;

    @media ${at('desktop')} {
      width: 62%;
      margin-top: ${props => props.overlapTop ? '-60px' : 'auto'};
      margin-bottom: ${props => props.overlapBottom ? '-60px' : 'auto'};
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }

    .image-main {

      & > img {
        padding: ${props => props.parallaxVideo ? '0 50px 75px 0' : 0};
      }
    }

    .video-parallax {
      position: absolute;
      bottom: 20px;
      right: ${props => props.imageLeft ? '15px' : 'auto'};
      left: ${props => props.imageLeft ? 'auto' : '15px'};
      width: 30%;
      max-width: 200px;
      border-radius: 4px;
      box-shadow: -20px 20px 40px 0 rgba(0, 0, 0, .2);

      @media ${at('desktop')} {
        bottom: 100px;
        right: ${props => props.imageLeft ? '30px' : 'auto'};
        left: ${props => props.imageLeft ? 'auto' : '30px'};
      }

      &::before {
        content: '';
        display: block;
        padding-bottom: 71%;
      }

      &-inner {
        ${position('absolute', -2, -2, -2, -2)};
        overflow: hidden;

        & > video {
          ${position('absolute', 0, 'auto', 'auto', 0)};
          min-width: 100%;
          min-height: 100%;
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }

  .text {
    text-align: center;
    padding: 0 ${spacing.mobile.x}px;

    @media ${at('desktop')} {
      width: 360px;
      padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
      margin-left: ${props => props.imageLeft ? 0 : 100}px;
      margin-right: ${props => props.imageLeft ? 100 : 0}px;
      text-align: left;
    }

    @media ${at('desktop')} {
      max-width: none;
      width: 38%;
      margin-left: ${props => props.imageLeft ? 0 : 150}px;
      margin-right: ${props => props.imageLeft ? 150 : 0}px;
    }

    .link {
      margin-top: 40px;

      a {
        display: inline-block;
        font-size: 20px;
        color: ${props => props.whiteText ? 'white' : colors.bodyText};

        &::after {
          ${icon('arrow-right')};
          ${position('relative', 2)};
          margin-left: 10px;
          font-size: 10px;
        }
      }
    }
  }

  .split-text {
    margin-bottom: 20px;

    @media ${at('desktop')} {
      margin: 0;
    }

    .heading {
      font-size: ${type.h2};
    }
  }

  /* ANIMATION */
  &.-animate-scroll-on {

    .split-text,
    .image {
      opacity: 1;
      transform: none;
    }
  }

  .split-text,
  .image {
    opacity: 0;
    transform: translateY(50px);
  }

  .split-text {
    transition: ${t.t} ${t.e} ${t.d};
  }

  .image {
    transition: ${t.t} ${t.e};
  }
`;

export default BlockSplit;
