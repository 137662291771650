export const forms = [
  {
    trigger: 'Start a Project',
    id: 'form0',
    sections: [
      [
        {
          customClass: '-half -first',
          required: true,
          type: 'text',
          label: 'First Name',
          name: 'FirstName',
          id: 'fn1'
        },
        {
          customClass: '-half',
          required: true,
          type: 'text',
          label: 'Last Name',
          name: 'LastName',
          id: 'ln1'
        },
        {
          type: 'email',
          required: true,
          label: 'Email Address',
          pattern: '.*@.*\\.[a-z]{2,3}',
          patternMsg: 'Invalid email format',
          name: 'EmailAddress',
          id: 'ea1'
        },
        {
          type: 'tel',
          required: true,
          label: 'Phone',
          name: 'Phone',
          id: 'p1'
        },
        {
          type: 'text',
          required: true,
          label: 'Organization',
          name: 'Organization',
          id: 'o1'
        },
        {
          type: 'file',
          required: false,
          label: 'RFP or Documentation (20MB)',
          name: 'RFP',
          id: 'rfp1'
        },
        {
          type: 'text',
          required: false,
          label: 'Budget',
          name: 'Budget',
          id: 'b1'
        },
        {
          type: 'text',
          required: true,
          label: 'What color is the sky?',
          pattern: '[Bb][Ll][Uu][Ee]',
          patternMsg: 'Just type "blue", it\'s for security',
          name: 'SecurityAnswer',
          id: 's1'
        }
      ],
      [
        {
          customClass: '-last',
          type: 'textarea',
          label: 'Message or Project Description',
          name: 'Message',
          id: 'm1',
          placeholder: '(If it\'s helpful, some questions we often ask center around preferred technology, target launch date, your top goals of the project and examples of projects you admire.)'
        }
      ]
    ]
  },
  {
    trigger: 'Say Hello',
    id: 'form1',
    sections: [
      [
        {
          customClass: '-half -first',
          required: true,
          type: 'text',
          label: 'First Name',
          name: 'FirstName',
          id: 'fn2'
        },
        {
          customClass: '-half',
          required: true,
          type: 'text',
          label: 'Last Name',
          name: 'LastName',
          id: 'ln2'
        },
        {
          type: 'email',
          required: true,
          label: 'Email Address',
          pattern: '.*@.*\\.[a-z]{2,3}',
          patternMsg: 'Invalid email format',
          name: 'EmailAddress',
          id: 'ea2'
        },
        {
          type: 'text',
          required: true,
          label: 'What color is the sky?',
          pattern: 'blue',
          patternMsg: 'Just type "blue", it\'s for security',
          name: 'SecurityAnswer',
          id: 's2'
        },
        {
          customClass: '-last',
          type: 'textarea',
          label: 'Message',
          name: 'Message',
          id: 'm2'
        }
      ]
    ]
  }
]
