import React, { Component } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';
import { TweenMax } from 'gsap';

import { type, t } from '../../assets/settings';
import { at, hasProp, position, hover } from '../../assets/utilities';
import arrow from '../../assets/img/arrow.svg';

// components
import StaticLeft from '../_internal/StaticLeft';
import StaticRight from '../_internal/StaticRight';
import DynamicTag from '../_internal/DynamicTag';
import DualImage from '../_internal/DualImage';
import IntroShape from '../_internal/IntroShape';
import ArrowLink from '../_internal/ArrowLink';
import MouseMove from '../_internal/MouseMove';
import Cat from '../_internal/Cat';

class StaticPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      catHidden: true
    }
  }

  handleMouseMove = (el, mouse) => {

    if (el && mouse) {

      if (!mouse.isActive && !this.state.catHidden) {
        this.setState({ catHidden: true });
      } else if (mouse.isActive) {

        const css = {
          left: mouse.x,
          top: mouse.y
        }

        if (this.state.catHidden) {
          TweenMax.to(el, 0, { css, onComplete: () => this.setState({ catHidden: false }) })
        } else {
          TweenMax.to(el, .3, { css });
        }
      }
    }
  }

  render() {

    const {
      title1,
      title2,
      detail,
      image,
      link,
      shapeImage
    } = this.props.fields;

    return (
      <StyledStaticPanel
        catHidden={this.state.catHidden}
        className="panel"
      >
        <StaticLeft>
          <div className="text">
            <Text tag="div" className="text-title" field={title1} />
          </div>
          <Text tag="p" className="detail" field={detail} />
        </StaticLeft>
        <MouseMove
          relativeX={true}
          relativeY={true}
          render={(mouse) => (
          <DynamicTag
            Tag={hasProp(link) ? 'a' : 'div'}
            href={hasProp(link) ? link.value.href : null}
          >
            <StaticRight>
              {
                hasProp(image) &&
                <DualImage desktop={image} />
              }
              <div className="text">
                <span className="title-left"><Text field={title1} /> </span><span className="title-right"><Text field={title2} /></span>
              </div>
              <Text tag="p" className="detail" field={detail} />
              {
                hasProp(link) &&
                <ArrowLink
                  href={link.value.href}
                >
                  {link.value.text}
                </ArrowLink>
              }
              {
                hasProp(shapeImage) && this.props.params &&
                <IntroShape
                  image={shapeImage}
                  mouse={mouse}
                  {...this.props.params}
                />
              }
              {
                hasProp(link) &&
                <Cat
                  mouse={mouse}
                  handleMouseMove={this.handleMouseMove}
                ></Cat>
              }
            </StaticRight>
          </DynamicTag>
        )} />
      </StyledStaticPanel>
    );
  }
}

const StyledStaticPanel = styled.div`
  display: flex;
  text-align: center;

  @media ${at('desktop')} {
    height: 95vh;
    text-align: left;

    .cat {
      display: none;

      @media ${at('desktop')} {
        display: block;
        ${position('absolute', '50%', 'auto', 'auto', '50%')};
        opacity: ${props => props.catHidden ? 0 : 1};
        transform: translate(-33%, -40%);
        width: 42px;
        height: 42px;
        border: 2px solid white;
        border-radius: 50%;
        transition: opacity .5s ${t.e};
        z-index: 2;
      }
    }
  }

  .mouse-tracker {
    flex: 1 0 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${at('desktop')} {
      flex-direction: row;
      justify-content: flex-start;
      flex-basis: 70%;
    }

    & > * {
      flex: 1 1 100%;
    }

    .right {
      flex: none;
      width: 100%;
      height: 100%;
    }

    a {

      ${hover()} {

        @media ${at('desktop')} {
          cursor: url(${arrow}), auto;
        }
      }

      * {
        pointer-events: none;
      }
    }
  }

  &:first-child {

    .right {
      padding: 114px 25px;

      @media ${at('desktop')} {
        padding-left: 20px;
        padding-right: 20px;
      }

      .detail {
        margin-bottom: 0;
      }
    }

    .left,
    .right {

      .text-title,
      .title-left,
      .title-right {
        font-size: ${type.h1};
      }
    }
  }

  .arrow-link {
    ${position('relative', 2)};

    .inner {
      margin: 0 auto;
    }

    @media ${at('desktop')} {
      display: none;
    }
  }
`;

export default StaticPanel;
