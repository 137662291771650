import React from 'react';
import Component from '../Component';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { spacing, t, colors, type } from '../../assets/settings';
import { at, icon, position, hasProp, readQueryString, getData } from '../../assets/utilities';
import defaultImage from '../../assets/img/random3.jpg';

import DualImage from '../_internal/DualImage';
import Footer from '../_internal/Footer';
import ActionTag from '../_internal/ActionTag';

class StandardPage extends Component {

  constructor(props) {
    super(props);
    this.apiNamespace = process.env.REACT_APP_API;
    this.state = {};
    this.queryParams = readQueryString(this.window.location ? this.window.location.search : '');
    this.shortcode = this.queryParams['shortcode'];
    this.application_url = this.queryParams['application_url'];
    this.jobUrl = this.apiNamespace + 'job?shortcode=' + this.shortcode;
  }

  getJobData = () => {
  
    getData(this.jobUrl)
      .then(jsonData => {
        const job = jsonData && jsonData.Success ? jsonData.Job : null;

        if (job) {
          this.setState({
            title1: null,
            title2: {
              value: job.title || 'No title provided.'
            },
            contentMarkup: {
              value: job.full_description || 'No description provided.'
            },
            applicationUrl: this.application_url
          });
        }
      })
      .catch(err => console.error(err));
  }

  componentDidMount() {

    if (this.shortcode && this.application_url) {
      this.getJobData();
    } else if (this.props.fields) {

      const {
        heroImage,
        title1,
        title2,
        contentMarkup
      } = this.props.fields;

      this.setState({
        heroImage,
        title1,
        title2,
        contentMarkup
      });
    }
  }

  render() {

    const {
      heroImage,
      title1,
      title2,
      contentMarkup,
      applicationUrl
    } = this.state;

    return (
      <StyledStandardPage>
        {
          hasProp(title2) &&
          <Helmet>
            <title>{title2.value} | Interactive Strategies - Washington, DC</title>
            <meta property="og:title" content={title2.value} />
          </Helmet>
        }
        <div className="hero -hero">
          {
            hasProp(heroImage) ?
            <DualImage desktop={heroImage} />
            :
            <div className="default-image">
              <img src={defaultImage} alt="defaultImage" />
            </div>
          }
        </div>
        <div className="content">
            <h1 className="content-title">
              <Text field={title1} /> <Text tag="span" field={title2} />
            </h1>
            <RichText className="content-description" field={contentMarkup} />
            {
              applicationUrl &&
              <ActionTag
                className="apply-link"
                reverse={true}
              >
                <a href={applicationUrl} target="_blank" rel="noopener noreferrer">Apply now</a>
              </ActionTag>
            }
          </div>
        <Footer />
      </StyledStandardPage>
    );
  }
}


const StyledStandardPage = styled.div`

  .hero {
    position: relative;

    .default-image {
      ${position('absolute', 0, 0, 0, 0)};
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }
    }
  }

  .content {
    position: relative;
    top: -500px;
    margin: 0 auto -400px;
    padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
    max-width: 885px;
    width: calc(100% - ${spacing.mobile.x}px);
    background-color: white;
    transition: ${t.t} ${t.e};

    @media ${at('desktop')} {
      padding: 55px 75px;
    }

    &-title {
      font-size: ${type.h2};
      font-weight: 300;

      & > span {
        display:inline-block;
        font-weight: 700;
      }
    }

    &-description {

      & > p {

        & > strong {
          display: block;
          position: relative;
          font-size: 12px;
          letter-spacing: ${type.letterSpacing};
          text-transform: uppercase;
          padding: 12px 0;
          margin-bottom: 24px;
          border-bottom: 2px solid ${colors.grayBorder};

          &::before {
            content: '';
            ${position('absolute', 'auto', 'auto', 0, 0, 0, '100%')};
            height: 4px;
            width: 40px;
            background-color: ${colors.red};
          }
        }
      }

      ul,
      ol {
        line-height: ${type.lineHeight.p};

        li {
          margin-bottom: 10px;
          padding-left: 36px;
        }
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          position: relative;

          &::before {
            content: '■';
            ${position('absolute', 3, 'auto', 'auto', 18, '-50%')};
            color: ${colors.red};
            font-size: 12px;
          }
        }
      }
    }

    .apply-link {
      margin-top: 40px;

      a {
        display: inline-block;
        font-size: 20px;
        color: ${colors.bodyText};

        &::after {
          ${icon('arrow-right')};
          ${position('relative', 2)};
          margin-left: 10px;
          font-size: 10px;
        }
      }
    }
  }
`;

export default StandardPage;
