import React from 'react';
import Component from '../../Component';
import styled, { keyframes } from 'styled-components';

import { type, clamp } from '../../../assets/settings';
import { at } from '../../../assets/utilities';

class ScrollHint extends Component {

  constructor(props) {
    super(props);
    this.state = {
      didScroll: false,
      visible: null
    }
  }

  handleWheel = () => {

    this.setState({
      didScroll: true,
      visible: false
    }, this.removeWheelListener);
  }

  triggerVisible = () => {
    if (!this.state.didScroll) {
      this.setState({
        visible: true
      })
    }
  }

  removeWheelListener = () => {
    this.window.removeEventListener('wheel', this.handleWheel);
  }

  componentDidMount() {
    this.window.addEventListener('wheel', this.handleWheel);
    setTimeout(this.triggerVisible, this.props.delay || 3000);
  }

  componentWillUnmount() {
    this.removeWheelListener();
  }

  render() {
    return (
      <StyledScrollHint
        className="scroll-hint"
        visible={this.state.visible}
        vertical={this.props.vertical}
        onMouseEnter={this.triggerVisible}
      >
        <div className="scroll-hint-inner">
          SCROLL
        </div>
      </StyledScrollHint>
    );
  }
}

const scroll = keyframes`
  0% { opacity: 1; height: 8px;}
  20% { opacity: 1; height: 8px;}
  30% { transform: translateY(0); opacity: 1; height: 17px;}
  40% { opacity: 1;}
  80% { transform: translateY(17px); opacity: 0; height: 0;}
  81% { transform: translateY(0); opacity: 0; height: 8px;}
  100% { opacity: 1; height: 8px;}
`;

const StyledScrollHint = styled.div`
  display: none;

  @media ${at('desktop')} {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: ${clamp(13, 18)};
    font-weight: 900;
    letter-spacing: ${type.letterSpacing};
    width: ${props => props.vertical ? '60px' : 'auto'};
    height: ${props => props.vertical ? 'auto' : '90px'};
    writing-mode: ${props => props.vertical ? 'vertical-lr' : 'unset'};
    opacity: ${props => props.visible ? 1 : 0};
    transition: opacity 1s linear;
    pointer-events: ${props => props.visible === null ? 'all' : 'none'};
    z-index: 10;

    .scroll-hint-inner {
      position: relative;
      padding: 10px;
      padding-top: ${props => props.vertical ? 50 : 10}px;
      padding-left: ${props => props.vertical ? 10 : 35}px;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
      }

      &::before {
        top: ${props => props.vertical ? 0 : '50%'};
        left: ${props => props.vertical ? '50%' : 0};
        transform: translate(${props => props.vertical ? '-50%' : 0}, ${props => props.vertical ? 0 : '-50%'});
        width: 20px;
        height: 35px;
        border: 2px solid white;
        border-radius: 12px;
      }

      &::after {
        top: 9px;
        left: ${props => props.vertical ? 'calc(50% - 1px)' : '11px'};
        width: 2px;
        height: 8px;
        background: rgba(255,255,255,1);
        border-radius: 1px;
        animation-name: ${scroll};
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-delay: 1s;
      }
    }
  }
`;

export default ScrollHint;
