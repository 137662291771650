import React from 'react';
import Component from '../../Component';
import styled from 'styled-components';

import logoIcon from '../../../assets/img/is_logo_icon.svg';
import { at, contentConstraint, postData } from '../../../assets/utilities';
import { colors, type, spacing, breakpoints } from '../../../assets/settings';

// components
import FormSwitcher from '../FormSwitcher';
import DynamicTag from '../DynamicTag';

class ContactPanel extends Component {

  constructor(props) {
    super(props);
    this.apiNamespace = process.env.REACT_APP_API;
    // this.apiNamespace = 'http://is-react.interactivestaging.com/api/feature/';
    this.state = {
      loading: false,
      success: false,
      fileUploading: false,
      filePath: null
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const dL = this.window.dataLayer;

    if (e.target.checkValidity()) {
      const { filePath, fileName } = this.state,
            formData = new FormData(e.target),
            dataPhone = formData.get('Phone');

      this.setState({ loading: true })

      if (filePath && fileName) {
        formData.set('RFP', filePath);
        formData.set('RFPOriginalFileName', fileName);
      } else {
        formData.delete('RFP');
      }

      if (dataPhone) {
        const cleanedPhone = dataPhone.replace(/\D/g, '');
        formData.set('Phone', cleanedPhone);
      }

      if (e.target.id === 'form1') {
        formData.append('Organization', 'IS - SayHello');
      }

      postData(this.apiNamespace + 'submitcontact', formData)
        .then(jsonData => {
          this.setState({
            success: jsonData.Success,
            loading: false
          });

          if (jsonData.Success && dL) {

            dL.push({
              event: 'Contact Form Submission',
              organization: jsonData.Organization,
              zoho_id: jsonData.CRMContactId
            })
          }
        })
        .catch(err => console.error(err));
    }
  }

  handleFileUpload = (e) => {
    const { files } = e.target;

    if (files && files[0]) {
      this.setState({
        fileUploading: true
      });

      const data = {
        file: files[0]
      };

      postData(this.apiNamespace + 'uploadrfp', data)
        .then(jsonData => {
          if (jsonData.Success) {
            this.setState({
              fileUploading: false,
              filePath: jsonData.Path,
              fileName: jsonData.OriginalFileName
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            fileUploading: false,
            filePath: null,
            fileName: null
          });
        })
    }
  }

  render() {

    const {
      removeIntro,
      heading1,
      heading2,
      tag
    } = this.props;

    return (
      <StyledContactPanel className={`contact-panel ${this.state.success ? '-success' : ''}`}>
        {
          !this.state.success ?
          <div className='content'>
            {
              !removeIntro &&
              <img className="content-logo" src={logoIcon} alt="Interactive Strategies Logo"/>
            }
            {
              !removeIntro &&
              <div className="content-headline">Want to talk about a project or idea?</div>
            }
            {
              heading1 || heading2 ?
              <DynamicTag
                Tag={tag || 'div'}
                className="content-title -custom"
              >
                {
                  heading1 &&
                  <span className="heading1">{heading1}{heading1 && heading2 ? ' ' : ''}</span>
                }
                {
                  heading2 &&
                  <span className="heading2">{heading2}</span>
                }
              </DynamicTag>
              :
              <div className="content-title">Let's do this</div>
            }
            <FormSwitcher
              handleSubmit={this.handleSubmit}
              handleFileUpload={this.handleFileUpload}
              submitDisabled={this.state.fileUploading}
              loading={this.state.loading}
            />
          </div>
          :
          <div className='content'>
            <img className="content-logo" src={logoIcon} alt="Interactive Strategies Logo"/>
            <div className="content-title -custom">
              <div className="heading2">Thank you</div>
            </div>
            <div className="content-headline">We appreciate your interest in learning how we might be able to work together. We will be in touch via email or phone very shortly.</div>
          </div>
        }


      </StyledContactPanel>
    );
  }

};

const StyledContactPanel = styled.div`
  position: relative;
  flex: none;
  display: block;
  width: 100%;
  padding: 100px ${spacing.mobile.x}px 50px;
  background-color: ${colors.grayBg};
  z-index: 3;

  @media ${at('tablet')} {
    padding: 150px ${spacing.desktop.x}px 100px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    ${contentConstraint(breakpoints.desktop)};


    &-logo {
      margin-bottom: 20px;
      ${contentConstraint(80)}

      @media ${at('tablet')} {
        margin-bottom: 32px;
      }
    }

    &-headline {
      font-size: ${type.p};

      @media ${at('tablet')} {
        margin-bottom: 18px;
      }
    }

    &-title  {
      font-size: ${type.h1};
      font-weight: 900;
      margin-bottom: 20px;

      @media ${at('tablet')} {
        margin-bottom: 30px;
      }

      &.-custom {
        font-size: ${type.h2};

        .heading1 {
          font-weight: 300;
        }
      }
    }
  }

  &.-success {
    padding-top: 25vmin !important;
    padding-bottom: 25vmin !important;

    .content {
      ${contentConstraint(660)};

      &-headline {
        line-height: 1.6;
      }
    }
  }
`;

export default ContactPanel;
