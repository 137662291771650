import { Expo } from 'gsap';
import enterDefault from './enter-default';
import exitDefault from './exit-default';

export default ({ type, node, mask, exitType, isMobile, timeline }) => {

    if (type === 'enter') {
      timeline = enterDefault(timeline, mask);

      const title = document.querySelector('.split-text .heading'),
            text = document.querySelector('.split-text .description'),
            form = document.querySelector('.head-form');

      const duration = .5;

      timeline
        .from(title, duration,
          {
            y: '100px',
            autoAlpha: 0,
            ease: Expo.easeOut
          }, .3)
        .from(text, duration,
          {
            y: '100px',
            autoAlpha: 0,
            ease: Expo.easeOut
          }, .4)
        .from(form, duration,
          {
            y: '100px',
            autoAlpha: 0,
            ease: Expo.easeOut
          }, .5);
    }

    if (type === 'exit') {
      timeline = exitDefault(timeline);
    }

  return timeline;
}
