import React from 'react';
import Component from '../../Component';
import styled from 'styled-components';

import { spacing, type, gradients, clamp } from '../../../assets/settings';
import { at, position, icon, hasProp, hover } from '../../../assets/utilities';

// components
import SiteLink from '../SiteLink';
import SplitText from '../SplitText';
import DualImage from '../DualImage';

const adjustArray = (arr) => {
  const last = arr.pop(),
        preLast = arr.pop();

  return [last, preLast, ...arr];
}

class CaseCarousel extends Component {

  constructor(props) {
    super(props);
    this.track = React.createRef();
    this.state = {
      slides: adjustArray(props.carousel.items),
      canUpdateSlides: false,
      itemWidth: 0
    }
  }

  resetSliderPosition = () => {
    this.track.current.style.transition = 'none';
    this.track.current.style.transform = 'translateX(0)';
    setTimeout(() => {
      this.track.current.style.transition = `.5s ease-in-out`;
    });
  }

  shiftSlides = () => {
    this.setState(state => {
      const slides = [...state.slides],
            first = slides.shift();

      return {
        canUpdateSlides: false,
        slides: [...slides, first]
      }
    }, this.resetSliderPosition);
  }

  popSlides = () => {
    this.setState(state => {
      const slides = [...state.slides],
            last = slides.pop();

      return {
        canUpdateSlides: false,
        slides: [last, ...slides]
      }
    }, this.resetSliderPosition);
  }

  handleChangeSlide = () => {
    if (this.state.canUpdateSlides) {
      const { direction } = this.state;

      // sliding right, move first item to end
      if (direction === 1) {
        this.popSlides();
      } else if (direction === -1) {
        this.shiftSlides();
      }
    }
  }

  changeSlide = (direction) => {
    this.setState({
      canUpdateSlides: true,
      direction
    }, () => {
      const track = this.track.current,
            { slides, direction } = this.state,
            value = direction * (100 / slides.length)

      this.setState({ canUpdateSlides: true }, () => {
        track.style.transform = `translateX(${value}%)`;
      });
    });
  }

  setSize = () => {
    const winWidth = this.window.innerWidth;
    let size;

    if (winWidth < 400) {
      size = winWidth;
    } else if (winWidth < 900) {
      size = winWidth - 120;
    } else {
      size = (winWidth - 120) / 3;
    }

    this.setState({ itemWidth: size });
  }

  componentDidMount() {
    this.setSize();
    this.track.current.addEventListener('transitionend', this.handleChangeSlide);
    this.window.addEventListener('resize', this.setSize);
  }

  componentWillUnmount() {
    this.window.removeEventListener('resize', this.setSize);
  }

  render() {

    const {
      caseCarouselHeading1,
      caseCarouselHeading2
    } = this.props.carousel;

    return (
      <StyledCaseCarousel
        numItems={this.state.slides.length}
        itemWidth={this.state.itemWidth}
      >
        {
          hasProp(caseCarouselHeading1) && hasProp(caseCarouselHeading2) &&
          <SplitText
            heading1={caseCarouselHeading1}
            heading2={caseCarouselHeading2}
          />
        }
        <div className="inner">
          <button className="control previous" type="button" onClick={() => this.changeSlide(1)}>
            <span className="sr-text">previous</span>
          </button>
          <button className="control next" type="button" onClick={() => this.changeSlide(-1)}>
            <span className="sr-text">next</span>
          </button>
          <div className="track" ref={this.track}>
            {
              this.state.slides.map((item, index) => {

                const styles = {
                  transform: `translateX(${this.state.activeIndex * 100}%)`
                },
                {
                  thumbnail,
                  clientName
                } = item.fields;

                return (
                  <SiteLink
                    key={index}
                    style={styles}
                    className="case-item"
                    to={item.url}
                  >
                    <DualImage desktop={thumbnail} />
                    <span className="case-item-title">{clientName.value}</span>
                  </SiteLink>
                );
              })
            }
          </div>
        </div>
      </StyledCaseCarousel>
    );
  }
}

const StyledCaseCarousel = styled.div`

  .inner {
    position: relative;
    height: 100vw;
    max-height: 400px;
    overflow: hidden;

    @media ${at(6)} {
      max-height: 480px;
    }

    @media ${at(9)} {
      max-height: 400px;
    }

    @media ${at('desktop')} {
      max-height: 480px;
    }
  }

  .track {
    position: absolute;
    height: 100%;
    width: ${props => props.numItems * props.itemWidth}px;
    left: -${props => 2 * props.itemWidth}px;
    display: flex;
    transition: .5s ease-in-out;

    @media ${at(4)} {
      left: -${props => 2 * props.itemWidth - 60}px;
    }

    @media ${at(9)} {
      left: -${props => 2 * props.itemWidth - 60}px;
    }
  }

  .case-item {
    position: relative;
    width: ${props => props.itemWidth}px;
    height: 100%;
    display: flex;
    flex: none;
    flex-direction: column;
    padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
    background-size: cover;
    background-position: center;
    background-color: black;
    overflow: hidden;

    .dual-image {
      transition: 1.2s ease-out;

      &::before {
        content: '';
        ${position('absolute', 'auto', 0, 0, 0)};
        height: 50%;
        background: ${gradients.background.black.up};
      }
    }

    ${hover()} {

      .dual-image {
        transform: scale(1.2);
      }
    }

    @media ${at('desktop')} {
      padding: ${spacing.desktop.y}px ${spacing.desktop.x}px;
    }

    &-title {
      position: relative;
      margin-top: auto;
      font-weight: 300;
      font-size: ${clamp(22, 30)};
      z-index: 2;
    }
  }

  .control {
    width: 60px;
    height: 100%;
    ${position('absolute', 0)};
    z-index: 2;

    &::before {
      ${position('absolute', '50%', 'auto', 'auto', '50%', '-50%', '-50%')};
      color: white;
    }

    &.previous {
      left: 0;

      &::before {
        ${icon('caret-left')};
      }
    }

    &.next {
      right: 0;

      &::before {
        ${icon('caret-right')};
      }
    }
  }

  .split-text {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
    padding-left: ${spacing.mobile.x}px;
    padding-right: ${spacing.mobile.x}px;

    @media ${at('desktop')} {
      margin-top: 75px;
      margin-bottom: 56px;
      padding-left: ${spacing.desktop.x}px;
      padding-right: ${spacing.desktop.x}px;
    }

    .heading {
      font-size: ${type.h2};
    }
  }
`;

export default CaseCarousel;
