import React, { Component } from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { colors, t, gradients } from '../../assets/settings';
import { at, position, hasProp, hover } from '../../assets/utilities';

// components
import SiteLink from '../_internal/SiteLink';
import DualImage from '../_internal/DualImage';

class WorkItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
  }

  handleClick = () => {

    this.setState({
      selected: true
    });
  }

  render() {

    const {
      link,
      image,
      text,
      hFocal,
      vFocal,
      flag,
      clientLogo
    } = this.props.fields;

    return (
      <StyledWorkItem
        className={`slider-item ${this.state.selected ? '-selected' : ''}`}
        hFocal={hFocal ? hFocal.value : '50%'}
        vFocal={vFocal ? vFocal.value : '50%'}
        onClick={this.handleClick}
      >
        <SiteLink className="link" to={link.value.href}>
          <DualImage desktop={image} />
          <h2 className="text">
            <span className="text-intro">How do you </span>
            <Text tag="span" className="text-body" field={text} />
          </h2>
          {
            hasProp(clientLogo) &&
            <div className="logo">
              <Image className="logo-image" media={clientLogo} />
            </div>
          }
          {
            hasProp(flag) &&
            <div className="flag">
              <Text field={flag} />
            </div>
          }
        </SiteLink>
      </StyledWorkItem>
    );
  }
};

const StyledWorkItem = styled.div`
  position: relative;
  display: block;
  cursor: pointer;
  min-height: 500px;
  max-height: 100vh;
  overflow: hidden;
  text-align: center;
  transition: ${t.t} ${t.e};

  & > .link {
    ${position('absolute', 0, 0, 0, 0)};
    display: block;

    &::before,
    &::after {
      content: '';
      ${position('absolute', 'auto', 0, 0, 0)};
      z-index: 2;
    }

    &::before {
      height: 40%;
      background: ${gradients.background.black.up};

      @media ${at('desktop')} {
        height: 50%;
      }
    }

    &::after {
      opacity: 1;
      top: 0;
      background-color: ${gradients.overlay};
      transition: ${t.t} ${t.e};

      @media ${at('desktop')} {
        opacity: 0;
      }
    }

    & > .dual-image {
      ${position('absolute', 0, 'auto', 'auto', 0)};
      width: 100%;
      height: 100%;
      z-index: 1;

      @media ${at('desktop')} {

        img {
          object-position: ${props => `${props.hFocal} ${props.vFocal}` };
        }
      }
    }
  }

  .-overlay & {
    pointer-events: none;
  }

  &.-selected {
    z-index: 100;
    width: 100vw;
    transform: translateZ(0);
    transition: width 1s ease-out;

    .link {

      &::after {
        opacity: 1;
      }

      & > .dual-image {

        img {
          object-position: center !important;
          transition: object-position 1s ease-in-out;
        }
      }
    }

    .flag {
      opacity: 0;
      transition: .8s ${t.expo};
    }

    .logo {
      transform: translate(-50%, 100px);
      opacity: 0;
      transition: .3s ${t.expo};
    }
  }


  @media ${at('desktop')} {
    width: calc((100vw / 3) - 20px);
    flex: none;
    height: 100vh;

    ${hover()} {

      .text {

        &-intro,
        &-body {
          opacity: 1;
          transform: none;
        }

        &::before {
          opacity: 1;
        }
      }

      .link {

        &::after {
          opacity: 1;
        }
      }
    }
  }

  @media ${at(1920)} {
    width: calc((1920px / 3) - 20px);
  }

  .text {
    width: 100%;
    max-width: calc(100% - 60px);
    ${position('absolute', '38%', 'auto', 'auto', '50%', '-50%', '-50%')};
    font-size: 30px;
    color: white;
    line-height: 1.125;
    font-weight: 300;
    z-index: 4;

    &::before {
      content: '';
      ${position('absolute', 'auto', 'auto', 0, '50%', '-50%', 30)};
      width: 62px;
      height: 2px;
      background-color: ${colors.red};
    }

    @media ${at('tablet')} {
      font-size: 35px;
    }

    @media ${at('desktop')} {
      max-width: calc(100% - 80px);

      &-intro,
      &-body,
      &::before {
        display: block;
        opacity: 0;
        transition-duration: ${t.t};
        transition-timing-function: ${t.e};
      }

      &-intro {
        transform: translateY(50px);
      }

      &-body {
        transform: translateY(100px);
        transition-delay: .1s;
      }

      &::before {
        transform: translate(-50%, 30px) translateY(50px);
        transition-delay: .2s;
      }
    }

    @media ${at(14)} {
      font-size: 40px;
    }
  }

  .logo {
    height: 150px;
    ${position('absolute', 'auto', 'auto', 40, '50%', '-50%')};
    display: flex;
    align-items: center;
    z-index: 4;

    @media ${at('desktop')} {
      bottom: 80px;
    }

    &-image {
      width: 180px;
      height: auto;

      @media ${at('desktop')} {
        width: 220px;
      }
    }
  }

  .flag {
    ${position('absolute', 'auto', 0, 0, 0)};
    background-color: ${colors.red};
    color: white;
    padding: 7px 12px;
    text-align: center;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    z-index: 4;
  }
`;

export default WorkItem;
