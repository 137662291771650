import React, { Component } from 'react';
import styled from 'styled-components';

import { colors, t } from '../../../assets/settings';
import { position } from '../../../assets/utilities';

class PhoneInput extends Component {

  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      isActive: false
    };
  }

  checkIfActive = (e) => {
    if (this.input.current === document.activeElement || this.input.current.value !== '') {
      this.setState({ isActive: true });
    } else {
      this.setState({ isActive: false });
    }
  }

  cleanValueString = (str) => {
    const x = str.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.input.current.value = this.cleanValueString(value);
  }


  componentDidMount() {
    this.checkIfActive();
  }

  render() {

    const {
      id,
      name,
      label,
      required,
      tabbable
    } = this.props;

    return (
      <StyledPhoneInput
        className={`${this.props.className} ${this.state.isActive ? '-active' : ''}`}
        isRequired={required ? true : false}
      >
        {
          <input
            type="tel"
            tabIndex={tabbable ? 0 : -1}
            onChange={this.handleChange}
            onInput={this.checkIfActive}
            onBlur={this.checkIfActive}
            onFocus={this.checkIfActive}
            ref={this.input}
            id={id}
            name={name}
            required={required}
          />
        }
        <label
          htmlFor={id}
        >
          {label}
        </label>
      </StyledPhoneInput>
    );
  }
};

const StyledPhoneInput = styled.div`
  position: relative;
  margin: 0 0 10px;
  padding: 16px;
  width: 100%;
  background-color: white;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    ${position('absolute', 19, 'auto', 'auto', 16)};
    font-size: 15px;
    text-align: left;
    transition: ${t.t} ${t.e};

    &::after {
      display: ${props => props.isRequired ? 'inline' : 'none'};
      content: '*';
    }
  }

  input {
    padding-left: ${props => props.file ? '196px' : 'auto'};
    width: 100%;
    height: 100%;
  }

  &.-active {

    label {
      left: 8px;
      top: 4px;
      font-size: 11px;
      color: ${colors.teal};
      transition: ${t.t} ${t.e};
    }

    input {
      ${position('relative', 5)}
      padding-left: 0;
    }
  }
`;

export default PhoneInput;
