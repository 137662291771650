import React from 'react';

class Component extends React.Component {

  constructor(props) {
    super(props);
    this.window = typeof window !== 'undefined' ? window : {};
  }
}

export default Component;
