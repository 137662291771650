import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorId: null
    }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    })
  }

  render() {

    if (this.state.hasError) {

      return (
        <div className="error">
          <p className="error-message">
            Something went wrong. Click the button below to provide any feedback. Othewise, try refreshing the page.
          </p>
          <button className="error-button" onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Send Report and Reload</button>
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
