import { breakpoints, iconMap, spacing } from './settings';

export const at = (value) => {                                             

  const errorMsg = 'Invalid breakpoint: breakpoint but be a valid number between 1 (100px) and 22 (2200px), a specific pixel breakpoint value above 320, or a predefined breakpoint name.';

  const convertedValue = parseInt(value);
  let breakpoint;

  if (isNaN(convertedValue)) {
    if (breakpoints[value]) {
      breakpoint = breakpoints[value] + 'px';
    } else {
      throw new Error(errorMsg);
    }
  } else if (convertedValue > 320) {
    breakpoint = convertedValue + 'px';
  } else if (convertedValue <= 22 && convertedValue > 0) {
    breakpoint = convertedValue + '00px';
  } else {
    throw new Error(errorMsg);
  }

  return `(min-width: ${breakpoint})`;
}

export const icon = (value) => {
  const icon = iconMap[value.toString()];
  if (!icon) {
    throw new Error('Icon not found in iconMap.');
  }
  return `font-family: 'icomoon' !important; content: '${icon}'`;
}

export const isWindow = () => {
  return typeof window !== 'undefined';
}

export const horizontalPadding = (mobile = spacing.mobile.x, desktop = spacing.desktop.x) => {
  return (
    `padding-left: ${mobile}px; padding-right: ${mobile}px; @media(min-width: ${breakpoints.desktop}px) { padding-left: ${desktop}px; padding-right: ${desktop}px; }`
  );
}

export const negatePadding = (mobile = spacing.mobile.x, desktop = spacing.desktop.x) => {
  return (
    `margin-left: -${mobile}px; margin-right: -${mobile}px; @media(min-width: ${breakpoints.desktop}px) { margin-left: -${desktop}px; margin-right: -${desktop}px; }`
  );
}

export const contentConstraint = (maxWidth = 600) => {
  return (
    `max-width: ${maxWidth}px; margin-left: auto; margin-right: auto;`
  );
}

export const position = (position = 'static', top = 'auto', right = 'auto', bottom = 'auto', left = 'auto', tX, tY) => {
  let transform = null;

  if (tX || tY) {
    transform = 'transform:';
  }

  if (tX) {
    transform += ` translateX(${pixelate(tX)})`;
  }

  if (tY) {
    transform += ` translateY(${pixelate(tY)})`;
  }

  return (
    `position: ${position};
     top: ${pixelate(top)};
     right: ${pixelate(right)};
     bottom: ${pixelate(bottom)};
     left: ${pixelate(left)};
     ${transform ? transform : ''}
    `
  )

  function pixelate(value) {
    return typeof value === 'number' ? `${value}px`: value;
  }
}

export const hover = (active = true, focus = true) => {
  return `&:hover${active ? ', &:active' : ''}${focus ? ', &:focus' : ''}`;
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const chunkArray = (array, parts) => {
  const result = [];
  for (let i = parts; i > 0; i--) {
      result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
}

export const throttle = (cb, limit, tailCb) => {
  let wait = false;
  return function () {
    if (!wait) {
      cb(...arguments);
      wait = true;
      setTimeout(function () {
          wait = false;
          if (tailCb) {
            tailCb(...arguments);
          }
      }, limit);
    }
  }
};

export const gridItemStyles = (num) => {
  let returnStr = '';
  for (let i = 1; i <= num; i ++) {
    returnStr += `&:nth-child(${i}) {
      grid-area: item${i};
    }`;
  }
  return returnStr;
}

export const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
}

export const hasProp = (prop) => {
  if (!prop || !prop.value) return false;

  const { value } = prop;

  if (typeof value !== 'object') {
    return value;
  }

  // GeneralLink
  if (value.hasOwnProperty('href')) {
    return value['href'];
  }

  // Image
  if (value.hasOwnProperty('src')) {
    return value['src']
  }

  return Object.keys(value).length !== 0;
}

export async function postData(url = '', data = {}) {
  let formData;

  if (data instanceof FormData) {
    formData = data;
  } else {
    formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
  }

  const response = await fetch(url, {
    method: 'POST',
    body: formData
  })
  .then(res => res.json())
  .catch(err => console.log(err));

  return response;
}

export async function getData(url = '') {

  // Default options are marked with *
  const response = await fetch(url)
    .then(res => res.json())
    .catch(err => console.log(err))

  return response;
}

export const readQueryString = (searchString) => {
  const urlParams = new URLSearchParams(searchString),
        initParams = {};
  for (const [key, value] of urlParams) {
    initParams[key] = value;
  }

  return initParams;
}

export const webpize = (url) => {
  const re = /.jpg|.png/;

  if (url && re.test(url)) {
    return url.replace(re, '.webp');
  }

  return null;
}

export const getUrlParams = (win) => {
  return new URLSearchParams(win.location ? win.location.search : '');
}
