const NotFound = () => {

  const {
    protocol,
    hostname,
    port
  } = window.location;

  const errorRoute = `${protocol}//${hostname}${port ? ':' + port : ''}/404`;

  window.location = errorRoute;

  return null;
}

export default NotFound;
