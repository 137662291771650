import { Expo } from 'gsap';

export default (timeline) => {
  const exitMask = document.querySelector('.transition-mask');

  if (exitMask) {
    exitMask.style.bottom = 0;
    exitMask.style.top = 'auto';

    timeline
      .to(exitMask, .4, { height: '100vh', ease: Expo.easeOut, clearProps: 'all'});
  }

  return timeline;
}
