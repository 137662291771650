import React, { Component } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { t } from '../../../assets/settings';
import { hasProp, position, hover } from '../../../assets/utilities';

// components
import DualImage from '../DualImage';

class DesktopCarousel extends Component {

  constructor(props) {
    super(props);
    this.lastIndex = this.props.slides.length - 1;
    this.state = {
      activeIndex: 0
    }
  }

  nextSlide = () => {
    this.setState((state) => {
      const newIndex = state.activeIndex + 1;
      return {
        activeIndex: newIndex > this.lastIndex ? 0 : newIndex
      }
    })
  }

  render() {

    return (
      <StyledDesktopCarousel
        className="desktop-carousel"
        activeN={this.state.activeIndex + 1}
      >
        <button
          className="button-next icon-caret-right"
          onClick={this.nextSlide}
        >Next</button>
        <div className="slides">
          {
            this.props.slides.map((item, index) => {

              const {
                image,
                mobileImage,
                title,
                description,
                attribution,
                icon,
                giphy
              } = item.fields;

              return (
                <div className="slides-item" key={index}>
                  {
                    hasProp(image) &&
                    <div className="item-image">
                      <DualImage desktop={image} mobile={hasProp(mobileImage) && mobileImage} />
                    </div>
                  }
                  <div className="item-content" key={index}>
                    {
                      hasProp(giphy) &&
                      <div className="item-content-giphy">
                        <div className="item-content-giphy-inner">
                          <video src={giphy.value.src} loop muted autoPlay playsInline></video>
                        </div>
                      </div>
                    }
                    {
                      hasProp(title) &&
                      <Text tag="h2" className="item-content-title" field={title} />
                    }
                    {
                      hasProp(description) &&
                      <Text tag="p" className="item-content-description" field={description} />
                    }
                    {
                      hasProp(attribution) &&
                      <Text tag="div" className="item-content-attribution" field={attribution} />
                    }
                    {
                      hasProp(icon) &&
                      <div className={`item-content-icon icon-${icon.value}`}></div>
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </StyledDesktopCarousel>
    );
  }
}


const StyledDesktopCarousel = styled.div`
  ${position('absolute', 0, 0, 0, 0)};
  z-index: 1;

  .slides {
    position: relative;
    width: 100%;
    height: 100%;

    &-item {
      ${position('absolute', 0, 0, 0, 0, 'calc(100% - 70px)')};
      transition-delay: ${t.t};

      &:nth-child(${props => props.activeN}) {
        transform: none;
        transition: ${t.t} ${t.e};
        z-index: 2;

        .item-content-giphy {
          transform: translateX(-100%);
          opacity: 1;
          transition: 1s ${t.e} .15s;
        }
      }

      &:nth-child(${props => props.activeN + 1}) {
        z-index: 3;
      }
    }
  }

  .item-content {
    ${position('absolute', 'auto', 115, 75, 'auto')};
    width: 360px;
    min-height: 160px;

    &-giphy {
      ${position('absolute', 0, 'auto', 'auto', -24, '-100%')};
      width: 18vw;
      max-width: 280px;
      opacity: 0;
      box-shadow: 10px 15px 15px 0 rgba(0, 0, 0, .5);

      &::before {
        content: '';
        display: block;
        padding-bottom: 71%;
      }

      &-inner {
        ${position('absolute', 0, 0, 0, 0)};
        overflow: hidden;

        & > video {
          ${position('absolute', 0, 'auto', 'auto', 0)};
          min-width: 100%;
          min-height: 100%;
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }

    &-icon {
      ${position('absolute', 0, 'auto', 'auto', '-24px', '-100%', 10)};
      font-size: 28px;
    }

    &-title {
      margin: 0 0 15px 0;
      font-size: 40px;
      font-weight: 300;
    }

    &-attribution {
      font-size: 24px;

      &::before {
        content: '@';
      }
    }
  }

  .button-next {
    ${position('absolute', 0, 0, 0, 'auto')};
    width: 70px;
    font-size: 0;
    z-index: 4;

    &::before {
      ${position('absolute', '50%', 'auto', 'auto', '50%', '-50%', '-50%')};
      color: white;
      font-size: 20px;
      z-index: 1;
    }

    &::after {
      content: '';
      ${position('absolute', 0, 0, 0, 0)};
      background-color: black;
      opacity: .2;
      transition: ${t.t} ${t.e};
    }

    ${hover()} {

      &::after {
        opacity: .5;
      }
    }
  }
`;

export default DesktopCarousel;
