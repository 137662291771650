import { TimelineMax as Timeline } from 'gsap';
import { isWindow } from '../assets/utilities';
import enterDefault from './route-transitions/enter-default';
import exitDefault from './route-transitions/exit-default';

if (isWindow()) {
  window.loadPromise = new Promise(resolve => {
    window.addEventListener('DOMContentLoaded', resolve);
  });
}

// animation
export const transitionAnimation = ({ type, node, mask, animation, exitType, isMobile, completeFn }) => {
  const timeline = new Timeline({ paused: true }),
        isInitial = exitType ? false : true;

  let animatedTimeline;

  if (animation) {
    animatedTimeline = animation({
      type,
      node,
      mask,
      exitType,
      isMobile,
      timeline
    });
  } else {
    if (type === 'enter') {
      animatedTimeline = enterDefault(timeline, mask, isInitial);
    } else {
      animatedTimeline = exitDefault(timeline);
    }
  }



  animatedTimeline.eventCallback('onComplete', completeFn);

  if (isWindow()) {
    window.loadPromise.then(() => requestAnimationFrame(() => animatedTimeline.play()))
  } else {
    animatedTimeline.play();
  }
}
