import webby from '../../../assets/img/webby_badge.png';
import cbe from '../../../assets/img/cbe_badge.png';
import nglcc from '../../../assets/img/nglcc_badge.png';
import clutch_design from '../../../assets/img/clutch_badge_design.png';
import clutch_dev from '../../../assets/img/clutch_badge_dev.png';

export const badges = [
  {
    alt: "Webby Nominee Badge",
    src: webby,
    width: 130,
    height: 130
  },
  {
    alt: "Certified Business Enterprise Badge",
    src: cbe,
    width: 133,
    height: 116
  },
  {
    alt: "NGLCC Certified LGBTBE Badge",
    src: nglcc,
    width: 173,
    height: 62
  },
  {
    alt: "Clutch Top Designers Badge",
    src: clutch_design,
    width: 120,
    height: 130,
    href: "https://clutch.co/profile/interactive-strategies"
  },
  {
    alt: "Clutch Top Developers Badge",
    src: clutch_dev,
    width: 120,
    height: 130,
    href: "https://clutch.co/profile/interactive-strategies"
  }
]

export const seoLinks = [
  {
    text: 'Washington DC Web Design',
    href: '/services/design'
  },
  {
    text: 'Baltimore Web Design',
    href: '/services/design/baltimore-web-design'
  },
  {
    text: 'Maryland Web Design',
    href: '/services/design/maryland-web-design'
  },
  {
    text: 'Virginia Web Design',
    href: '/services/design/virginia-web-design'
  },
  {
    text: 'SEO in Washington DC',
    href: '/services/marketing/seo'
  },
  {
    text: 'SEO in Maryland',
    href: '/services/marketing/md-seo'
  },
  {
    text: 'SEO in Virginia',
    href: '/services/marketing/va-seo'
  }
]
