import React, { Component } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { at, gridItemStyles, hasProp, position } from '../../assets/utilities';

// context
import MobileContext from '../../context/mobile';

// components
import StaticLeft from '../_internal/StaticLeft';
import StaticRight from '../_internal/StaticRight';
import MobileSlider from '../_internal/MobileSlider';
import DualImage from '../_internal/DualImage';

class BenefitsPanel extends Component {

  static contextType = MobileContext;

  render() {

    const {
      image,
      title1,
      title2,
      detail,
      benefits
    } = this.props.fields;

    const benefitsChildren = benefits.map((item, index) => {
      const { text, icon } = item.fields;
      return (
        <div className="slider-item grid-item" key={index}>
          <div className="slider-item-inner">
            <div className={`grid-item-icon icon-${icon.value}`}></div>
            <Text field={text} />
          </div>
        </div>
      );
    });

    return (
      <StyledBenefitsPanel
        className="panel"
      >
        <StaticLeft>
          <div className="text">
            <Text tag="div" className="text-title" field={title1} />
          </div>
          <Text tag="p" className="detail" field={detail} />
        </StaticLeft>
        <StaticRight>
          {
            hasProp(image) &&
            <DualImage desktop={image} />
          }
          <div className="text">
            <span className="title-left"><Text field={title1} /> </span><span className="title-right"><Text field={title2} /></span>
          </div>
          <Text tag="p" className="detail" field={detail} />
          <div className="content">
            {
              this.context ?
              <MobileSlider>
                {benefitsChildren}
              </MobileSlider>
              :
              <div className="grid">
                {benefitsChildren}
              </div>
            }
          </div>
        </StaticRight>
      </StyledBenefitsPanel>
    );
  }
}

const StyledBenefitsPanel = styled.div`
  display: flex;
  text-align: center;

  @media ${at('desktop')} {
    height: 90vh;
    text-align: left;
  }

  .content {
    margin: 0 -30px;


    .slider-item-inner {
      font-size: 20px;

      @media ${at('desktop')} {
        font-size: inherit;
      }
    }
  }

  .grid {
    ${position('absolute', 0, 0, 0, 0)};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
      "item1 item2 item3"
      "..... item4 item5"
      "item6 item7 item8";
    justify-items: center;
    align-items: center;
    text-align: center;

    &-item {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.5;
      ${gridItemStyles(8)};

      @media ${at('desktop')} {
        max-width: 140px;
        height: 30vh;
      }

      @media ${at(14)} {
        max-width: none;
      }

      &-icon {
        text-align: center;
        font-size: 47px;
        margin-bottom: 20px;

        @media ${at('desktop')} {
          font-size: 24px;
        }
      }
    }
  }
`;

export default BenefitsPanel;
