const toRem = px => px / 16;

export const clamp = (minFont, maxFont) => {
  const minWidth = toRem(500);
  const maxWidth = toRem(1920);
  minFont = toRem(minFont);
  maxFont = toRem(maxFont);

  let slope = (maxFont - minFont) / (maxWidth - minWidth),
        yInt = (-minWidth * slope) + minFont;

  return `clamp(${minFont}rem, ${yInt}rem + ${slope * 100}vw, ${maxFont}rem)`;
}

export const colors = {

  gray: '#333333',
  grayMedium: '#666666',
  grayLight: '#9B9B9B',
  grayBorder: '#D8D8D8',
  grayBg: '#E9ECF1',
  red: '#CF202E',
  redLight: '#f1d9d9',
  teal: '#0D8A95',
  orange: '#EF5322',
  yellow: '#F9A31A',
  blue: '#055377',

  get bodyText() {
    return this.gray;
  },

  get linkColor() {
    return this.red;
  }
};

export const gradients = {
  overlay: 'rgba(0,0,0, .59)',
  background: {
    black: {
      up: 'linear-gradient(rgba(0,0,0, 0), rgba(0,0,0, 1))',
      down: 'linear-gradient(rgba(0,0,0, 1), rgba(0,0,0, 0))'
    }
  }
}

export const spacing = {

  mobile: {
    x: 30,
    y: 30
  },

  desktop: {
    x: 50,
    y: 40
  }

};

export const fonts = {
  header: 'itc-avant-garde-gothic-pro, sans-serif',
  body: 'canada-type-gibson, sans-serif'
};

export const type = {

  h1: clamp(47, 92),

  h2: clamp(37, 64),

  h3: clamp(20, 32),

  p: clamp(16, 18),

  lineHeight: {
    p: '1.64'
  },

  letterSpacing: '2.5px'
};

export const breakpoints = {
  max: 1600,
  desktop: 1100,
  tablet: 800
};

export const iconMap = {
  'caret-left': '\\e901',
  'caret-right': '\\e910',
  'bike': '\\e911',
  'happy-hour': '\\e912',
  'lunch-train': '\\e913',
  'map': '\\e914',
  'profit-sharing': '\\e915',
  'arrow-right': '\\e900',
  'facebook': '\\e902',
  'twitter': '\\e903',
  'linkedin': '\\e904',
  'instagram': '\\e905',
  'close': '\\e906',
  'menu': '\\e907',
  'dividend': '\\e908',
  'dollar': '\\e909',
  'gym': '\\e90a',
  'heart': '\\e90b',
  'house': '\\e90c',
  'plane': '\\e90d',
  'sad': '\\e90e',
  'wrench': '\\e90f',
  'double-quote': '\\e916',
  // client logos
  'wilderness': '\\e917'
}

export const t = {
  t: '.3s',
  d: '.1s',
  e: 'ease-out',
  expo: 'cubic-bezier(.82, .1, .14, 1.12)'
}

export const placeholderLink = {
  value: {
    href: "#",
    text: "PLACEHOLDER LINK"
  }
}
