import React, { Component } from 'react';
import { Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';
import { TweenMax } from 'gsap';

// assets
import { type, t } from '../../assets/settings';
import { at, position, hasProp, hover } from '../../assets/utilities';
import arrow from '../../assets/img/arrow.svg';
import circleArrow from '../../assets/img/circle_arrow.svg';

// components
import DualImage from '../_internal/DualImage';
import DynamicTag from '../_internal/DynamicTag';
import IntroShape from '../_internal/IntroShape';
import ArrowLink from '../_internal/ArrowLink';
import Cat from '../_internal/Cat';
import ScrollHint from '../_internal/ScrollHint';

class ScrollRight extends Component {

  constructor(props) {
    super(props);
    this.params = this.props.params ? this.props.params : null;
    this.state = {
      catHidden: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isActivePanel !== this.props.isActivePanel) {
      this.setState({ catHidden: true });
    }
  }

  handleMouseMove = (el, mouse) => {

    if (el && mouse) {

      if (mouse.isActive) {
        const css = {
          left: mouse.x,
          top: mouse.y
        }

        if (this.state.catHidden) {
          TweenMax.to(el, 0, { css, onComplete: () => this.setState({ catHidden: false }) })
        } else {
          TweenMax.to(el, .3, { css });
        }
      } else if (!this.state.catHidden) {
        this.setState({ catHidden: true });
      }
    }
  }

  render() {

    const {
      image,
      ask,
      answer,
      detail,
      link,
      shapeImage
    } = this.props.fields;

    return (
      <StyledScrollRight
        className={`panel-item`}
        large={getFieldValue(this.props.fields, 'large')}
        catHidden={this.state.catHidden}
        onMouseLeave={this.handleMouseLeave}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
      >
        <DynamicTag
          Tag={hasProp(link) ? 'a' : 'div'}
          className="panel-item-inner"
          href={hasProp(link) && link.value.href}
        >
          <DualImage desktop={image} />
          <div className="text">
            <Text tag="div" className="text-mobile" field={ask} />
            <Text tag="div" className="text-answer" field={answer} />
            {
              hasProp(detail) &&
              <Text tag="p" className="text-detail" field={detail} />
            }
            {
              hasProp(link) &&
              <ArrowLink
                href={link.value.href}
              >
                {link.value.text}
              </ArrowLink>
            }
          </div>
          {
            shapeImage && this.params &&
            <IntroShape
              image={shapeImage}
              mouse={this.props.mouse}
              {...this.params}
            />
          }
          {
            this.props.isActivePanel && hasProp(link) &&
            <Cat
              mouse={this.props.mouse}
              handleMouseMove={this.handleMouseMove}
            ></Cat>
          }
          {
            this.props.scrollHint &&
            <ScrollHint />
          }
        </DynamicTag>
      </StyledScrollRight>
    );
  }
}

const StyledScrollRight = styled.div`
  text-align: center;
  color: white;
  overflow: hidden;

  @media ${at('desktop')} {
    text-align: left;

    &:first-child {

      .text-detail {
        display: none;
      }
    }

    &:last-child {

      .panel-item-inner {
        height: 100vh;
      }
    }
  }

  .panel-item-inner {
    position: relative;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    padding: 25px;

    @media ${at('desktop')} {
      flex-direction: row;
      justify-content: flex-start;
      height: 95vh;
      max-height: none;
    }
  }

  a.panel-item-inner {

    .text-answer {

      @media ${at('desktop')} {

        &::after {
          content: url(${circleArrow});
          position: relative;
          top: ${props => props.large ? 0 : 5}px;
          margin-left: 10px;
          white-space: nowrap;
          opacity: 1;
          transition: .6s ${t.e};
        }
      }
    }

    .dual-image {
      transition: 1s ${t.e};
    }

    ${hover()} {

      @media ${at('desktop')} {
        cursor: url(${arrow}), auto;

        .text-answer {

          &::after {
            opacity: .2;
          }
        }

        .dual-image {
          transform: scale(1.1);
          transition-duration: 1.4s;
        }
      }
    }

    * {
      pointer-events: none;
    }
  }

  .text {
    position: relative;
    z-index: 3;

    &-mobile,
    &-answer {
      font-size: ${type.h2};
      line-height: 1.2;
      font-weight: 600;
    }

    &-mobile {
      font-weight: 300;

      @media ${at('desktop')} {
        display: none;
      }
    }

    &-answer {
      display: block;

      @media ${at('desktop')} {
        display: inline-block;
        font-size: ${props => props.large ? type.h1 : type.h2};
      }
    }

    &-detail {
      margin: 10px 0 0 0;

      @media ${at('desktop')} {
        position: absolute;
        margin-top: 0;
        width: 100vw;
        max-width: calc(100vw - 550px);
      }
    }

    .arrow-link {
      ${position('relative', 10)};

      .inner {
        margin: 0 auto;
      }

      @media ${at('desktop')} {
        display: none;
      }
    }
  }

  .scroll-hint {
    display: none;

    @media ${at('desktop')} {
      display: flex;
      ${position('absolute', 'auto', 0, 0, 0)};
    }
  }

  .cat {
    display: none;

    @media ${at('desktop')} {
      display: block;
      ${position('absolute', '50%', 'auto', 'auto', '50%')};
      opacity: ${props => props.catHidden ? 0 : 1};
      transform: translate(-33%, -40%);
      width: 42px;
      height: 42px;
      border: 2px solid white;
      transition: opacity .5s ${t.e};
      border-radius: 50%;
      z-index: 2;
    }
  }
`;

export default ScrollRight;
