import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { type, colors, clamp } from '../../../assets/settings';
import { at, position } from '../../../assets/utilities';


const Tag = (props) => {
  const {
    text,
    location
  } = props;

  return (
    <StyledTag className="section-tag" location={location}>
      <div className="section-tag-text">
        <Text field={text} />
      </div>
    </StyledTag>
  );
}


const StyledTag = styled.div`
  position: relative;
  margin-bottom: 20px;
  font-size: ${clamp(13, 16)};
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: ${type.letterSpacing};

  @media ${at('desktop')} {
    position: absolute;
    margin: 0;
    padding-right: 73px;
    top: 130px;
    left: ${props => props.location === 'right' ? 'auto' : '50px'};
    right: ${props => props.location === 'right' ? '50px' : 'auto'};
    transform: translateX(${props => props.location === 'right' ? '50%' : '-50%'}) rotate(-90deg);

    &::after {
      content: '';
      ${position('absolute', '50%', 0)};
      transform: translateY(-50%);
      background-color: ${colors.red};
      width: 60px;
      height: 2px;
    }
  }

  .section-tag-text {
    @media ${at('desktop')} {
      position: absolute;
      white-space: nowrap;
      right: 70px;
      top: -6px;
    }
  }
`;

export default Tag;
