import React from 'react';
import { Text, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { breakpoints, t } from '../../assets/settings';
import { at, position, hover } from '../../assets/utilities';

// components
import Observe from '../_internal/Observe';
import DualImage from '../_internal/DualImage';
import SiteLink from '../_internal/SiteLink';

const TeamGrid = (props) => {

  const handleObserved = (el, index) => {
    setTimeout(() => {
      el.classList.add('-animate-scroll-on');
    }, 150 * index);
  }

  const {
    items
  } = props.fields;

  return (
    <StyledTeamGrid>
      <div className="inner">
        <Observe
          className="components"
          options={{ threshold: .2 }}
          callback={handleObserved}
        >
          {
            items.map((item, index) => {

              const {
                url,
                fields
              } = item;

              const {
                firstName,
                lastName,
                position,
                image
              } = fields;

              return (
                <div
                  key={index}
                  className="team-grid-item"
                >
                  <DualImage desktop={image} />
                  <SiteLink to={url}>
                    <div className="text">
                      <div className="text-name">
                        <Text field={firstName} /> <Text field={lastName} />
                      </div>
                      <div className="text-title">
                        <Text tag="span" field={position} />
                      </div>
                    </div>
                  </SiteLink>
                </div>
              );
            })
          }
        </Observe>
      </div>
    </StyledTeamGrid>
  );
}


const StyledTeamGrid = styled.div`

  .inner {
    max-width: ${breakpoints.max}px;
    margin: 0 auto;

    .components {
      display: flex;
      flex-wrap: wrap;
    }

    @media ${at('max')} {
      padding-top: 5vmin;
    }
  }

  .team-grid-item {
    position: relative;
    flex: none;
    height: 0;
    width: 50%;
    padding-bottom: 50%;
    transition: ${t.t} ${t.e};
    overflow: hidden;
    opacity: 0;
    transform: translateY(100px);

    &.-animate-scroll-on {
      opacity: 1;
      transform: none;
    }

    ${hover()} {
      transform: scale(1.2);
      box-shadow: 0 2px 10px 4px rgba(0, 0, 0, .59);
      z-index: 4;

      .text {
        transform: none;
        opacity: 1;

        &-name,
        &-title {
          transform: none;
        }
      }
    }

    & > a {
      display: block;
      ${position('absolute', 0, 0, 0, 0)};
      font-size: 0;
      color: transparent;
      z-index: 3 !important;
    }

    .text {
      ${position('absolute', 'auto', 15, 15, 15, '100%')};
      background-color: rgba(207,32,46, .9);
      color: white;
      padding: 15px;
      opacity: 0;
      transition: ${t.t} ${t.e};

      @media ${at('tablet')} {
        left: 24px;
        right: 24px;
        bottom: 24px;
        padding: 16px 24px;
      }

      &-name {
        margin-bottom: 6px;
        font-weight: bold;
        font-size: 22px;
        letter-spacing: -.5px;
        transform: translateX(-100%);
        transition: ${t.t} ${t.e} .15s;

        @media ${at('desktop')} {
          font-size: 26px;
        }
      }

      &-title {
        font-size: 13px;
        line-height: 1.77;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        transform: translateX(-300%);
        transition: ${t.t} ${t.e} .15s;

        & > span {
          display: block;
        }
      }
    }

    @media ${at('tablet')} {
      width: calc(100% / 3);
      padding-bottom: calc(100% / 3);

      &:nth-child(3n + 1) {
        transform-origin: left;
      }

      &:nth-child(3n + 3) {
        transform-origin: right;
      }

      &:nth-last-child(3) {
        transform-origin: bottom left;
      }

      &:nth-last-child(2) {
        transform-origin: bottom;
      }

      &:nth-last-child(1) {
        transform-origin: bottom right;
      }
    }

    @media ${at('desktop')} {
      width: 25%;
      padding-bottom: 25%;

      &:nth-child(n) {
        transform-origin: center;
      }

      &:nth-child(4n + 1) {
        transform-origin: left;
      }

      &:nth-child(4n + 4) {
        transform-origin: right;
      }

      &:nth-last-child(4) {
        transform-origin: bottom left;
      }

      &:nth-last-child(3),
      &:nth-last-child(2) {
        transform-origin: bottom;
      }

      &:nth-last-child(1) {
        transform-origin: bottom right;
      }
    }
  }
`;

export default withPlaceholder('grid-items')(TeamGrid);
