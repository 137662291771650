import React from 'react';
import Component from '../Component';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// animations
import pageScroll from '../../animations/general/page-scroll';

//assets
import { t, breakpoints } from '../../assets/settings';
import { at, throttle, hasProp, position } from '../../assets/utilities';

// context
import MobileContext from '../../context/mobile';

// components
import ScrollLeft from '../ScrollLeft';
import ScrollRight from '../ScrollRight';
import FeaturedClients from '../FeaturedClients';
import Swipe from '../_internal/Swipe';
import StickyMenu from '../_internal/StickyMenu';
import ContactPanel from '../_internal/ContactPanel';
import Footer from '../_internal/Footer';
import MouseMove from '../_internal/MouseMove';

class ScrollPage extends Component {

  static contextType = MobileContext;

  constructor(props) {
    super(props);
    this.scrollRight = React.createRef();
    this.contactPanelRef = React.createRef();
    this.state = {
      panelIndex: 0,
      scrollDown: true,
      contactOpen: false,
      lastActive: false
    };
  }

  hideBodyOverflow = (bool) => {
    if (this.window) {
      document.body.style.overflow = bool ? 'hidden' : '';
    }
  }

  handleResize = (e) => {
    const isMobile = this.window.innerWidth < breakpoints.desktop;
    if (isMobile !== this.context) {
      return this.window.location = this.window.location; // eslint-disable-line no-self-assign
    }
  }

  // throttle the animation so it can only happen so often
  handleWheel = throttle((e, direction) => {

    if (!this.state.contactOpen) {
      const max = this.rightPanels.length - 1;

      this.setState((state) => {
        // if swiped, direction will be 1 (down) or -1 (up)
        const scrollDown = direction ? direction > 0 : e.deltaY > 0,
              { panelIndex } = state,
              returnObj = {
                scrollDown,
                lastActive: false
              }

        if (scrollDown && panelIndex < max) {
          returnObj.panelIndex = panelIndex + 1;
          returnObj.lastActive = panelIndex === max - 1;
          return returnObj;
        }

        if (!scrollDown && panelIndex > 0) {
          returnObj.panelIndex = panelIndex - 1;
          return returnObj;
        }

        if (panelIndex === max) {
          this.props.toggleContactPanelOpen(true);
          return {
            contactOpen: true
          }
        }
      });
    } else if (direction < 0 || e.deltaY < 0) {
      const contactPanel = this.contactPanelRef.current;

      if (contactPanel.scrollTop < 50) {
        this.props.toggleContactPanelOpen(false);
        this.setState({ contactOpen: false });
      }
    }
  }, 1200)

  handleStickyClick = (e) => {
    const { index } = e.target.dataset;

    this.setState((state) => {
      const scrollDown = index > state.panelIndex,
            newIndex = parseInt(index);

      return {
        scrollDown,
        panelIndex: newIndex,
        lastActive: newIndex === this.rightPanels.length - 1
      };
    });
  }

  shouldComponentUpdate(nextProps, nextState) {

    return (
      this.state.panelIndex !== nextState.panelIndex ||
      this.state.contactOpen !== nextState.contactOpen
    );
  }

  componentDidUpdate() {
    const { panelIndex, scrollDown } = this.state,
          destPanel = this.rightPanels[panelIndex];

    if (this.context) {
      this.window.removeEventListener('wheel', this.handleWheel);
      this.hideBodyOverflow(false);
    } else {
      pageScroll(
        this.scrollRight.current,
        destPanel,
        scrollDown
      );
    }
  }

  componentDidMount() {
    // scroll to top of page
    if (this.scrollRight && this.scrollRight.current) {
      this.scrollRight.current.scrollTo(0, 0);
      this.rightPanels = Array.from(this.scrollRight.current.querySelectorAll('.panel-item'));

      // reload page if we cross from mobile to desktop
      this.hideBodyOverflow(true);
      this.window.addEventListener('resize', this.handleResize);
      this.window.addEventListener('wheel', this.handleWheel);

    }
  }

  componentWillUnmount() {
    this.hideBodyOverflow(false);
    this.window.removeEventListener('resize', this.handleResize);
    this.window.removeEventListener('wheel', this.handleWheel);
  }

  render() {

    const {
      placeholders
    } = this.props.rendering;

    const {
      stickyTitle
    } = this.props.fields;

    return (
      <StyledScrollPage
        contactOpen={this.state.contactOpen}
      >
        <div className="scroll-container">
          <div className="scroll-left">
            {
              (placeholders['scroll-left'] || [])
                .filter((component) => component && component.fields)
                .map((component, index) => (
                  <ScrollLeft
                    key={index}
                    activePanel={this.state.panelIndex}
                    lastActive={this.state.lastActive}
                    fields={component.fields}
                  />
              ))
            }
            {
              hasProp(stickyTitle) &&
              <StickyMenu
                title={stickyTitle.value}
                components={this.props['scroll-right']}
                activeIndex={this.state.panelIndex}
                handleClick={this.handleStickyClick}
              />
            }
          </div>
          <div className="scroll-right" ref={this.scrollRight}>
            <MouseMove
              disabled={!hasProp(stickyTitle) && this.rightPanels && this.state.panelIndex === this.rightPanels.length - 1}
              relativeX={true}
              render={mouse => (
              <>
                <Swipe
                  vertical={true}
                  handleSwipe={this.handleWheel}
                >
                  {
                    (
                      placeholders['scroll-right'] || [])
                      .filter((component) => component && component.fields)
                      .map((component, index) => {
                        const cName = component.componentName;

                        if (cName === 'ScrollRight') {
                          return (
                            <ScrollRight
                              key={index}
                              mouse={mouse}
                              fields={component.fields}
                              params={component.params}
                              scrollHint={index === 0}
                              isActivePanel={this.state.panelIndex > 0 && this.state.panelIndex === index}
                            />
                          )
                        } else {
                          return (
                            <FeaturedClients
                              key={index}
                              fields={component.fields}
                            />
                          )
                        }
                      }
                    )
                  }
                </Swipe>
              </>
            )} />
          </div>
        </div>
        <div className="scroll-contact-panel" ref={this.contactPanelRef}>
          <Swipe
            vertical={true}
            handleSwipe={this.handleWheel}
          >
            <ContactPanel />
          </Swipe>
          <Footer />
        </div>
      </StyledScrollPage>
    );
  }
}

const StyledScrollPage = styled.div`

  @media ${at('desktop')} {
    height: 100vh;
  }

  .scroll-contact-panel {
    width: 100%;

    @media ${at('desktop')} {
      height: 100%;
      ${position('absolute', 0)};
      transform: translateY(${props => props.contactOpen ? 0 : '100%'});
      transition: 1s ${t.expo};
      overflow-y: scroll;
      z-index: 3;
    }
  }

  .scroll-container {
    width: 100%;

    @media ${at('desktop')} {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      overflow: hidden;
    }
  }

  .scroll-left {
    position: relative;
    display: none;
    flex: none;

    @media ${at('desktop')} {
      display: block;
      flex: 0 0 500px;
    }

    @media ${at('max')} {
      flex-basis: 35vw;
    }
  }

  .scroll-right {
    flex: 1 1 100%;
    min-height: 500px;
    overflow: scroll;
    scrollbar-width: none;
    width: 100%;

    @media ${at('desktop')} {
      height: 100%;
      flex: 0 0 calc(100% - 500px);
      overflow: hidden;
    }

    @media ${at('max')} {
      flex-basis: calc(100% - 35vw);
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .link-arrow {
      margin-top: 15px;

      @media ${at('desktop')} {
        margin-top: 0;
      }
    }
  }
`;

export default withPlaceholder(['scroll-left', 'scroll-right'])(ScrollPage);
