import React, { Component } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { colors, t } from '../../assets/settings';
import { at, position, getData } from '../../assets/utilities';

// context
import MobileContext from '../../context/mobile';

// components
import StaticLeft from '../_internal/StaticLeft';
import StaticRight from '../_internal/StaticRight';
import FadeLink from '../_internal/FadeLink';
import SiteLink from '../_internal/SiteLink';

class JobListPanel extends Component {

  static contextType = MobileContext;

  constructor(props) {
    super(props);
    this.apiNamespace = process.env.REACT_APP_API;
    this.state = {
      pivot: null,
      jobs: []
    };
  }

  handleMouseLeave = (e) => {
    if (!this.context) {
      this.setState({ pivot: null });
    }
  }

  handleMouseEnter = (e) => {
    if (!this.context) {
      const { index } = e.target.dataset;
      this.setState({
        pivot: index
      });
    }
  }

  componentDidMount() {
    getData(this.apiNamespace + 'jobslist')
      .then(jsonData => {
        if (jsonData.Jobs && jsonData.Jobs.length) {
          this.setState({
            jobs: jsonData.Jobs
          });
        }
      });
  }

  render() {

    const {
      title1,
      title2,
      detail
    } = this.props.fields;

    return (
      <StyledJobListPanel
        className="panel"
      >
        <StaticLeft>
          <div className="text">
            <Text tag="div" className="text-title" field={title1} />
          </div>
          <Text tag="p" className="detail" field={detail} />
        </StaticLeft>
        <StaticRight>
          <div className="text">
            <span className="title-left"><Text field={title1} /> </span><span className="title-right"><Text field={title2} /></span>
          </div>
          <Text tag="p" className="detail" field={detail} />
          <div className="jobs">
            <div className="jobs-title">Current Openings</div>
            <ul className="jobs-list"
              onMouseLeave={this.handleMouseLeave}
            >
              <div className="bracket"></div>
              {
                this.state.jobs.map((item, index) => {

                  const {
                    title,
                    shortcode,
                    application_url
                  } = item;

                  return (
                    <li key={index}>
                      <FadeLink
                        index={index}
                        pivot={this.state.pivot}
                      >
                        <SiteLink
                          data-index={index}
                          to={'/careers/job?shortcode=' + shortcode + '&application_url=' + application_url + '&job_title=' + title}
                          onMouseEnter={this.handleMouseEnter}
                        >
                          {title}
                        </SiteLink>
                      </FadeLink>
                    </li>
                  );
                })
              }
              <li>
                <FadeLink
                  index={this.state.jobs.length ? this.state.jobs.length : 0}
                  pivot={this.state.pivot}
                >
                  <SiteLink
                    data-index={this.state.jobs.length ? this.state.jobs.length : 0}
                    to={'/contact?form=1'}
                    onMouseEnter={this.handleMouseEnter}
                  >
                    Interested but not ready to apply? Drop us a line.
                  </SiteLink>
                </FadeLink>
              </li>
            </ul>
          </div>
        </StaticRight>
      </StyledJobListPanel>
    );
  }
}

const bracketStroke = `3px solid ${colors.bodyText}`;

const StyledJobListPanel = styled.div`
  display: flex;
  text-align: center;

  @media ${at('desktop')} {
    height: 90vh;
    text-align: left;
  }

  .jobs {

    &-title {
      margin-bottom: 24px;
      font-weight: 900;
      line-height: 1.2;
      font-size: 24px;
      color: black;

      @media ${at('desktop')} {
        display: none;
      }
    }

    &-list {
      position: relative;
      list-style-type: none;
      margin: 0;
      padding: 0 20px;

      @media ${at('desktop')} {
        padding: 0 0 0 60px;
        margin-left: 30px;
      }

      .bracket {
        display: none;

        @media ${at('desktop')} {
          display: block;
          ${position('absolute', 0, 'auto', 0, 0)};
          min-height: 70px;
          height: 100%;
          width: 20px;
          border-top: ${bracketStroke};
          border-left: ${bracketStroke};
          border-bottom: ${bracketStroke};
        }

        &::before {
          content: '';
          ${position('absolute', 0, 'auto', 'auto', '50%')};
          transform: translate(-50%, -50%) rotate(45deg);
          width: 15px;
          height: 15px;
          border: ${bracketStroke};
          background-color: ${colors.grayBg};
          z-index: 1;

          @media ${at('desktop')} {
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }

        &::after {
          content: '';
          ${position('absolute', 0, 0, 0, 0)};
          background-color: ${colors.grayBg};
          z-index: 2;
        }
      }

      li {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.3;
        margin-bottom: 12px;

        @media ${at('desktop')} {
          font-weight: 500;
          font-size: 30px;
          padding: 15px 0;
          margin: 0;
        }

        a {
          display: block;
          height: 100%;
          color: ${colors.bodyText};
          transition: ${t.t} ${t.e};

          &:focus,
          &:active {
            font-weight: 500;
          }
        }
      }
    }
  }

  .right {
    background-color: ${colors.grayBg}
    color: ${colors.bodyText};
  }
`;

export default JobListPanel;
