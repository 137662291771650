import React, { Component } from 'react';
import styled from 'styled-components';

// assets
import logo from '../../../assets/img/is_logo.svg';
import logoDark from '../../../assets/img/is_logo_dark.svg';
import { spacing, fonts, colors, t, clamp } from '../../../assets/settings';
import { icon, at, position } from '../../../assets/utilities';

// data maps
import navItems from '../../../maps/nav-items';

// context
import MobileContext from '../../../context/mobile';

// components
import SiteLink from '../SiteLink';

class NavMain extends Component {

  static contextType = MobileContext;

  handleMouseEnter = (e) => {
    const { currentTarget } = e;

    currentTarget.classList.add('-hover-in');
  }

  handleMouseLeave = (e) => {
    const { currentTarget } = e;

    currentTarget.classList.remove('-hover-in');
    currentTarget.classList.add('-hover-out');
    setTimeout(() => {
      currentTarget.classList.remove('-hover-out');
    }, 300);
  }


  render() {

    const {
      darkLinkText,
      darkLogoText,
      navShadow,
      transitionComplete,
      toggleMobileMenu
    } = this.props;

    return (
      <StyledNavMain
        className="nav"
        darkLinkText={darkLinkText}
        navShadow={navShadow}
        transitionComplete={transitionComplete}
      >
        <div className="nav-home">
          <SiteLink to="/">
            <img src={this.context || darkLogoText ? logoDark : logo} alt="Interactive Strategies Logo" />
          </SiteLink>
        </div>
        <nav className="nav-main">
          {
            navItems.map((item, index) => {
              return (
                <SiteLink
                  key={index}
                  to={item.path}
                  isNav={true}
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                >
                  {item.name}
                </SiteLink>
              )}
            )
          }
        </nav>
        <button className="mobile-open" onClick={() => toggleMobileMenu(true)}></button>
      </StyledNavMain>
    );
  }
}


const StyledNavMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${fonts.header};
  z-index: 6;
  background-color: white;
  padding: 13px ${spacing.mobile.x}px;

  @media ${at('desktop')} {
    ${position('absolute', 0, 0, 'auto', 0)};
    padding: ${spacing.desktop.y}px ${spacing.desktop.x}px;
    background-color: transparent;
    transform: translateY(${props => props.transitionComplete ? 0 : '-100%'});
    transition: ${t.t} ${t.e};

    &::before {
      display: ${props => props.navShadow ? 'block' : 'none'};
      content: '';
      ${position('absolute', 0, 'auto', 'auto', 0)};
      width: 100%;
      height: 150%;
      background: linear-gradient(rgba(0,0,0, .59), rgba(0,0,0, 0));
      z-index: -1;
    }
  };

  .nav-home {

    & > a {
      display: flex;
      align-items: center;
      margin-right: 17px;

      img {
        width: 200px;
        height: auto;
        transition: ${t.t} ${t.e};

        @media ${at(380)} {
          width: 250px;
        }
      }
    }
  }

  .nav-main {
    display: none;

    @media ${at('desktop')} {
      display: block;

      & > a {
        display: inline-block;
        ${position('relative', 6)};
        padding-bottom: 10px;
        margin-right: 28px;
        color: white;
        font-size: ${clamp(16, 20)};
        overflow: hidden;

        @media ${at('desktop')} {
          color: ${props => props.darkLinkText ? colors.bodyText : 'white'};
        }

        &:last-child {
          margin-right: 0;
        }

        &::before {
          content: '';
          ${position('absolute', 'auto', 'auto', 0, 0)};
          width: 0;
          height: 2px;
          background-color: ${props => props.darkLinkText ? colors.bodyText : 'white'};
          transition: ${t.t} ${t.e};
        }

        &.active {

          &::before {
            width: 100%;
          }

          &.-hover-in {

            &::before {
              left: auto;
              right: 0;
              width: 0;
            }
          }

          &.-hover-out {

            &::before {
              left: 0;
              width: 100%;
            }
          }
        }

        &.-hover-in {

          &::before {
            left: 0;
            width: 100%;
          }
        }

        &.-hover-out {

          &::before {
            left: auto;
            right: 0;
            width: 0;
          }
        }
      }
    }
  }

  .mobile-open {
    width: 18px;
    height: 18px;
    padding: 0;

    &::before {
      ${icon('menu')};
    }

    @media ${at('desktop')} {
      display: none;
    }
  }
`

export default NavMain;
