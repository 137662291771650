import React from 'react';
import Component from '../../Component';

class Observe extends Component {

  constructor(props) {
    super(props);
    this.elRef = React.createRef();
    this.defaultOptions = {
      rootMargin: "0px 0px -200px 0px",
      threshold: 0
    };

    const IntersectionObserver = this.window.IntersectionObserver,
          options = Object.assign(this.defaultOptions, this.props.options || {});

    if (IntersectionObserver) {
      this.observer = new IntersectionObserver(this.handleObserved, options);
    }
  }

  handleObserved = (entries) => {
    entries.forEach((entry, index) => {
      const { target, intersectionRatio, isIntersecting } = entry,
            { callback, negativeCallback } = this.props;

      if (isIntersecting && intersectionRatio > 0) {
        return callback && callback(target, index);
      } else {
        return negativeCallback && negativeCallback(target, index);
      }
    });
  }

  componentDidMount() {

    const children = Array.from(this.elRef.current.children);
    if (this.observer && children.length) {
      children.forEach((item, index) => {
        this.observer.observe(item);
      });
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  render() {
    return (
      <div ref={this.elRef} className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

export default Observe;
