import React from 'react';
import styled from 'styled-components';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { TransitionGroup, Transition } from 'react-transition-group';

// assets
import { at, position } from '../../../assets/utilities';
import { spacing, fonts, colors, type, t } from '../../../assets/settings';
// import logoIcon from '../../../assets/img/is_logo_icon.svg';

const PageContent = (props) => {

  const transitionMask = React.createRef();

  const {
    children,
    route,
    transitionComplete,
    toggleContactPanelOpen,
    onEnter
  } = props;

  return (
    <StyledPageContent
      id="page-content"
      className={transitionComplete ? '-ready' : '-transitioning'}
    >
      <StyledMain>
        <div className="page-container">
          <TransitionGroup
            component={null}
          >
            <Transition
              key={route.name}
              appear={true}
              onEnter={(node) => onEnter(node, transitionMask.current)}
              timeout={1000}
            >
              <>
                {
                  children ?
                  children
                  :
                  <Placeholder name="jss-main" rendering={route} toggleContactPanelOpen={toggleContactPanelOpen} />
                }
                <div className="transition-mask" ref={transitionMask}>
                  {/*<img src={logoIcon} />*/}
                </div>
              </>
            </Transition>
          </TransitionGroup>
        </div>
      </StyledMain>
    </StyledPageContent>
  );
}

const StyledPageContent = styled.div`
  pointer-events: none;

  &.-ready {
    pointer-events: auto;
  }

  .page-container {
    position: relative;
    overflow: hidden;

    &-inner {
      max-width: 2000px;
      margin: 0 auto;
    }
  }
`;

const StyledMain = styled.main`
  position: relative;
  color: ${colors.bodyText};
  font-family: ${fonts.body};
  background-color: white;

  h1 {
    font-size: ${type.h1};
  }

  h2 {
    font-size: ${type.h2};
  }

  p {
    font-size: ${type.p};
    line-height: ${type.lineHeight.p};

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: ${colors.linkColor};
    }
  }

  a {
    color: white;
  }

  .-hero {
    min-height: 600px;

    @media ${at('desktop')} {
      height: 100vh;
      padding: ${spacing.desktop.y}px ${spacing.desktop.x}px;
    }

    .split-text {
      display: inline-block;

      .heading {
        display: inline-block;
      }
    }
  }

  .sr-text {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    position: absolute;
    white-space: nowrap;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }

  /* hyperform */
  .hf-invalid {
    color: ${colors.red};

    + label {
      color: ${colors.red};
    }
  }

  .hf-warning {
    ${position('absolute', 'auto', 8, 2)};
    color: ${colors.red};
    font-size: 11px;

    @media ${at(5)} {
      top: 4px;
      bottom: auto;
    }
  }

  .transition-mask {
    position: fixed;
    left: 0;
    right: 0;
    background-color: ${colors.grayBg};
    z-index: 9999;
    overflow: hidden;

    & > img {
      ${position('absolute', '50vh', 'auto', 'auto', '50vw', '-50%', '-50%')};
      width: 50%;
      max-width: 200px;
      height: auto;
      opacity: .6;
    }
  }

  .text {

    @media ${at('desktop')} {

      .-ready & {
        transition: transform ${t.t} ${t.e}, opacity ${t.t} ${t.e};
      }
    }
  }
` // setup default app styles in here

export default PageContent;
