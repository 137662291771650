import React from 'react';
import Component from '../../Component';
import styled from 'styled-components';

// assets
import { type, colors, spacing, t, clamp } from '../../../assets/settings';
import { at, position, hover } from '../../../assets/utilities';

class StickyMenu extends Component {

  constructor(props) {
    super(props);
    this.menuList = React.createRef();
    this.titleBar = React.createRef();
  }

  setWidth = () => {
    const menuList = this.menuList.current,
          titleBar = this.titleBar.current;

    if (menuList && titleBar) {
      titleBar.style.width = `${menuList.getBoundingClientRect().height}px`;
    }
  }

  componentDidMount() {
    this.setWidth();
    this.window.addEventListener('resize', this.setWidth);
  }

  componentWillUnmount() {
    this.window.removeEventListener('resize', this.setWidth);
  }

  render() {

    return (
      <StyledStickyMenu className="sticky-menu">
        <ul className="sticky-menu-list" ref={this.menuList}>
          {
            this.props.title &&
            <div className="title-bar" ref={this.titleBar}>
              <h1 className="title-bar-text">{this.props.title}</h1>
              <span className="title-bar-bar"></span>
            </div>
          }
          {
            this.props.components.map((component, index) => {
              // for sitecore experience editor
              if (component.props && component.props.type === 'text/sitecore') return component;
              const { fields } = component.props,
                    text = fields.menuItem && fields.menuItem.value;

              if (index === 0) return null;

              return (
                <li key={index}>
                  <button
                    className={index === parseInt(this.props.activeIndex) ? '-active' : ''}
                    data-index={index}
                    onClick={this.props.handleClick}
                  >
                    {text}
                  </button>
                </li>
              )
            })
          }
        </ul>
      </StyledStickyMenu>
    );
  }
};

const StyledStickyMenu = styled.nav`
  display: none;
  ${position('fixed', '50%', 'auto', 'auto', 0, '-100%', '-50%')};
  padding-left: ${spacing.mobile.x}px;
  opacity: 0;

  .-ready & {
    transform: translateY(-50%);
    opacity: 1;
    transition: ${t.t} ${t.e};
    transition-delay: 1s;
    z-index: 3;
  }

  @media ${at('desktop')} {
    display: block;

    .title-bar {
      display: flex;
      ${position('absolute', 'auto', 'auto', 0, 0)};
      padding-bottom: 12px;
      letter-spacing: ${type.letterSpacing};
      text-transform: uppercase;
      transform: rotate(-90deg) translateY(100%);
      transform-origin: bottom left;

      &-text {
        font-size: ${clamp(13, 18)};
        flex: 0 0 auto;
        margin-right: 14px;
        font-weight: 700;
      }

      &-bar {
        flex: 1 1 auto;
        position: relative;

        &::after {
          content: '';
          ${position('absolute', '50%', 'auto', 'auto', 0, 0, '-50%')};
          width: 100%;
          height: 2px;
          background-color: ${colors.red};
        }
      }
    }

    & > ul {
      position: relative;
      list-style-type: none;
      padding: 0 0 0 ${clamp(34, 50)};

      & > li {

        & > button {
          color: ${colors.grayLight};
          font-size: ${clamp(13, 16)};
          line-height: 2.6;
          transition: ${t.t} ${t.e};

          ${hover()},
          &.-active {
            color: black;
            transition: ${t.t} ${t.e};
          }
        }
      }
    }
  }

  @media ${at(15)} {
    padding-left: ${spacing.desktop.x}px;
  }
`;

export default StickyMenu;
