// meta data defaults

import image from '../assets/img/og-image.jpg';

const metaMap = new Map([
  ['description', 'We bring together strategy, design, content, technology and marketing to provide measurable results for our clients.'],
  ['image', image]
]);

export default (value) => {
  return metaMap.get(value);
};
