import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { contentConstraint, hasProp } from '../../../assets/utilities';
import { type, colors } from '../../../assets/settings';

// components
import DynamicTag from '../DynamicTag';

const SplitText = (props) => {

  const {
    heading1,
    heading2,
    description,
    separator
  } = props;

  return (
    <StyledSplitText className="split-text">
      <DynamicTag
        Tag={props.tag || 'h2'}
        className="heading"
      >
        <Text field={heading1}/>{ hasProp(separator) ? separator.value : ' ' }{ heading2 && <Text tag="span" field={heading2} /> }
      </DynamicTag>
      {
        hasProp(description) &&
        <div className="description">
          <RichText field={description} />
        </div>
      }
      {props.children}
    </StyledSplitText>
  );
}

const StyledSplitText = styled.div`
  position: relative;
  ${contentConstraint(720)};

  .heading {
    font-weight: 300;
    margin: 0;

    & > span {
      display: inline-block;
      font-weight: 700;
    }
  }

  .description {
    margin-top: 20px;
    line-height: ${type.lineHeight.p};
    font-size: ${type.p};

    a {
      color: ${colors.linkColor};
    }
  }
`;

export default SplitText;
