import React, { Component } from 'react';

import { throttle } from '../../../assets/utilities';

class Swipe extends Component {

  constructor(props) {
    super(props);
    this.max = this.props.children.length - 1;
    this.state = {
      start: null,
      end: null
    }
  }

  handleTouchStart = (e) => {
    e.persist();
    const { clientX, clientY } = e.touches[0];
    this.setState({
      start: this.props.vertical ? clientY : clientX
    });
  }

  handleTouchMove = throttle((e) => {
    e.persist();
    const { clientX, clientY } = e.touches[0];
    this.setState({
      end: this.props.vertical ? clientY : clientX
    });
  })

  handleTouchEnd = (e) => {
    e.persist();
    const { start, end } = this.state;

    if (end > start) {
      // increment down (scroll right/down, go left)
      this.props.handleSwipe && this.props.handleSwipe(e, -1);

    } else if (start > end) {
      // increment up (scroll left/up, go right)
      this.props.handleSwipe && this.props.handleSwipe(e, 1);
    }

    this.setState({
      start: null,
      end: null
    });
  }

  render() {

    return (
      <div
        className={this.props.className}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        ref={this.props.propRef}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Swipe;
