import React from 'react';
import Component from '../../Component';
import styled from 'styled-components';

import { t } from '../../../assets/settings';
import { position, icon } from '../../../assets/utilities';

import Swipe from '../Swipe';

class MobileSlider extends Component {

  constructor(props) {
    super(props);
    this.max = this.props.children ? this.props.children.length - 1 : 0;
    this.state = {
      gutter: 0,
      itemWidth: 0,
      activeIndex: 0
    }
  }

  updateIndex = (e, direction) => {
    const newIndex = this.state.activeIndex + direction;
    let activeIndex
    if (direction < 0) {
      activeIndex = newIndex < 0 ? 0 : newIndex;
    } else {
      activeIndex = newIndex > this.max ? this.max : newIndex;
    }

    this.setState({ activeIndex: activeIndex });
  }

  setGutter = () => {
    const { innerWidth } = this.window;
    let gutter = 30;

    if (innerWidth < 500) {
      gutter = 20;
    } else if (innerWidth < 800) {
      gutter = 25;
    }

    this.setState({
      gutter: gutter,
      itemWidth: ((100 - (2 * gutter)) / 100) * innerWidth
    });
  }

  componentDidMount() {
    this.setGutter();
    this.window.addEventListener('resize', this.setGutter);
  }

  componentWillUnmount() {
    this.window.removeEventListener('resize', this.setGutter);
  }

  render() {

    return (
      <StyledMobileSlider
        className="mobile-slider"
        activeN={this.state.activeIndex + 1}
        gutter={this.state.gutter}
        itemWidth={this.state.itemWidth}
        transX={this.state.itemWidth * this.state.activeIndex}
        disablePrev={this.state.activeIndex === 0}
        disableNext={this.state.activeIndex === this.max}
      >
        <button className="arrow prev" onClick={(e) => this.updateIndex(e, -1)}>
          <span className="sr-text">Previous</span>
        </button>
        <button className="arrow next" onClick={(e) => this.updateIndex(e, 1)}>
          <span className="sr-text">Next</span>
        </button>
        <Swipe
          className="track"
          handleSwipe={this.updateIndex}
        >
          {this.props.children}
        </Swipe>
      </StyledMobileSlider>
    );
  }
}


const StyledMobileSlider = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    z-index: 5;

    &::before {
      ${position('absolute', '50%', 'auto', 'auto', '50%', '-50%', '-50%')};
      color: white;
    }

    &.prev {
      display: ${props => props.disablePrev ? 'none' : 'block'};
      left: 0;

      &::before {
        ${icon('caret-left')};
      }
    }

    &.next {
      display: ${props => props.disableNext ? 'none' : 'block'};
      right: 0;

      &::before {
        ${icon('caret-right')};
      }
    }
  }

  .track {
    position: relative;
    display: flex;
    height: 100%;
    width: 500%;
    padding-left: ${props => props.gutter}vw;
    transform: translateX(-${props => props.transX}px);
    transition: ${t.t} ${t.e};
  }

  .slider-item {
    flex: none;
    width: ${props => props.itemWidth}px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: ${t.t} ${t.e};

    &-inner {
      width: 100%;
      max-width: 250px;
    }

    &:nth-child(${props => props.activeN}) {
      opacity: 1;
    }
  }
`;

export default MobileSlider;
