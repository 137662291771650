import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { type, colors, t, spacing, clamp } from '../../../assets/settings';
import { at, position, hover } from '../../../assets/utilities';

const WorkIndex = (props) => {

  const {
    toggleOpen,
    isOpen,
    peekIndex,
    title,
    list
  } = props;

  return (
    <StyledWorkIndex
      className={`work-index ${peekIndex ? '-peek': ''} ${isOpen ? '-open' : ''}`}
    >
      <div className="trigger">
        <button onClick={toggleOpen} className="trigger-button"><span className="trigger-button-text">{isOpen ? 'LESS' : 'MORE'}</span></button>
      </div>
      <div className="title">{title}</div>
      <RichText field={list} className="scroll"/>
    </StyledWorkIndex>
  );
}

const StyledWorkIndex = styled.div`
  ${position('fixed', 'auto', 'auto', 0, 0, 0, '100%')};
  height: calc(100vh - 80px);
  width: 100%;
  background-color: ${colors.red};
  color: white;
  padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
  transition: ${t.t} ${t.e};
  z-index: 4;
  display: flex;
  flex-direction: column;

  @media ${at('desktop')} {
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    height: 100vh;
    padding: 155px 100px 100px;
    width: calc(2 * (100vw / 3));
    transform: translateX(100%);
  }

  @media ${at(1920)} {
    /* 2 * (1920 / 3) */
    width: 1280px;
  }

  &.-peek {

    .trigger {
      transform: translateY(-100%);

      @media ${at('desktop')} {
        transform: translateX(-100%);
      }
    }

    &.-open {
      transform: none;
      transition: ${t.t} ${t.e};

      .trigger {

        @media ${at('desktop')} {
          transform: translateX(-100%);
        }
      }
    }
  }

  .trigger {
    ${position('absolute', 0, 0, 0, 0)};
    height: 70px;
    transition: ${t.t} ${t.e};
    overflow: hidden;

    @media ${at('desktop')} {
      ${position('absolute', 0, 'auto', 0, 0)};
      margin: 0;
      width: 60px;
      height: 100%;
    }

    &-button {
      ${position('absolute', 15, 'auto', 'auto', '50%', '-50%')};
      width: 140vw;
      height: 140vw;
      background-color: ${colors.red};
      border-radius: 50%;
      box-shadow: 0 0 12px 6px rgba(0, 0, 0, .3);
      transition: ${t.t} ${t.e};

      ${hover()} {
        width: 180vw;
      }

      @media ${at('desktop')} {
        top: 50%;
        left: 15px;
        width: 600px;
        height: 600px;
        transform: translateY(-50%);

        ${hover()} {
          height: 1600px;
        }
      }

      &-text {
        ${position('absolute', 24, 'auto', 'auto', '50%', '-50%')};
        color: white;
        letter-spacing: ${type.letterSpacing};
        font-size: ${clamp(13, 16)};
        font-weight: 700;
        z-index: 2;

        @media ${at('desktop')} {
          left: 24px;
          top: 50%;
          writing-mode: vertical-rl;
          transform: rotate(180deg) translateY(50%);
        }
      }
    }
  }

  .title {
    font-size: ${clamp(30, 45)};
    font-weight: 300;
    margin: 0 0 40px;
  }

  .scroll {
    overflow: auto;

    & > ul {
      column-count: 1;
      list-style-type: none;
      padding: 0;

      @media ${at(6)} {
        column-count: 2;
        column-gap: 40px;
      }

      @media ${at('tablet')} {
        column-gap: 60px;
      }

      @media ${at('desktop')} {
        column-gap: 120px;
      }

      li {
        font-size: ${clamp(15, 18)};
        line-height: 1.4;
        margin-bottom: 10px;
        color: ${colors.redLight};

        a {
          color: white;
          font-weight: 600;
          transition: ${t.t} ${t.e};

          ${hover()} {
            box-shadow: inset 0 -2px 0 0 white;
          }
        }
      }
    }
  }
`;

export default WorkIndex;
