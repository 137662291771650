import React, { Component } from 'react';

// context
import MobileContext from '../../../context/mobile';

// assets
import { throttle } from '../../../assets/utilities';

class MouseMove extends Component {

  static contextType = MobileContext;

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      x: 0,
      y: 0
    }
  }

  handleMouseMove = throttle((e) => {
    const { clientX, clientY } = e,
          { left, top } = e.currentTarget.getBoundingClientRect();
    this.setState({
      x: this.props.relativeX ? clientX - left : clientX,
      y: this.props.relativeY ? clientY - top : clientY
    })
  }, 25)

  render() {
    return (
      <div
        className="mouse-tracker"
        onMouseMove={this.context || this.props.disabled ? null : this.handleMouseMove}
        onMouseEnter={() => this.setState({ isActive: true })}
        onMouseLeave={() => this.setState({ isActive: false })}
      >
        {this.props.render(this.state)}
      </div>
    )
  }
}

export default MouseMove;
