import React from 'react';
import styled from 'styled-components';

import { type, spacing } from '../../../assets/settings';
import { at } from '../../../assets/utilities';

const StaticRight = (props) => {

  return (
    <StyledStaticRight className="right">
      {props.children}
    </StyledStaticRight>
  );
}


const StyledStaticRight = styled.div`
  position: relative;
  color: white;
  overflow: hidden !important;
  padding: 87px ${spacing.mobile.x}px;
  flex: 0 0 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${at('desktop')} {
    align-items: center;
    justify-content: flex-start;
    flex-basis: calc(100% - 400px);
    padding: 50px 20px;
    flex-direction: row;
  }

  @media ${at('max')} {
    flex-basis: calc(100% - 35vw);
  }

  .text {
    width: 100%;
    margin: 0 auto 30px;
    position: relative;
    z-index: 2;
    font-size: ${type.h2};
    font-weight: 900;

    @media ${at('desktop')} {
      width: auto;
      white-space: nowrap;
      margin: 0;
    }

    .title-left {
      font-weight: 300;

      @media ${at('desktop')} {
        display: none;
      }
    }
  }

  .detail {
    position: relative;
    line-height: 1.6;
    margin: 0 auto 40px;
    z-index: 1;

    @media ${at('desktop')} {
      display: none;
    }
  }
`;

export default StaticRight;
