import React, { Component } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { t } from '../../../assets/settings';
import { gridItemStyles, hover } from '../../../assets/utilities';

import SiteLink from '../SiteLink';

class FCGridItem extends Component {

  constructor(props) {
    super(props);
    this.fadeDuration = 500;
    this.state = {
      isVisible: false,
      data: {}
    }
  }

  componentWillReceiveProps(newProps) {
    const { order } = newProps,
          staggerDuration = order * 50;

      setTimeout(() => {
        this.setState({ isVisible: false }, () => {
          setTimeout(() => {
            this.setState({ isVisible: true, data: newProps.data })
          }, 1.5 * this.fadeDuration);
        });
      }, staggerDuration);
  }

  render() {
    const {
      fields
    } = this.state.data;

    const icon = fields && fields.icon && fields.icon.value || '';

    return (
      <StyledFCGridItem
        className="grid-item"
        isVisible={this.state.isVisible}
      >
        {
          fields &&
          <SiteLink to={'/work'}>
            <div className={`logo-icon icon-${icon}`}>
              <Text tag="span" className="sr-text" field={fields.fullClientName} />
            </div>
          </SiteLink>
        }
      </StyledFCGridItem>
    );
  }
}

const StyledFCGridItem = styled.div`
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: 500ms ${t.e};
  width: 100%;
  height: 33.33vh;
  ${gridItemStyles(8)};

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: ${t.t} ${t.e};

    .logo-icon {
      font-size: 120px;
    }

    ${hover()} {
      background-color: rgba(255, 255, 255, .15);
      box-shadow: 0 2px 18px 0 rgba(0, 0, 0, .3);
    }
  }
`;

export default FCGridItem;
