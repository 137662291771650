import React from 'react';
import Component from '../../Component';
import styled from 'styled-components';

// assets
import { colors, t } from '../../../assets/settings';
import { at, icon, position, hover, getUrlParams } from '../../../assets/utilities';

import { forms } from './data';

// components
import FormInput from '../FormInput';
import FileInput from '../FileInput';
import PhoneInput from '../PhoneInput';
import ActionTag from '../ActionTag';
import LoadingSpinner from '../LoadingSpinner';

class FormSwitcher extends Component {

  constructor(props) {
    super(props);
    this.formsContainer = React.createRef();
    this.activeForm = getUrlParams(this.window).get('form');
    this.state = {
      activeIndex: this.activeForm ? parseInt(this.activeForm) : 0
    }
  }

  changeForm = (e, index, id) => {
    e.preventDefault();

    this.setState({
      activeIndex: index,
      activeFormId: id
    });
  }

  componentDidMount() {
    const { id } = this.formsContainer.current.children[this.state.activeIndex];
    this.setState({ activeFormId: id });
  }

  render() {

    return (
      <StyledFormSwitcher
        className="form-switcher"
        activeIndex={this.state.activeIndex}
      >
        <div className="switcher-head">
          I want to:
          <div className="switcher-head-triggers">
            {
              forms.map((form, index) => {
                return (
                  <button
                    key={index}
                    data-id={form.id}
                    className={ index === this.state.activeIndex ? '-active' : '' }
                    onClick={(e) => this.changeForm(e, index, form.id)}
                  >{form.trigger}</button>
                );
              })
            }
          </div>
        </div>
        <div className="switcher-forms" ref={this.formsContainer}>
          {
            forms.map((form, index) => {
              return (
                <form
                  id={form.id}
                  key={index}
                  className="switcher-forms-item"
                  onSubmit={this.props.handleSubmit}
                >
                  <div className="switcher-forms-item-inner">
                    {
                      form.sections.map((section, index) => {
                        return (
                          <div key={index} className="switcher-forms-item-section">
                            {
                              section.map((item,index) => {
                                if (item.type === 'file') {
                                  return (
                                    <FileInput
                                      tabbable={this.state.activeFormId === form.id}
                                      key={index}
                                      className={item.customClass || ''}
                                      handleFileUpload={this.props.handleFileUpload}
                                      {...item}
                                    />
                                  )
                                } else if (item.type === 'tel') {
                                  return (
                                    <PhoneInput
                                      tabbable={this.state.activeFormId === form.id}
                                      key={index}
                                      className={item.customClass || ''}
                                      {...item}
                                    />
                                  )
                                } else {
                                  return (
                                    <FormInput
                                      tabbable={this.state.activeFormId === form.id}
                                      key={index}
                                      className={item.customClass || ''}
                                      {...item}
                                    />
                                  )
                                }
                              })
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                </form>
              )
            })
          }
        </div>
        {
          !this.props.loading ?
          <ActionTag
            reverse={true}
            className="switcher-submit"
            isDisabled={this.props.submitDisabled}
          >
            <button
              type="submit"
              form={this.state.activeFormId}
              disabled={this.props.submitDisabled}
            >Submit</button>
          </ActionTag>
          :
          <LoadingSpinner />
        }
      </StyledFormSwitcher>
    );
  }
};

const StyledFormSwitcher = styled.div`
  color: ${colors.bodyText};

  .switcher {

    &-head {
      margin-bottom: 40px;

      @media ${at('tablet')} {
        margin-bottom: 65px;
      }

      &-triggers {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        @media ${at(500)} {
          display: inline;
          margin-top: 0;
        }

        button {
          position: relative;
          color: inherit;
          margin: 12px;
          padding-bottom: 5px;
          transition: ${t.t} ${t.e};

          &::before {
            content: '';
            ${position('absolute', 'auto', 'auto', 0)}
            width: 0;
            height: 3px;
            background-color: ${colors.red};
            transition: ${t.t} ${t.e};
          }

          ${hover()} {
            color: ${colors.red};
          }

          &.-active {
            color: ${colors.red};

            &::before {
              width: 100%;
            }
          }
        }
      }
    }

    &-forms {
      display: flex;
      overflow: hidden;

      &-item {
        flex: none;
        width: 100%;
        transform: translateX(-${props => props.activeIndex * 100}%);
        transition: ${t.t} ${t.e};

        &-inner {

          @media ${at('tablet')} {
            display: flex;
            justify-content: center;
          }
        }

        &-section {
          flex: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          margin: 0;

          @media ${at('tablet')} {
            width: calc(50% - 20px);
            margin: 0 10px;
          }

          & > div {
            flex: 0 0 100%;

            &.-half {

              @media ${at('tablet')} {
                flex: 0 0 calc(50% - 5px);
              }
            }
          }

          textarea {
            min-height: 200px;
          }

          &:not(:first-child) {
            margin-top: 10px;

            @media ${at('tablet')} {
              margin-top: 0;
            }
          }
        }
      }
    }

    &-submit {
      margin-top: 40px;

      & > button {
        font-size: 20px;
        transition: ${t.t} ${t.e};

        &::after {
          ${icon('arrow-right')};
          ${position('relative', -2)}
          margin-left: 10px;
          font-size: 10px;
        }
      }
    }
  }
`;

export default FormSwitcher;
