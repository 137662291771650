import React from 'react';
import styled from 'styled-components';

// assets
import { t } from '../../../assets/settings';
import { at, position, hover } from '../../../assets/utilities';
import circleArrow from '../../../assets/img/circle_arrow.svg';

// components
import DynamicTag from '../DynamicTag';

const ArrowLink = ({isLink, children, ...props}) => {

  return (
    <StyledArrowLink className="arrow-link">
      <DynamicTag className="inner" Tag={isLink ? "a" : "div"} {...props}>
        <span className="sr-text">{children}</span>
      </DynamicTag>
    </StyledArrowLink>
  );
}

const StyledArrowLink = styled.div`

  .inner {
    display: block;
    position: relative;
    width: 47px;
    height: 47px;
    z-index: 2;

    &::before {
      content: url(${circleArrow});
      ${position('absolute', '50%', 'auto', 'auto', '50%', '-50%', '-50%')};
      transition: ${t.t} ${t.e};
    }

    @media ${at('desktop')} {

      ${hover()} {

        &::before {
          transform: translate(-50%, -50%) scale(1.2);
        }
      }
    }
  }
`

export default ArrowLink;
