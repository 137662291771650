import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { hasProp, position, webpize } from '../../../assets/utilities';

const DualImage = (props) => {

  let {
    desktop,
    desktopWebp,
    mobile,
    mobileWebp,
    breakpoint
  } = props;

  if (!desktopWebp) {
    desktopWebp = webpize(hasProp(desktop));
  }

  if (!mobileWebp) {
    mobileWebp = webpize(hasProp(mobile));
  }
        

  return (
    <StyledDualImage className="dual-image">
      <picture>
        {
          hasProp(mobile) &&
          <>
            <source srcSet={mobileWebp} media={`(max-width: ${breakpoint || 800}px)`} type="image/webp"/>
            <source srcSet={mobile.value.src} media={`(max-width: ${breakpoint || 800}px)`} />
          </>
        }
        <source srcSet={desktopWebp} type="image/webp" />
        <Image media={desktop} />
      </picture>
    </StyledDualImage>
  );
}

const StyledDualImage = styled.div`
  ${position('absolute', 0, 0, 0, 0)};
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
`;

export default DualImage;
