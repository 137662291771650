import React, { Component } from 'react';
import styled from 'styled-components';

import { colors, t } from '../../../assets/settings';
import { position } from '../../../assets/utilities';

class FormInput extends Component {

  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      isActive: false
    };
  }

  checkIfActive = (e) => {
    if (this.input.current === document.activeElement || this.input.current.value !== '') {
      this.setState({ isActive: true });
    } else {
      this.setState({ isActive: false });
    }
  }

  componentDidMount() {
    this.checkIfActive();
  }

  render() {

    const {
      type,
      id,
      name,
      label,
      required,
      placeholder,
      pattern,
      patternMsg,
      handleFileUpload,
      tabbable
    } = this.props;

    return (
      <StyledFormInput
        className={`${this.props.className} ${this.state.isActive ? '-active' : ''}`}
        fullHeight={type === 'textarea'}
        file={type === 'file'}
        isRequired={required ? true : false}
      >
        {
          type === 'textarea' ?
          <textarea
            tabIndex={tabbable ? 0 : -1}
            onInput={this.checkIfActive}
            onBlur={this.checkIfActive}
            onFocus={this.checkIfActive}
            ref={this.input}
            id={id}
            name={name}
            placeholder={placeholder}
            required={required}
          />
          :
          <input
            tabIndex={tabbable ? 0 : -1}
            onChange={handleFileUpload}
            onInput={this.checkIfActive}
            onBlur={this.checkIfActive}
            onFocus={this.checkIfActive}
            ref={this.input}
            id={id}
            name={name}
            type={type}
            pattern={pattern ? pattern : null}
            data-pattern-mismatch={patternMsg ? patternMsg : null}
            maxLength="255"
            required={required}
          />
        }
        <label
          htmlFor={id}
        >
          {label}
        </label>
      </StyledFormInput>
    );
  }
};

const StyledFormInput = styled.div`
  position: relative;
  margin: 0 0 10px;
  padding: 16px;
  width: 100%;
  height: ${props => props.fullHeight ? '100%' : 'auto'};
  background-color: white;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    ${position('absolute', 19, 'auto', 'auto', 16)};
    font-size: 15px;
    text-align: left;
    transition: ${t.t} ${t.e};

    &::after {
      display: ${props => props.isRequired ? 'inline' : 'none'};
      content: '*';
    }
  }

  input {
    padding-left: ${props => props.file ? '196px' : 'auto'};
  }

  textarea {
    padding-top: 30px;
    transition: ${t.t} ${t.e};

    &::placeholder {
      font-style: italic;
      opacity: .2;
    }
  }

  input,
  textarea {
    width: 100%;
    height: 100%;
  }

  &.-active {

    label {
      left: 8px;
      top: 4px;
      font-size: 11px;
      color: ${colors.teal};
      transition: ${t.t} ${t.e};
    }

    input,
    textarea {
      ${position('relative', 5)}
      padding-left: 0;
    }

    textarea {
      padding-top: 10px;
    }
  }
`;

export default FormInput;
