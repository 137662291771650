import React from 'react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { spacing, type } from '../../assets/settings';
import { at, position } from '../../assets/utilities';
import teamVideo from '../../assets/video/team_video.mp4';

// components
import SplitText from '../_internal/SplitText';
import ContactPanel from '../_internal/ContactPanel';
import Footer from '../_internal/Footer';
import Observe from '../_internal/Observe';

const TeamPage = (props) => {

  const handleObserved = (el) => {
    el.classList.add('-animate-scroll-on');
  };

  const {
    heading1,
    heading2,
    description,
    video
  } = props.fields;

  return (
    <StyledTeamPage>
      <div className="hero -hero">
        <div className="hero-video">
          <video src={teamVideo} loop muted autoPlay playsInline></video>
        </div>
        <SplitText
          heading1={heading1}
          heading2={heading2}
          tag='h1'
          description={description}
        />
      </div>
        <Observe
          className="components"
          callback={handleObserved}
        >
          {props['team-grid']}
        </Observe>
      <ContactPanel/>
      <Footer />
    </StyledTeamPage>
  );
}


const StyledTeamPage = styled.div`

  .hero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 ${spacing.mobile.x}px;
    align-items: center;
    text-align: center;

    &-video {
      ${position('absolute', 0, 0, 0, 0)};
      overflow: hidden;

      & > video {
        ${position('absolute', 0, 'auto', 'auto', 0)};
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .split-text {
      color: white;
      z-index: 2;

      .heading {
        font-size: ${type.h2};
      }
    }

    .content {
      position: relative;
      z-index: 2;
      color: white;
      padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;

      &-title {
        margin: 0 0 24px 0;
        font-size: ${type.h2};

        & > span {
          display: block;

          @media ${at('tablet')} {
            display: inline-block;
          }

          &:first-child {
            font-weight: 400;
          }
        }
      }

      &-text {
        max-width: 600px;
        margin: 0 auto;
      }
    }
  }
`;

export default withPlaceholder('team-grid')(TeamPage);
