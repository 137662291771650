import React from 'react';
import styled from 'styled-components';
import { Image, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

// assets
import { colors, t } from '../../assets/settings';
import { horizontalPadding, negatePadding, contentConstraint, at, hasProp, hover, webpize } from '../../assets/utilities';

// components
import SplitText from '../_internal/SplitText';
import Columns from '../_internal/Columns';

const TextColumns = (props) => {

  const {
    heading1,
    heading2,
    description,
    tagText,
    tagPosition,
    bgColor,
    colItems,
    numCols,
    image,
    mobileImage
  } = props.fields;

  const mobileWebp = webpize(mobileImage),
        desktopWebp = webpize(image);

  return (
    <StyledTextColumns
      bgColor={hasProp(bgColor) ? bgColor.value : 'transparent'}
      whiteText={getFieldValue(props.fields, 'whiteText')}
      numCols={hasProp(numCols) ? numCols.value : 1}
      hasImage={hasProp(image)}
    >
      <SplitText
        tag="h3"
        heading1={heading1}
        heading2={heading2}
        description={description}
        tagText={tagText}
        tagPosition={tagPosition}
      />
      <Columns
        items={colItems}
        numCols={numCols}
      />
      {
        hasProp(image) &&
        <div className="image">
          <picture>
            {
              hasProp(mobileImage) &&
              <>
                <source srcSet={mobileWebp} media={`(max-width: 600}px)`} type="image/webp" />
                <source srcSet={mobileImage.value.src} media={`(max-width: 600}px)`} />
              </>
            }
            <source srcSet={desktopWebp} type="image/webp" />
            <Image media={image} />
          </picture>
        </div>
      }
    </StyledTextColumns>
  );
}


const StyledTextColumns = styled.div`
  ${horizontalPadding()};
  padding-top: 50px;
  padding-bottom: ${props => props.hasImage ? 0 : '50px'};
  background-color: ${props => props.bgColor};
  color: ${props => props.whiteText ? 'white' : 'auto'};

  @media ${at('tablet')} {
    padding-top: 90px;
    padding-bottom: ${props => props.hasImage ? 0 : '90px'};
  }

  .split-text {
    text-align: center;
    font-size: 40px;
    margin-bottom: 20px;

    @media ${at('tablet')} {
      margin-bottom: 40px;
      font-size: 50px;
    }
  }

  .columns {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.2;
    ${contentConstraint(850)};


    &-inner {
      margin: 0 -20px;
      display: flex;
      flex-wrap: wrap;
    }

    &-list {
      margin: 0 20px;
      padding: 0;
      width: 100%;
      list-style-type: none;

      & > li {
        padding: 8px 0;

        & > a {
          color: ${colors.bodyText};
          transition: ${t.t} ${t.e};
          font-weight: 500;

          ${hover()} {
            color: ${colors.red};
          }
        }
      }

      @media ${at(5)} {
        width: calc(50% - 40px);
      }

      @media ${at(8)} {
        width: calc((100% / ${props => props.numCols}) - 40px);
      }
    }
  }

  .image {
    ${negatePadding()};
    margin-top: 45px;

    @media ${at(6)} {
      text-align: center;
    }

    img {
      min-width: 75%;
      max-width: 100%;
      vertical-align: bottom;
    }
  }
`;

export default TextColumns;
