import React from 'react';
import { Text, RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { spacing, type, colors, t } from '../../assets/settings';
import { at, getRandomInt, icon, hasProp, position, hover } from '../../assets/utilities';

// components
import Parallax from '../_internal/Parallax';
import DualImage from '../_internal/DualImage';
import Footer from '../_internal/Footer';
import ActionTag from '../_internal/ActionTag';
import SiteLink from '../_internal/SiteLink';

import randomImg1 from '../../assets/img/random1.jpg';
import randomImg2 from '../../assets/img/random2.jpg';
import randomImg3 from '../../assets/img/random3.jpg';
import randomImg1webp from '../../assets/img/random1.webp';
import randomImg2webp from '../../assets/img/random2.webp';
import randomImg3webp from '../../assets/img/random3.webp';

const randoms = [[randomImg1, randomImg1webp], [randomImg2, randomImg2webp], [randomImg3, randomImg3webp]],
      randomSelection = randoms[getRandomInt(0, randoms.length - 1)],
      randomDefault = {
        value: {
          src: randomSelection[0],
          alt: 'Default Office Image'
        }
      },
      randomWebp = {
        value: {
          src: randomSelection[1],
          alt: 'Default Office Image'
        }
      }

const BioPage = (props) => {

  const {
    firstName,
    lastName,
    linkedIn,
    position,
    credential,
    description,
    bannerImage,
    image,
    focalX,
    focalY
  } = props.fields;

  return (
    <StyledBioPage
      x={focalX && focalX.value}
      y={focalY && focalY.value}
    >
      <div className="hero -hero">
        <DualImage
          breakpoint={1100}
          desktop={hasProp(bannerImage) ? bannerImage : randomDefault}
          desktopWebp={hasProp(bannerImage) ? bannerImage : randomWebp}
          mobile={randomDefault}
          mobileWebp={randomWebp}
        />
      </div>
      <Parallax
        maxOffset={-420}
      >
        <div className="bio">
          {
            hasProp(image) &&
            <div className="headshot">
              <Image media={image} />
              {
                hasProp(linkedIn) &&
                <Link className="social-link -linkedin" field={linkedIn} />
              }
            </div>
          }
          <div className="content">
            <h1 className="content-name"><Text field={firstName} /> <span><Text field={lastName} /></span></h1>
            <div className="content-position">
              <Text field={position} />
            </div>
            <div className="content-credential">
              <Text field={credential} />
            </div>
            <div className="content-description">
              <RichText field={description} />
            </div>
            <ActionTag
              className="link-back"
              reverse={true}
              lineColor={colors.orange}
            >
              <SiteLink to="/about/team">Back to Team</SiteLink>
            </ActionTag>
          </div>
        </div>
      </Parallax>
      <Footer />
    </StyledBioPage>
  );
}

const imageMax = 285;

const StyledBioPage = styled.div`

  .hero {
    position: relative;

    & > .dual-image {
      img {
        object-position: ${props => props.x || '50%'} ${props => props.y || 0};
      }
    }
  }

  .bio {
    margin: 200px auto 0;
    padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
    width: calc(100% - ${spacing.mobile.x}px);
    background-color: white;
    transition: ${t.t} ${t.e};

    @media ${at('tablet')} {
      max-width: 700px;
    }

    @media ${at('desktop')} {
      margin-top: 0;
      padding: 55px 75px;
      width: calc(100% - ${spacing.desktop.x}px);
      display: flex;
    }

    @media ${at(1200)} {
      max-width: 885px;
    }

    .headshot {
      flex: none;
      max-width: ${imageMax}px;
      margin: -${imageMax / 2}px 0 10px;

      @media ${at('desktop')} {
        margin-left: -30%;
        margin-right: 75px;
        margin-top: 80px;
      }

      img {
        width: 100%;
        height: auto;
      }

      .social-link {
        display: block;
        position: relative;
        background-color: black;
        width: 33px;
        height: 33px;
        margin-top: 10px;
        margin-left: auto;
        font-size: 0;
        border-radius: 50%;
        transition: ${t.t} ${t.e};

        &::before {
          ${position('absolute', '50%', 'auto', 'auto', '50%', '-50%', '-50%')};
        }

        &.-linkedin {

          &::before {
            ${icon('linkedin')};
            font-size: 14px;
          }
        }

        ${hover()} {
          background-color: ${colors.red};
          transition: ${t.t} ${t.e};
        }
      }
    }

    .content {

      &-name {
        font-size: ${type.h2};
        font-weight: 400;
        margin: 0 0 14px 0;

        & > span {
          font-weight: 900;
        }
      }

      &-position {
        font-size: ${type.h3};
        margin-bottom: 5px;
      }

      &-credential {
        font-size: 18px;
        font-style: italic;
      }

      &-description {
        margin-top: 36px;
      }

      .link-back {
        opacity: 0;
        margin-top: 40px;
        transition: ${t.t} ${t.e};

        .-ready & {
          opacity: 1;
        }

        a {
          padding-left: 30px;
          display: inline-block;
          font-size: 20px;
          color: ${colors.bodyText};

          &::before {
            ${icon('arrow-right')};
            ${position('absolute', 5, 'auto', 'auto', 0)};
            margin-right: 10px;
            transform: rotate(180deg);
            font-size: 10px;
          }
      }
    }
  }
`;

export default BioPage;
