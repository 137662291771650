import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

import { t, colors } from '../../../assets/settings';
import { hover } from '../../../assets/utilities';

class ActionTag extends Component {

  constructor(props) {
    super(props);
    this.state = {
      animationIndex: null,
      canAnimate: true
    };
  }

  handleMouseEnter = () => {
    this.setState({ animationIndex: 0 });
  }

  handleMouseLeave = () => {
    if (this.state.canAnimate) {
      this.setState({ animationIndex: 1 });
    }
  }

  // set to active and prevent other events
  handleClick = () => {
    this.setState({
      canAnimate: false,
      animationIndex: 0
    });
  }

  render() {

    const {
      reverse,
      lineColor,
      className,
      children
    } = this.props;

    return (
      <StyledActionTag
        className={className}
        reverse={reverse}
        lineColor={lineColor || colors.red}
        onFocus={this.handleMouseEnter}
        onBlur={this.handleMouseLeave}
        onMouseEnter={this.handleMouseEnter}
        onClick={this.handleClick}
        onMouseLeave={this.handleMouseLeave}
        animationIndex={this.state.animationIndex}
        isDisabled={this.props.isDisabled}
      >
        {children}
      </StyledActionTag>
    );
  }
};

const StyledActionTag = styled.div`
    position: relative;
    display: inline-block;
    overflow: hidden;
    opacity: ${props => props.isDisabled ? .3 : 1};
    pointer-events: ${props => props.isDisabled ? 'none': 'auto'};

    & > * {
      padding-bottom: 10px;
      transition: ${t.t} ${t.e};

      ${hover()} {
        color: ${props => props.lineColor} !important;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: ${props => props.reverse ? 'auto' : '100%'};
      left: ${props => props.reverse ? 0 : 'auto'};
      width: 100%;
      height: 3px;
      background-color: ${props => props.lineColor};
      animation-name: ${props => props.reverse ? animationsReverse[props.animationIndex] : animations[props.animationIndex]};
      animation-fill-mode: forwards;
      animation-duration: .5s;
    }
`;

const slideIn = keyframes`

  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
`;

const slideOut = keyframes`

  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(calc(200% + 1px));
  }
`;

const slideInReverse = keyframes`

  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`;

const slideOutReverse = keyframes`

  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(100% + 1px));
  }
`;

const animations = [slideIn, slideOut],
      animationsReverse = [slideOutReverse, slideInReverse];

export default ActionTag;
