import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

//assets
import { chunkArray, hasProp } from '../../../assets/utilities';

import SiteLink from '../SiteLink';

const Columns = (props) => {

  const {
    items,
    numCols
  } = props;

  const chunkedItems = chunkArray([...items], numCols.value);

  return (
    <div className="columns">
      <div className="columns-inner">
        {
          chunkedItems.map((column, index) => {
            return (
              <ul key={index} className="columns-list">
                {
                  column.map((item, index) => {
                    const { textField, link } = item.fields;

                    return (
                      <li key={index}>
                        {
                          hasProp(link) ?
                          <SiteLink className="link" to={link.value.href}>
                            <Text field={textField} />
                          </SiteLink>
                          :
                          <Text field={textField}/>
                        }
                      </li>
                    );
                  })
                }
              </ul>
            )
          })
        }
      </div>
    </div>
  );
}

export default Columns;
