import React from 'react';
// import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { spacing, t, colors, type } from '../../assets/settings';
import { at, position, hasProp, hover } from '../../assets/utilities';
import defaultImage from '../../assets/img/random3.jpg';

import Parallax from '../_internal/Parallax';
import DualImage from '../_internal/DualImage';
import SiteLink from '../_internal/SiteLink';
import Footer from '../_internal/Footer';

const SiteMap = (props) => {

  const {
    heroImage,
    lists
  } = props.fields;

  return (
    <StyledSiteMap>
      <div className="hero -hero">
        {
          hasProp(heroImage)?
          <DualImage desktop={heroImage} />
          :
          <div className="default-image">
            <img src={defaultImage} alt="defaultImage" />
          </div>
        }
      </div>
      <Parallax
        maxOffset={-500}
      >
        <div className="content">
          <h1 className="content-title">Looking for something?</h1>
          {
            lists && lists.map((list, index) => (
              <div className="content-group" key={index}>
                {
                  list.header &&
                  <h2 className="content-group-header">
                    <SiteLink to={list.header.href}>
                      {list.header.text}
                    </SiteLink>
                  </h2>
                }
                {
                  list.items && list.items.length > 0 &&
                  <ul className="content-group-list">
                    {
                      list.items.map((item, index) => (
                        <li className="content-group-list-item" key={index}>
                          <SiteLink to={item.href}>
                            {item.text}
                          </SiteLink>
                        </li>
                      ))
                    }
                  </ul>
                }
              </div>
            ))
          }
        </div>
      </Parallax>
      <Footer />
    </StyledSiteMap>
  );
}


const StyledSiteMap = styled.div`

  .hero {
    position: relative;

    .default-image {
      ${position('absolute', 0, 0, 0, 0)};
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }
    }
  }

  .content {
    margin: 0 auto;
    padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
    max-width: 885px;
    width: calc(100% - ${spacing.mobile.x}px);
    background-color: white;
    transition: ${t.t} ${t.e};

    @media ${at('desktop')} {
      padding: 55px 75px;
    }

    &-title {
      font-size: ${type.h2};
      font-weight: 300;
    }

    a {
      color: ${colors.bodyText};
    }

    &-group {

      &-header {
        display: block;
        font-size: 12px;
        letter-spacing: ${type.letterSpacing};
        text-transform: uppercase;
        margin-bottom: 24px;
        border-bottom: 2px solid ${colors.grayBorder};

        & > a {
          display: inline-block;
          padding: 12px 0;
          position: relative;

          &::before {
            content: '';
            ${position('absolute', 'auto', 'auto', 0, 0, 0, '100%')};
            height: 4px;
            width: 40px;
            background-color: ${colors.red};
            transition: ${t.t} ${t.e};
          }

          ${hover()} {

            &::before {
              width: 100%;
            }
          }
        }
      }

      &-list {
        list-style-type: none;
        padding: 0;
        line-height: ${type.lineHeight.p};
        column-count: 1;
        list-style-type: none;
        padding: 0;

        @media ${at(6)} {
          column-count: 2;
          column-gap: 40px;
        }

        @media ${at('tablet')} {
          column-gap: 60px;
        }

        @media ${at('desktop')} {
          column-gap: 120px;
        }


        &-item {
          position: relative;
          margin-bottom: 10px;

          a {
            transition: ${t.t} ${t.e};

            ${hover()} {
              color: ${colors.red};
            }
          }
        }
      }
    }
  }
`;

export default SiteMap;
