import React from 'react';
import { Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { type, t } from '../../assets/settings';
import { at, hasProp, position } from '../../assets/utilities';

const ScrollLeft = (props) => {

  const {
    ask,
    detail,
    showIndex,
    hideIndex
  } = props.fields;

  let hidden;

  if (hideIndex) {
    hidden = props.activePanel < showIndex.value || props.activePanel === hideIndex.value
  } else {
    hidden = props.activePanel < showIndex.value;
  }

  return (
    <StyledScrollLeft
      className={`panel-item ${hidden ? '-hidden' : ''}`}
      large={getFieldValue(props.fields, 'large')}
      lastActive={props.lastActive}
    >
      {
        hasProp(ask) &&
        <Text tag="div" className="text" field={ask} />
      }
      {
        hasProp(detail) &&
        <Text tag="p" className="detail" field={detail} />
      }
    </StyledScrollLeft>
  );
}

const StyledScrollLeft = styled.div`
  ${position('absolute', 0, 0, 'auto', 0)};
  display: flex;
  flex: none;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  max-height: ${props => props.lastActive ? '100%' : '95%'};
  padding: 20px;
  background-color: white;
  text-align: right;
  transition: max-height 1.3s ${t.expo};

  .text {
    transform: none;
    font-size: ${type.h2};
    font-weight: 300;
    white-space: nowrap;

    .-ready & {
      transition: ${t.t} ${t.expo} .5s;
    }

    @media ${at('desktop')} {
      font-size: ${props => props.large ? type.h1 : type.h2};
    }
  }

  .detail {
    ${position('absolute', 'auto', 'auto', 100, 'auto')};
    max-width: 240px;
    font-size: ${type.p};
    transition: ${t.t} ${t.expo} .5s;
  }

  &.-hidden {
    opacity: 0;
    transition: ${t.t} ${t.expo};

    .text,
    .detail {
      opacity: 0;
      transform: ${props => props.scrollDown ? 'translateY(50px)' : 'translateY(-50px)'};
      transition: ${t.t} ${t.expo};
    }
  }
`;

export default ScrollLeft;
