import React from 'react';
import Component from '../Component';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';
import { TweenMax, ScrollToPlugin } from 'gsap/all';

// assets
import { at, position } from '../../assets/utilities';

// components
import WorkIndex from '../_internal/WorkIndex';
import Swipe from '../_internal/Swipe';
import ScrollHint from '../_internal/ScrollHint';

// context
import MobileContext from '../../context/mobile';

class WorkPage extends Component {

  static contextType = MobileContext;

  constructor(props) {
    super(props);
    this.el = React.createRef();
    this.state = {
      peekIndex: false,
      indexOpen: false
    }
    this.window.ScrollToPlugin = ScrollToPlugin;
  }

  handleIndexButton = () => {
    this.setState(prevState => ({
      indexOpen: !prevState.indexOpen
    }), () => {
      document.body.classList.toggle('-overlay');
    });
  }

  handleSwipe = (e, direction) => {
    if (direction > 0) {
      this.checkPeek();
    } else {
      this.setState({
        peekIndex: false
      })
    }
  }

  checkPeek = () => {
    const workEl = this.el.current;

    if (!this.context) {
      // desktop
      const totalWidth = workEl.scrollLeft + window.innerWidth,
            reachedEnd = workEl.scrollWidth <= totalWidth + 50;

      this.setState({ peekIndex: reachedEnd });

    } else {
      // mobile
      const { pageYOffset, innerHeight} = window,
            distanceToTop = pageYOffset + (innerHeight * 1.5),
            reachedEnd = distanceToTop >= workEl.scrollHeight * .8;

      this.setState({ peekIndex: reachedEnd });
    }
  }

  scrollChanger = (e) => {
    if (this.state.indexOpen) return;

    const workEl = this.el.current;

    if (!this.context) {
      e.preventDefault();
      const delta = e.deltaY + e.deltaX,
            { scrollLeft } = workEl;

      TweenMax.to(workEl, .1, { scrollTo: { x: scrollLeft + (delta > 400 ? 200 : delta / 1.3)}})
    }

    this.checkPeek();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.peekIndex !== this.state.peekIndex ||
      nextState.indexOpen !== this.state.indexOpen
    );
  }

  componentDidMount() {
    this.window.addEventListener('wheel', this.scrollChanger, { passive: false });
  }

  componentWillUnmount() {
    this.window.removeEventListener('wheel', this.scrollChanger, { passive: false });
  }

  render() {

    const {
      clientList
    } = this.props.fields;

    return (
      <StyledWorkPage className={`work ${this.state.indexOpen ? '-overlay' : ''}`}>
        <Swipe
          className="swipe-container"
          vertical={this.context}
          handleSwipe={this.handleSwipe}
          propRef={this.el}
        >
          {this.props['work-items']}

          <WorkIndex
            toggleOpen={this.handleIndexButton}
            isOpen={this.state.indexOpen}
            peekIndex={this.state.peekIndex}
            title="But wait, there's more"
            list={clientList}
          />
          <ScrollHint vertical={true} />
        </Swipe>
      </StyledWorkPage>
    );
  }
}

const StyledWorkPage = styled.div`

  .swipe-container {

    @media ${at('desktop')} {
      display: flex;
      flex-wrap: no-wrap;
      overflow-x: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .scroll-hint {
    ${position('absolute', 0, 0, 0, 'auto')};
  }
`;

export default withPlaceholder('work-items')(WorkPage);
