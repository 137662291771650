import { Expo } from 'gsap';
import enterDefault from './enter-default';
import exitDefault from './exit-default';

export default ({ type, node, mask, exitType, isMobile, timeline }) => {



  if (type === 'enter') {
    timeline = enterDefault(timeline, mask);

    const text1 = node.querySelector('.content-name'),
          text2 = node.querySelector('.content-position'),
          text3 = node.querySelector('.content-credential'),
          text4 = node.querySelector('.content-description'),
          headshot = node.querySelector('.headshot');

    const duration = .8,
          duration2 = .4;

    if (text1) {
      timeline
        .from(text1, duration2,
          {
            y: '50px',
            autoAlpha: 0,
            ease: Expo.easeOut
          }, duration + .05)
        .from(text2, duration2,
          {
            y: '75px',
            autoAlpha: 0,
            ease: Expo.easeOut
          }, duration + .1)
        .from(text3, duration2,
          {
            y: '100px',
            autoAlpha: 0,
            ease: Expo.easeOut
          }, duration + .15)
        .from(text4, duration2,
          {
            y: '125px',
            autoAlpha: 0,
            ease: Expo.easeOut
          }, duration + .2)
    }

    if (headshot) {
      timeline
        .from(headshot, .5,
          {
            x: '-200px',
            autoAlpha: 0,
            ease: Expo.easeOut
          }, duration + .3);
    }
    return timeline;
  }

  if (type === 'exit') {
    timeline = exitDefault(timeline);
  }


  return timeline;
}
