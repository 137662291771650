import React from 'react';
import Component from '../../Component';
import styled from 'styled-components';

// assets
import { shuffleArray, position } from '../../../assets/utilities';

// components
import FCGridItem from '../FCGridItem';

class FCRandomizer extends Component {

  constructor(props) {
    super(props);
    this.clients = [...this.props.clients];
    this.gridItems = Array.from(Array(8).keys());
    this.state = {
      timeoutID: null,
      randomOrder: [],
      activeItems: []
    }
  }

  randomizeOrder = () => {
    return shuffleArray(this.gridItems).map(item => item + 1);
  }

  fetchItems = () => {
    const newItems = this.clients.splice(0, this.gridItems.length);
    this.clients = this.clients.concat(newItems);
    return newItems;
  }

  updateState = () => {
    const newItems = this.fetchItems(),
          newOrder = this.randomizeOrder();

    // if (this.timeoutID !== null) {
      this.setState({
        randomOrder: newOrder,
        activeItems: newItems,
        timeoutID: 1
      });
    // }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.timeoutID !== nextState.timeoutID;
  }

  componentDidMount() {
    this.updateState();
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
    this.timeoutID = null;
  }

  render() {
    return (
      <StyledFCRandomizer
        className="clients-grid"
      >
        {
          this.gridItems.map((item, index) => {
            return (
              <FCGridItem
                key={index}
                data={this.state.activeItems[index]}
                order={this.state.randomOrder[index]}
              />
            );
          })
        }
      </StyledFCRandomizer>
    );
  }
}

const StyledFCRandomizer = styled.div`
  ${position('absolute', 0, 0, 0, 0)};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "item1 item2 item3"
    "..... item4 item5"
    "item6 item7 item8";
  justify-items: stretch;
  align-items: stretch;
`;

export default FCRandomizer;
