import React from 'react';
import styled from 'styled-components';

import { colors, spacing, t } from '../../assets/settings';
import { at, hasProp, hover } from '../../assets/utilities';

import DynamicTag from '../_internal/DynamicTag';

const LogoLinks = (props) => {

  const {
    logos
  } = props.fields;

  return (
    <StyledLogoLinks
      numItems={logos.length}
    >
      <div className="inner">
        {
          logos &&
          logos.map((item, index) => {
            const { icon, link } = item.fields;
            return (
              <DynamicTag
                key={index}
                className="logo"
                Tag={hasProp(link) ? 'a' : 'div'}
                to={link.value.href}
              >
                <div className={`logo-icon icon-${icon.value}`}>
                  <span className="sr-text">{item.fullName}</span>
                </div>
              </DynamicTag>
            )
          })
        }
      </div>
    </StyledLogoLinks>
  );
}

const getPercent = (numItems, divisor) => {
  const percent = 100 / (Math.ceil(numItems / divisor));
  return `${percent}%`;
}


const StyledLogoLinks = styled.div`
  background-color: ${colors.grayBg};
  padding: 20px ${spacing.mobile.x}px;

  @media ${at('desktop')} {
    padding: 20px ${spacing.desktop.x}px;
  }

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .logo {
    flex: none;
    width: calc(${props => getPercent(props.numItems, 3)});

    @media ${at(650)} {
      width: calc(${props => getPercent(props.numItems, 2)});
    }

    @media ${at('desktop')} {
      width: calc(${props => getPercent(props.numItems, 1)});
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${colors.grayMedium};
      font-size: 80px;
      transition: ${t.t} ${t.e};

      @media ${at('desktop')} {
        font-size: 85px;
      }
    }

    ${hover()} {

      .logo-icon {
        color: ${colors.gray};
      }
    }
  }
`;

export default LogoLinks;
