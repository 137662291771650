import React from 'react';
import styled from 'styled-components';

import SocialLinks from '../SocialLinks';

import { spacing, colors, t } from '../../../assets/settings';
import { at, position, hover } from '../../../assets/utilities';

// data
import { badges, seoLinks } from './data';

import SiteLink from '../SiteLink';
import DynamicTag from '../DynamicTag';

const backgroundColor = '#dee4ec',
      textColor = '#8d8b8e';

const Footer = (props) => {

  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter className="footer">
      <div className="section">
        <div className="section-inner">
          {
            badges.map((item, index) => {

              const {
                alt,
                src,
                width,
                height,
                href
              } = item;

              return (
                <DynamicTag
                  key={index}
                  className="badge"
                  Tag={href ? 'a' : 'div'}
                  href={href}
                >
                  <img
                    alt={alt}
                    src={src}
                    width={width}
                    height={height}
                  />
                </DynamicTag>
              )
            })
          }
        </div>
      </div>
      <div className="section">
        <div className="section-block">
          <div>Interactive Strategies is an award-winning  web design agency and digital marketing firm serving Washington DC, VA, and MD</div>
        </div>
        <div className="section-block">
          <div className="section-block-item">
            <a
              href="https://www.google.com/maps/dir//1133+Connecticut+Avenue,+1133+Connecticut+Ave+NW,+Washington,+DC+20036/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x89b7b7b8c4f51595:0x96cd9a79b6973cc?sa=X&ved=0ahUKEwiXjPe_gIToAhXEmnIEHc0CAP0Q48ADCCswAA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>1133 Connecticut Ave NW</div>
              <div>Suite 600</div>
              <div>Washington, DC</div>
              <div>20036</div>
            </a>
            <a href="tel:1-202-223-8656">
              <div>(202) 223-8656</div>
            </a>
          </div>
          <div className="section-block-item">
            {
             seoLinks.map((item, index) => {
               return (
                 <div key={index}>
                  <SiteLink to={item.href}>
                    {item.text}
                  </SiteLink>
                 </div>
               )
             })
            }
          </div>
        </div>
        <div className="section-block">
          <div className="section-block-item">
            <SocialLinks color={textColor} />
          </div>
          <div className="section-block-item">
            <div>All content © {currentYear} Interactive Strategies</div>
          </div>
          <div className="section-block-item -utility-links">
            <SiteLink to="/privacy-policy">
              Privacy Policy
            </SiteLink>
            <a href='javascript:void(0)' onclick='revisitCkyConsent()'>Do Not Sell/Share My Data</a>
            <SiteLink to="/sitemap">
              Sitemap
            </SiteLink>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
}

const StyledFooter = styled.div`
  position: relative;
  font-size: 13px;
  line-height: 1.7;
  background-color: ${backgroundColor};
  color: ${textColor};
  z-index: 3;

  a {
    color: ${colors.grayLight} !important;
    transition: ${t.t} ${t.e};

    ${hover()} {
      color: ${colors.linkColor} !important;
    }
  }

  .section {
    text-align: center;
    padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;

    @media ${at('desktop')} {
      padding: ${spacing.desktop.y}px ${spacing.desktop.x}px;
    }

    &-block {
      margin-bottom: 17px;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(3) {
        .section-block-item {
          &:nth-child(3) {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            column-gap: 20px;
          }
        }
      }

      &-item {
        margin-bottom: 17px;

        &.-utility-links {
          margin-bottom: 0;

          a {
            margin: 0;
          }
        }

        @media ${at(5)} {
          margin-bottom: 10px;

          * {
            display: inline;
            margin-right: 16px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    /* badges section */
    &:nth-child(1) {
      position: relative;

      &::before {
        content: '';
        ${position('absolute', 'auto', 0, 0, 0)};
        height: 1px;
        width: 100%;
        background-color: ${textColor};
      }

      .section-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -${spacing.mobile.y / 2}px -${spacing.mobile.x / 2}px;

        .badge {
          flex: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 15px;
          width: calc(50% - ${spacing.mobile.x}px);

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }

      @media ${at(6)} {

        .section-inner {

          .badge {
            width: calc(33.33% - ${spacing.mobile.x}px);
          }
        }
      }

      @media ${at(11)} {

        .section-inner {

          .badge {
            width: calc(20% - ${spacing.mobile.x}px);
          }
        }
      }
    }

    &:nth-child(2) {

      @media ${at(9)} {
        display: flex;
        justify-content: space-between;

        .section-block {
          flex-grow: 1;
          flex-shrink: 1;

          &:nth-child(1) {
            flex-basis: 20%;
            text-align: left;
            max-width: 320px;
          }

          &:nth-child(2) {
            flex-basis: 60%;
            max-width: 500px;
            margin: 0 40px;

            .section-block-item {

              &:nth-child(1) {
                margin-bottom: 0;
              }
            }
          }

          &:nth-child(3) {
            display: flex;
            flex-direction: column;
            flex-basis: 20%;
            text-align: right;
            max-width: 320px;

            .section-block-item {

              &:nth-child(3) {
                flex-order: -1;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }
`;

export default Footer;
