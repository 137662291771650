import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { type, colors, clamp } from '../../../assets/settings';

const Eyebrow = (props) => {

  return (
    <StyledEyebrow
      className="hero-eyebrow"
    >
      {props.children}
      <Text field={props.field} />
    </StyledEyebrow>
  );
}

const StyledEyebrow = styled.h1`
  position: relative;
  margin-bottom: 15px;
  color: ${colors.grayBorder};
  font-size: ${clamp(13, 16)} !important;
  letter-spacing: ${type.letterSpacing};
  font-weight: 600;
  text-transform: uppercase;
  z-index: 2;
`

export default Eyebrow;
