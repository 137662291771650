import React from 'react';
import Component from '../../Component';
import styled from 'styled-components';

class Parallax extends Component {

  constructor(props) {
    super(props);
    this.elRef = React.createRef();
    this.state = {
      offset: 0
    }
  }

  setOffset = () => {
    const newOffset = this.props.maxOffset + (this.window.pageYOffset / 5);

    this.setState({
      offset: newOffset < 0 ? newOffset : 0
    });
  }

  componentDidMount() {
    this.setOffset();
    this.window.addEventListener('scroll', this.setOffset);
  }

  componentWillUnmount() {
    this.window.removeEventListener('scroll', this.setOffset);
  }

  render() {
    return (
      <StyledParallax
        ref={this.elRef}
        style={{
          transform: `translate3d(0, ${this.state.offset}px, 0)`
        }}
      >
        {this.props.children}
      </StyledParallax>
    );
  }
}


const StyledParallax = styled.div`
  position: relative;
  transition: 1s ease-out;
`;

export default Parallax;
