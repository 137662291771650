import React from 'react';
import styled from 'styled-components';

import { icon, hover } from '../../../assets/utilities';
import { colors, t } from '../../../assets/settings';

const items = [
  {
    href: 'https://www.facebook.com/interactivestrategies',
    text: 'Facebook'
  },
  {
    href: 'https://www.linkedin.com/company/interactive-strategies/',
    text: 'LinkedIn'
  },
  {
    href: 'https://twitter.com/Interactive_DC',
    text: 'Twitter'
  },
  {
    href: 'https://www.instagram.com/interactivestrategies/',
    text: 'Instagram'
  }
]

const SocialLinks = (props) => {

  return (
    <StyledSocialLinks
      className="social-links"
      color={props.color}
    >
      {items.map((item, index) => {
        const customClass = `-${item.text.toLowerCase()}`;
        return (
          <a key={index} className={customClass} href={item.href}>
            <span>{item.text}</span>
          </a>
        )
      })}
    </StyledSocialLinks>
  );
}


const StyledSocialLinks = styled.div`
  display: flex;
  justify-content: center;

  & > a {
    margin-right: 24px;
    font-size: 18px;

    &:last-child {
      margin-right: 0;
    }

    ${hover()} {

      &::before {
        color: ${colors.red};
        transition: ${t.t} ${t.e};
      }
    }

    &::before {
      color: ${props => props.color};
      transition: ${t.t} ${t.e};
    }

    &.-facebook {

      &::before {
        ${icon('facebook')};
      }
    }

    &.-linkedin {

      &::before {
        ${icon('linkedin')};
      }
    }

    &.-twitter {

      &::before {
        ${icon('twitter')};
      }
    }

    &.-instagram {

      &::before {
        ${icon('instagram')};
      }
    }

    span {
      font-size: 0;
      color: transparent;
    }
  }
`;

export default SocialLinks;
