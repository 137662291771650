import React from 'react';
import { Image, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { type, t, colors } from '../../assets/settings';
import { at, horizontalPadding, negatePadding, contentConstraint, hasProp, icon, position, webpize } from '../../assets/utilities';

// components
import SplitText from '../_internal/SplitText';
import Tag from '../_internal/Tag';
import ActionTag from '../_internal/ActionTag';
import SiteLink from '../_internal/SiteLink';

const BlockFull = (props) => {

  const {
    tagText,
    heading1,
    heading2,
    description,
    link,
    image,
    mobileImage
  } = props.fields;

  const tagRight = getFieldValue(props.fields, 'tagRight'),
        overlapBottom = getFieldValue(props.fields, 'overlapBottom'),
        textAlignLeft = getFieldValue(props.fields, 'textAlignLeft');

  const mobileWebp = webpize(mobileImage),
        desktopWebp = webpize(image);

  const isAny = (array) => {
    let valid = false;
    array.forEach(item => {
      if (hasProp(item)) {
        valid = true;
      }
    });
    return valid;
  };

  return (
    <StyledBlockFull
      overlapBottom={overlapBottom}
      textAlignLeft={textAlignLeft}
    >
      {
        hasProp(tagText) &&
        <Tag text={tagText} location={tagRight ? 'right' : 'left'} />
      }
      {
        isAny([heading1, heading2, description]) &&
        <SplitText
          tag={'h3'}
          heading1={heading1}
          heading2={heading2}
          description={description}
        >
        {
          hasProp(link) &&
          <ActionTag
            className={'link'}
            reverse={true}
            lineColor={colors.orange}
          >
            <SiteLink to={link.value.href}>
              {link.value.text}
            </SiteLink>
          </ActionTag>
        }
        </SplitText>
      }
      {
        hasProp(image) &&
        <div className="image">
          <picture>
            {
              hasProp(mobileImage) &&
              <>
                <source srcSet={mobileWebp} media={`(max-width: 800px)`} type="image/webp" />
                <source srcSet={mobileImage.value.src} media={`(max-width: 800px)`} />
              </>
            }
            <source srcSet={desktopWebp} type="image/webp" />
            <Image media={image} />
          </picture>
        </div>
      }
    </StyledBlockFull>
  );
}


const StyledBlockFull = styled.div`
  position: relative;
  ${horizontalPadding()};
  padding-top: 60px;
  padding-bottom: 60px;

  .split-text {
    text-align: ${props => props.textAlignLeft ? 'left' : 'center'};
    padding: 0 0 50px;

    @media ${at('desktop')} {
      margin-bottom: 30px;
    }

    @media ${at('desktop')} {
      padding-top: 30px;
    }

    .heading {
      font-size: ${type.h2};
    }

    .description {
      ${contentConstraint(720)};
    }

    .link {
      margin-top: 40px;

      a {
        display: inline-block;
        font-size: 20px;
        color: ${props => props.whiteText ? 'white' : colors.bodyText}

        &::after {
          ${icon('arrow-right')};
          ${position('relative', -2)};
          margin-left: 10px;
          font-size: 10px;
        }
      }
    }
  }

  .section-tag {

    @media ${at('desktop')} {
      ${props => props.textAlignLeft ? 'left: calc(50vw - 410px)' : ''};
    }
  }

  .image {
    position: relative;
    ${negatePadding()};
    z-index: 1;

    img {
      width: 100%;
      height: auto;
    }

    @media ${at('desktop')} {
      margin-bottom: ${props => props.overlapBottom ? '-160px' : 'auto'};
    }
  }

  + div {

    @media ${at('desktop')} {
      padding-top: ${props => props.overlapBottom ? '160px' : 'auto'}
    }
  }

  /* ANIMATION */
  &.-animate-scroll-on {

    .split-text,
    .image {
      opacity: 1;
      transform: none;
    }
  }

  .split-text,
  .image {
    opacity: 0;
    transform: translateY(50px);
  }

  .split-text {
    transition: ${t.t} ${t.e};
  }

  .image {
    transition: ${t.t} ${t.e} ${t.d};
  }
`;

export default BlockFull;
