import React from 'react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

//assets
import { at } from '../../assets/utilities';

const GridSix = (props) => (
  <StyledGridSix>
    {props['grid-items']}
  </StyledGridSix>
);

const StyledGridSix = styled.div`

  @media ${at('tablet')} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export default withPlaceholder('grid-items')(GridSix);
