export default [
  {
    name: 'Work',
    path: '/work'
  },
  {
    name: 'Markets',
    path: '/markets'
  },
  {
    name: 'Services',
    path: '/services'
  },
  {
    name: 'About',
    path: '/about'
  },
  {
    name: 'Careers',
    path: '/careers'
  },
  {
    name: 'Contact',
    path: '/contact'
  }
];
