import { Expo } from 'gsap';

export default (timeline, maskEl, isInitial) => {

  if (maskEl) {
    maskEl.style.bottom = 'auto';
    maskEl.style.top = 0;
    maskEl.style.height = '100vh';

    timeline
      .to(maskEl, .4,
        {
          height: 0,
          ease: Expo.easeIn,
          clearProps: 'all'
        }, isInitial ? .3 : 0);
  }

  const staticLeft = document.querySelector('.left .text'),
        scrollLeft = document.querySelector('.scroll-left .text'),
        staticRight = document.querySelector('.right .text'),
        scrollRight = document.querySelector('.scroll-right .text');

  if (staticLeft) {
    timeline
      .from(staticLeft, .5,
        {
          y: 100,
          autoAlpha: 0,
          ease: Expo.easeOut
        }, .5);
  }

  if (scrollLeft) {
    timeline
      .from(scrollLeft, .5,
        {
          y: 100,
          autoAlpha: 0,
          ease: Expo.easeOut
        }, .5);
  }

  if (staticRight) {
    timeline
      .from(staticRight, .5,
        {
          y: 100,
          autoAlpha: 0,
          ease: Expo.easeOut
        }, .6);
  }

  if (scrollRight) {
    timeline
      .from(scrollRight, .5,
        {
          y: 100,
          autoAlpha: 0,
          ease: Expo.easeOut
        }, .6);
  }

  return timeline;
}
