import React, { Component } from 'react';

import MobileContext from '../../../context/mobile';

class Cat extends Component {

  static contextType = MobileContext;

  constructor(props) {
    super(props);
    this.catRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.handleMouseMove) {
      this.props.handleMouseMove(this.catRef.current, this.props.mouse, this.context);
    }
  }

  render() {

    return (
      <div className="cat" ref={this.catRef}>
        {this.props.children}
      </div>
    )
  }
}

export default Cat;
