import React, { Component } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { type, t, colors } from '../../assets/settings';
import { at, icon, hasProp, position } from '../../assets/utilities';

// context
import MobileContext from '../../context/mobile';

// components
import DualImage from '../_internal/DualImage';
import MobileSlider from '../_internal/MobileSlider';
import FCRandomizer from '../_internal/FCRandomizer';
import SiteLink from '../_internal/SiteLink';
import ArrowLink from '../_internal/ArrowLink';
import ActionTag from '../_internal/ActionTag';

class FeaturedClients extends Component {

  static contextType = MobileContext;

  render() {

    const {
      ask,
      answer,
      link,
      image,
      clients
    } = this.props.fields;

    return (
      <StyledFeaturedClients
        className="panel-item"
      >
        <div className="panel-item-inner">
          <DualImage desktop={image} />
          <div className="text">
            <Text tag="span" className="text-mobile" field={ask} />
            <Text tag="span" className="text-answer" field={answer} />
            {
              hasProp(link) &&
              <div className="mobile-link">
                <ActionTag
                  lineColor={colors.orange}
                  reverse={true}
                >
                  <SiteLink to={link.value.href}>
                    {link.value.text}
                  </SiteLink>
                </ActionTag>
              </div>
            }
            {
              hasProp(link) &&
              <ArrowLink
                isLink={true}
                href={link.value.href}
              >
                {link.value.text}
              </ArrowLink>
            }
          </div>
          <div className="clients">
            {
              this.context ?
              <MobileSlider>
                {
                  clients.slice(0, 8).map((item, index) => {
                    const { icon, fullClientName } = item.fields;
                    return (
                      <div
                        className="slider-item"
                        key={index}
                      >
                        <div className="slider-item-inner">
                          {
                            <SiteLink className="slider-item-link" to={'/work'}>
                              <div className={`logo-icon icon-${icon.value}`}>
                                <Text tag="span" className="sr-text" field={fullClientName} />
                              </div>
                            </SiteLink>
                          }
                        </div>
                      </div>
                    );
                  })
                }
              </MobileSlider>
              :
              <FCRandomizer clients={clients} />
            }
          </div>
        </div>
      </StyledFeaturedClients>
    );
  }
}

const StyledFeaturedClients = styled.div`
  text-align: center;
  color: white;
  overflow: hidden;
  height: 100%;

  .clients {
    padding: 20px 0;
    margin: 0 -25px;

    @media ${at('desktop')} {
      ${position('absolute', 0, 0, 0, 0)};
      padding: 0;
      margin: 0;
    }

    .slider-item {

      &-link {
        display: flex;
        align-items: center;
        justify-content: center;

        .logo-icon {
          font-size: 170px;
        }
      }
    }
  }

  .panel-item-inner {
    position: relative;
    padding: 50px 25px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: normal;

    @media ${at('desktop')} {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100vh;
    }
  }

  .text {
    position: relative;
    margin-bottom: 58px;
    z-index: 3;

    &-mobile,
    &-answer {
      font-size: ${type.h2};
      font-weight: 600;
    }

    &-mobile {
      margin-right: 5px;
      font-weight: 300;
    }

    .mobile-link {
      margin-top: 18px;
      font-size: 20px;

      & > * {
        padding-bottom: 10px;
      }

      a {

        &::after {
          ${icon('arrow-right')};
          ${position('relative', -2)};
          margin-left: 10px;
          font-size: 10px;
        }
      }

      @media ${at('desktop')} {
        display: none;
      }
    }

    /* desktop link */
    .arrow-link {
      display: none;

      @media ${at('desktop')} {
        display: block;
        ${position('absolute', '100%', 'auto', 'auto', 0)};
      }

      @media ${at(12)} {
        display: inline-block;
        ${position('relative', 12, 'auto', 'auto', 15)};
      }
    }
  }

  @media ${at('desktop')} {

    .text {
      margin: 0;
      opacity: 0;
      transform: none;
      transition: transform ${t.t} ${t.e}, opacity ${t.t} ${t.e};

      &-mobile {
        display: none;
      }

      &-answer {
        font-size: ${type.h2};
      }

      &-answer {
        display: inline-block;
      }
    }
  }
`;

export default FeaturedClients;
