import React from 'react';
import Component from '../Component';
import styled from 'styled-components';
import { Text } from '@sitecore-jss/sitecore-jss-react';

// components
import ContactPanel from '../_internal/ContactPanel';
import Footer from '../_internal/Footer';
import DualImage from '../_internal/DualImage';
import SplitText from '../_internal/SplitText';
import DynamicTag from '../_internal/DynamicTag';

// assets
import { contentConstraint, at } from '../../assets/utilities';
import { type, spacing } from '../../assets/settings';

class ContactPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      headOffset: 0
    }
  }

  setOffset = () => {
    const heroTextEl = document.querySelector('.hero .split-text'),
          { offsetTop } = heroTextEl;

    this.setState({ headOffset : -Math.abs(offsetTop - 80) });
  }

  componentDidMount() {
    this.setOffset();
    this.window.addEventListener('resize', this.setOffset);
  }

  componentWillUnmount() {
    this.window.removeEventListener('resize', this.setOffset);
  }

  render() {

    const {
      title,
      description,
      heroImage,
      heroMobileImage,
      heading1,
      heading2,
      address1,
      address2,
      image1,
      image2,
      link1,
      link2
    } = this.props.fields;

    return (
      <StyledContactPage
        headOffset={this.state.headOffset}
      >
        <div className="hero -hero">
          <DualImage desktop={heroImage} mobile={heroMobileImage} />
          <SplitText
            heading2={title}
            description={description}
          />
        </div>
        <div ref={this.headEl} className="head">
          <div className="head-form">
            <ContactPanel
              removeIntro={true}
              tag="h1"
              heading2="Contact us"
            />
          </div>
        </div>
        <div className="block">
          <div className="block-content">
            <SplitText
              heading1={heading1}
              heading2={heading2}
            />
            <div className="block-content-text">
              <Text tag="p" field={address1} />
              <Text tag="p" field={address2} />
            </div>
          </div>
          <div className="block-images">
            <DynamicTag
              Tag={link1 ? 'a' : 'div'}
              className="block-images-image"
              href={link1 ? link1.value.href : null}
            >
              <DualImage desktop={image1} />
            </DynamicTag>
            <DynamicTag
              Tag={link2 ? 'a' : 'div'}
              className="block-images-image"
              href={link2 ? link2.value.href : null}
            >
              <DualImage desktop={image2} />
            </DynamicTag>
          </div>
        </div>
        <Footer />
      </StyledContactPage>
    );
  }
}


const StyledContactPage = styled.div`

  .hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${spacing.mobile.x}px;
    color: white;
    text-align: center;

    @media ${at('desktop')} {
      padding: 0 ${spacing.desktop.x}px;
    }

    .split-text {

      .heading {
        font-size: 43px;

        @media ${at('tablet')} {
          font-size: 60px;
        }

        @media ${at('desktop')} {
          font-size: 85px;
        }
      }
    }
  }

  .head {
    text-align: center;
    margin-top: ${props => props.headOffset}px;

    &-content {
      display: none;
      position: relative;
      padding: 100px ${spacing.mobile.x}px;
      ${contentConstraint()};
      z-index: 2;

      @media ${at('desktop')} {
        padding-left: ${spacing.mobile.x}px;
        padding-right: ${spacing.mobile.x}px;
      }

      &-title {
        margin-bottom: 30px;
      }

      &-title {
        margin: 0;
      }

      @media ${at('desktop')} {
        padding-top: 30vh;
        padding-bottom: 100px;
      }
    }

    &-form {
      position: relative;
      padding: 0 ${spacing.mobile.x}px;
      z-index: 2;

      @media ${at('tablet')} {
        padding: 0 ${spacing.desktop.x}px;
      }

      & > .contact-panel {
        padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
        ${contentConstraint(1260)};

        @media ${at('desktop')} {
          padding: 45px 85px;
        }
      }
    }
  }

  .block {

    &-content {
      ${contentConstraint()};
      margin-top: 60px;
      margin-bottom: 60px;
      text-align: center;

      .split-text {
        z-index: 2;

        .heading {
          font-size: ${type.h2};
        }
      }

      &-text {

        p {
          margin: 0;
        }
      }
    }

    &-images {

      @media ${at('tablet')} {
        display: flex;
      }

      &-image {
        position: relative;
        display: block;
        flex: 1 1 50%;
        height: 60vw;
        max-height: 520px;
      }
    }
  }
`;

export default ContactPage;
