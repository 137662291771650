import React from 'react';
import styled from 'styled-components';

import { type } from '../../../assets/settings';
import { at, position } from '../../../assets/utilities';

const StaticLeft = (props) => {

  return (
    <StyledStaticLeft className="left">
      {props.children}
    </StyledStaticLeft>
  );
}


const StyledStaticLeft = styled.div`
  display: none;
  min-height: 600px;

  @media ${at('desktop')} {
    flex: 0 0 400px;
    position: relative;
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
  }

  @media ${at('max')} {
    flex-basis: 35vw;
  }
  

  .text {

    &-title {
      font-size: ${type.h2};

      @media ${at('desktop')} {
        margin: 0;
        font-weight: 300;
      }
    }
  }

  .detail {

    @media ${at('desktop')} {
      ${position('absolute', 'auto', 'auto', 40, 'auto')};
      max-width: 240px;
    }
  }
`;

export default StaticLeft;
