import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { type, colors, t } from '../../assets/settings';
import { at, hasProp, position, hover } from '../../assets/utilities';

// animations
import { TweenMax } from 'gsap';

// components
import StaticLeft from '../_internal/StaticLeft';
import StaticRight from '../_internal/StaticRight';
import DualImage from '../_internal/DualImage';
import MouseMove from '../_internal/MouseMove';
import Cat from '../_internal/Cat';

const ErrorPage = (props) => {

  const handleMouseMove = (el, mouse, isMobile) => {

    if (el) {

      if (isMobile) {
        el.removeAttribute('style');
      } else {
        TweenMax.to(el, .5, {
         css: {
           x: mouse.x / 10,
           y: mouse.y / 10
         }
       });
      }
    }
  }

  const {
    title1,
    title2,
    contentMarkup,
    heroImage,
    video
  } = props.fields;

  return (
    <StyledErrorPage>
      <StaticLeft>
        <div className="text">
          <Text tag="div" className="text-title" field={title1} />
        </div>
        <RichText tag="div" className="detail" field={contentMarkup} />
      </StaticLeft>
      <StaticRight>
        <MouseMove
          disabled={false}
          render={mouse => (
            <>
              {
                hasProp(heroImage) &&
                <DualImage desktop={heroImage} />
              }
              <h1 className="text">
                <span className="title-left"><Text field={title1} /> </span><span className="title-right"><Text field={title2} /></span>
              </h1>
              <RichText className="detail" field={contentMarkup} />
              {
                hasProp(video) &&
                <Cat
                  mouse={mouse}
                  handleMouseMove={handleMouseMove}
                >
                  <div className="video">
                    <video src={video.value.src} loop muted autoPlay playsInline></video>
                  </div>
                </Cat>
              }
            </>
          )
        }/>
      </StaticRight>
    </StyledErrorPage>
  );
}


const StyledErrorPage = styled.div`
  height: 100vh;
  min-height: 500px;
  display: flex;
  text-align: center;

  @media ${at('desktop')} {
    text-align: left;
  }

  .left,
  .right {

    .text-title,
    .title-left,
    .title-right {
      font-size: ${type.h1};
    }
  }

  .detail {

    a {
      color: white;
      border-bottom: 2px solid white;
      transition: ${t.t} ${t.e};

      ${hover()} {
        color: ${colors.red};
        border-color: transparent;
      }

      @media ${at('desktop')} {
        color: ${colors.red};
        border-color: transparent;

        ${hover()} {
          color: ${colors.bodyText};
          border-color: ${colors.bodyText};
        }
      }
    }
  }

  .cat {
    position: relative;
    width: 50%;
    margin: 0 auto;
    max-width: 330px;
    min-width: 200px;
    box-shadow: 10px 15px 15px 0 rgba(0, 0, 0, .25);

    @media ${at('desktop')} {
      position: absolute;
      left: 50%;
      margin: 0;
    }

    &::before {
      content: '';
      display: block;
      padding-bottom: 70%;
    }

    .video {
      ${position('absolute', -2, -2, -2, -2)};
      overflow: hidden;

      & > video {
        ${position('absolute', 0, 'auto', 'auto', 0)};
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
`;

export default ErrorPage;
