import React from 'react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { spacing } from '../../assets/settings';
import { at, position, hasProp } from '../../assets/utilities';

// reusable components
import SplitText from '../_internal/SplitText';
import DualImage from '../_internal/DualImage';
import Observe from '../_internal/Observe';
import ContactPanel from '../_internal/ContactPanel';
import Footer from '../_internal/Footer';
import Eyebrow from '../_internal/Eyebrow';
import CaseCarousel from '../_internal/CaseCarousel';

const ServiceDetail = (props) => {

  const {
    heroImage,
    heroMobileImage,
    eyebrow,
    heading1,
    heading2,
    description,
    carousel
  } = props.fields;

  const handleObserved = (el) => {
    el.classList.add('-animate-scroll-on');
  };

  return (
    <StyledServiceDetail>
      <div className="hero">
        <DualImage desktop={heroImage} mobile={heroMobileImage} />
        {
          hasProp(eyebrow) &&
          <Eyebrow field={eyebrow} />
        }
        <SplitText
          heading1={heading1}
          heading2={heading2}
          description={description}
        />
      </div>
      <Observe
        className="components"
        callback={handleObserved}
      >
        {props['page-components']}
      </Observe>
      {
        carousel && carousel.items && carousel.items.length && carousel.items.length >= 5 &&
        <CaseCarousel carousel={carousel} />
      }
      <ContactPanel />
      <Footer />
    </StyledServiceDetail>
  );
}

const StyledServiceDetail = styled.div`

  .hero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
    color: white;
    text-align: center;
    min-height: 300px;

    @media ${at('desktop')} {
      padding: ${spacing.desktop.y}px ${spacing.desktop.x}px;
      min-height: 550px;
    }

    &::after {
      content: '';
      ${position('absolute', 0, 0, 0, 0)};
      background-color: #175A77;
      opacity: .7;
    }
  }

  .split-text {
    z-index: 2;

    .heading {
      font-size: 34px;

      @media ${at('desktop')} {
        font-size: 50px;
      }
    }
  }

  .components {

    & > div {

      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

export default withPlaceholder('page-components')(ServiceDetail);
