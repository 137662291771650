// route transition animations
import caseAnimation from '../animations/route-transitions/case';
import workAnimation from '../animations/route-transitions/work';
import bioAnimation from '../animations/route-transitions/bio';
import contactAnimation from '../animations/route-transitions/contact';

const typeMap = new Map([
  ['home', {
    darkLogoText: true
  }],
  ['services', {
    darkLogoText: true
  }],
  ['markets', {
    navShadow: true
  }],
  ['work', {
    animation: workAnimation,
    navShadow: true
  }],
  ['about', {
    darkLogoText: true
  }],
  ['careers', {
    darkLogoText: true
  }],
  ['contact', {
    animation: contactAnimation,
    navShadow: true
  }],
  ['caseStudy', {
    animation: caseAnimation
  }],
  ['bio', {
    animation: bioAnimation
  }],
  ['error', {
    darkLogoText: true
  }],
  ['default', {}]
]);

export default (type) => {
  const data = typeMap.get(type);
  return data ? data : typeMap.get('default');
};
