import React from 'react';
import Component from '../../Component';
import { Link, NavLink } from 'react-router-dom';

class SiteLink extends Component {

  handleClick = (path, e) => {
    if (window.location.pathname === path) {
      e.preventDefault();
      return false;
    }

    return true;
  }

  render() {

    const {
      isNav,
      ...props
    } = this.props;

    const Tag = isNav ? NavLink : Link;

    return (
      <Tag
        {...props}
        onClick={(e) => this.handleClick(props.to, e)}
      />
    );
  }
}

export default SiteLink;
