import React from 'react';
import { Text, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { type, spacing, colors, t, gradients, clamp } from '../../assets/settings';
import { at, contentConstraint, position, hover } from '../../assets/utilities';

// reusable components
import SplitText from '../_internal/SplitText';
import Parallax from '../_internal/Parallax';
import DualImage from '../_internal/DualImage';
import Observe from '../_internal/Observe';
import ContactPanel from '../_internal/ContactPanel';
import Footer from '../_internal/Footer';
import SiteLink from '../_internal/SiteLink';
import Eyebrow from '../_internal/Eyebrow';
import CaseCarousel from '../_internal/CaseCarousel';

const CasePage = (props) => {

  const {
    eyebrow,
    heroImage,
    heroMobileImage,
    heading1,
    heading2,
    challengeText,
    marketsList,
    carousel
  } = props.fields;

  const handleObserved = (el) => {
    el.classList.add('-animate-scroll-on');
  };

  return (
    <StyledCasePage>
      <div className="hero -hero">
        <DualImage desktop={heroImage} mobile={heroMobileImage} />
        <Eyebrow field={eyebrow} />
        <SplitText
          heading1={heading1}
          heading2={heading2}
        />
      </div>
      <Parallax
        maxOffset={-145}
      >
        <div className="overview">
          <div className="overview-left">
            <div className="overview-left-title">The Challenge</div>
            <div className="overview-left-description">
              <p><Text field={challengeText} /></p>
            </div>
          </div>
          <div className="overview-right">
            <div className="overview-right-title">Markets & Services</div>
            <ul className="overview-right-items">
              {
                // Safety check
                marketsList &&

                marketsList.map((item, index) => {
                  const { link } = item.fields;
                  return (
                    <li key={index}>
                      <SiteLink to={link.value.href}>
                        {link.value.text}
                      </SiteLink>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>
      </Parallax>
      <Observe
        className="components"
        callback={handleObserved}
      >
        {props['page-components']}
      </Observe>
      {
        carousel && carousel.items && carousel.items.length && carousel.items.length >= 5 &&
        <CaseCarousel carousel={carousel} />
      }
      <ContactPanel />
      <Footer />
    </StyledCasePage>
  );
}

const StyledCasePage = styled.div`

  & > * {
    z-index: 1;
  }

  .hero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 ${spacing.mobile.x}px;
    color: white;
    text-align: center;

    &::before {
      content: '';
      ${position('absolute', 0, 0, 0, 0)};
      background-color: ${gradients.overlay};
      z-index: 2;
    }

    @media ${at('desktop')} {
      padding: ${spacing.desktop.y}px ${spacing.desktop.x}px;
    }

    & > .split-text {
      ${contentConstraint(1260)};
      z-index: 2;

      .heading {
        font-size: ${type.h2};
      }
    }
  }

  .overview {
    position: relative;
    background-color: white;
    margin: 0 auto;
    padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
    width: calc(100% - ${spacing.mobile.x}px);
    max-width: 1260px;
    z-index: 1;

    @media ${at('desktop')} {
      display: flex;
      width: calc(100% - ${spacing.desktop.x}px);
      padding: 55px 75px;
    }

    &-left,
    &-right {

      &-title {
        position: relative;
        font-size: 12px;
        font-weight: 900;
        letter-spacing: ${type.letterSpacing};
        text-transform: uppercase;
        padding-bottom: 14px;
        border-bottom: 2px solid ${colors.grayBorder};

        &::before {
          content: '';
          ${position('absolute', 'auto', 'auto', 0, 0, 0, '100%')};
          height: 4px;
          width: 40px;
          background-color: ${colors.red};
        }
      }

      &-description {
        margin-top: 20px;
      }
    }

    &-left {

      &-description {

        p {
          font-size: ${clamp(18, 23)};
        }
      }

      @media ${at('desktop')} {
        flex: 1 1 70%;

        &-description {
          padding-right: 60px;
        }
      }
    }

    &-right {
      margin-top: 40px;

      @media ${at('desktop')} {
        flex: 1 0 240px;
        margin-top: 0;
      }

      &-items {
        margin-top: 20px;
        padding: 0;
        list-style-type: none;

        @media ${at('desktop')} {
          margin-top: 24px;
        }

        li {

          a {
            font-size: ${type.p};
            line-height: 2;
            color: black;
            opacity: .4;
            transition: ${t.t} ${t.e};

            ${hover()} {
              color: ${colors.red};
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .components {

    & > div {

      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

export default withPlaceholder('page-components')(CasePage);
