import React from 'react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { spacing, type } from '../../assets/settings';
import { at, position } from '../../assets/utilities';

// components
import DualImage from '../_internal/DualImage';
import SplitText from '../_internal/SplitText';
import Observe from '../_internal/Observe';
import Footer from '../_internal/Footer';
import ContactPanel from '../_internal/ContactPanel';
import Eyebrow from '../_internal/Eyebrow';
import CaseCarousel from '../_internal/CaseCarousel';
import ScrollHint from '../_internal/ScrollHint';

const MarketPage = (props) => {

  const {
    eyebrow,
    heading1,
    heading2,
    description,
    heroImage,
    heroMobileImage,
    carousel
  } = props.fields;

  const handleObserved = (el) => {
    el.classList.add('-animate-scroll-on');
  };

  return (
    <StyledMarketPage>
      <div className="hero -hero">
        <Eyebrow field={eyebrow} />
        <DualImage desktop={heroImage} mobile={heroMobileImage} />
        <SplitText
          heading1={heading1}
          heading2={heading2}
          description={description}
        />
        <ScrollHint />
      </div>
      <Observe
        className="components"
        callback={handleObserved}
      >
        {props['page-components']}
      </Observe>
      {
        carousel && carousel.items && carousel.items.length && carousel.items.length >= 5 &&
        <CaseCarousel carousel={carousel} />
      }
      <ContactPanel />
      <Footer/>
    </StyledMarketPage>
  );
}


const StyledMarketPage = styled.div`

  .hero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
    text-align: center;

    @media ${at(5)} {
      align-items: center;
    }

    @media ${at('desktop')} {
      padding: ${spacing.desktop.y}px ${spacing.desktop.x}px;
    }

    .split-text {
      color: white;

      .heading {
        font-size: 40px;

        @media ${at('tablet')} {
          font-size: ${type.h2};
        }
      }
    }

    .scroll-hint {
      ${position('absolute', 'auto', 'auto', 0)};
    }
  }
`;

export default withPlaceholder('page-components')(MarketPage);
