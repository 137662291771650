import React, { Component } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { at, icon, hasProp, position } from '../../assets/utilities';

// context
import MobileContext from '../../context/mobile';

// components
import StaticLeft from '../_internal/StaticLeft';
import StaticRight from '../_internal/StaticRight';
import MobileCarousel from '../_internal/MobileCarousel';
import DesktopCarousel from '../_internal/DesktopCarousel';

class StaticSlider extends Component {

  static contextType = MobileContext;

  render() {

    const {
      title1,
      title2,
      detail,
      slides,
      type
    } = this.props.fields;

    return (
      <StyledStaticSlider className={`panel ${hasProp(type) && type.value}`}>
      <StaticLeft>
        <div className="text">
          <Text tag="div" className="text-title" field={title1} />
        </div>
        <Text tag="p" className="detail" field={detail} />
      </StaticLeft>
      <StaticRight>
        <div className="text">
          <span className="title-left"><Text field={title1} /> </span><span className="title-right"><Text field={title2} /></span>
        </div>
        <Text tag="p" className="detail" field={detail} />
        {
          this.context ?
          <MobileCarousel slides={slides} />
          :
          <DesktopCarousel slides={slides} />
        }
      </StaticRight>
      </StyledStaticSlider>
    );
  }
}

const StyledStaticSlider = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  z-index: 2;

  @media ${at('desktop')} {
    height: 90vh;
    text-align: left;
  }

  .right {

    @media ${at('desktop')} {
      overflow: visible;
    }

    .detail {
      padding: 0 30px;

      @media ${at('desktop')} {
        padding: 0;
      }
    }
  }

  &.quote,
  &.culture {

    .right {

      .detail {
        position: relative;

        &::before {
          content: '';
          ${position('absolute', 'auto', 'auto', 0, '50%', '-50%', 30)};
          width: 50px;
          height: 4px;
          background-color: white;
        }
      }
    }
  }


  &.quote {

    .right {

      .detail {
        margin-bottom: 40px;
      }
    }

    .mobile-carousel {

      .content-item {

        &-title {
          font-weight: 500;
          text-transform: none;
          line-height: 1.6;

          &::before,
          &::after {
            content: '"';
          }
        }
      }
    }

    .desktop-carousel {

      .item-content {

        &-title {

          &::before {
            ${position('absolute', 15, 'auto', 'auto', 0, 'calc(-100% - 10px)')};
            ${icon('double-quote')};
            font-size: 12px;
          }
        }
      }
    }
  }

  &.culture {

    .right {

      .detail {
        margin-bottom: 90px;
      }
    }

    .mobile-carousel {

      .content-item {

        &-icon {
          ${position('absolute', 0, 'auto', 'auto', '50%', '-50%', -30)};
          font-size: 24px;
        }
      }
    }
  }
`;

export default StaticSlider;
