import { Power2 } from 'gsap';
import enterDefault from './enter-default';
import exitDefault from './exit-default';

export default ({ type, node, mask, exitType, isMobile, timeline }) => {

  if (type === 'enter') {
    timeline = enterDefault(timeline, mask);
  }

  if (type === 'exit') {
    const selectedPanel = document.querySelector('.slider-item.-selected');

    if (!selectedPanel || isMobile) {

      timeline = exitDefault(timeline);

    } else {

      const { left } = selectedPanel.getBoundingClientRect();

      timeline
        .to(selectedPanel, 1,
          {
            x: -(left),
            ease: Power2.easeOut
          })
    }
  }

  return timeline;
}
