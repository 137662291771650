import React, { Component } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { t } from '../../../assets/settings';
import { at, throttle, hasProp, negatePadding, contentConstraint, position } from '../../../assets/utilities';

// components
import DualImage from '../DualImage';

class MobileCarousel extends Component {

  constructor(props) {
    super(props);
    this.lastIndex = this.props.slides.length - 1;
    this.state = {
      startX: null,
      endX: null,
      activeIndex: 0
    }
  }

  prevSlide = () => {
    this.setState((state) => {
      const newIndex = state.activeIndex - 1;
      return {
        activeIndex: newIndex < 0 ? 0 : newIndex
      }
    })
  }

  nextSlide = () => {
    this.setState((state) => {
      const newIndex = state.activeIndex + 1;
      return {
        activeIndex: newIndex > this.lastIndex ? this.lastIndex : newIndex
      }
    })
  }

  handleTouchStart = (e) => {
    e.persist();
    const { clientX } = e.touches[0];
    this.setState({
      startX: clientX
    });
  }

  handleTouchMove = throttle((e) => {
    e.persist();
    const { clientX } = e.touches[0];
    this.setState({ endX: clientX });
  }, 100)

  handleTouchEnd = (e) => {
    e.persist();
    const { startX, endX } = this.state;

    if (endX > startX) {
      // increment down (scroll right, go left)
      this.prevSlide();

    } else if (startX > endX) {
      // increment up (scroll left, go right)
      this.nextSlide();
    }

    this.setState({
      startX: null,
      endX: null
    });
  }

  render() {

    return (
      <StyledMobileCarousel
        className="mobile-carousel"
        activeIndex={this.state.activeIndex}
        disablePrev={this.state.activeIndex === 0}
        disableNext={this.state.activeIndex === this.lastIndex}
      >
        <button
          className="button-prev icon-caret-left"
          onClick={this.prevSlide}
        >Previous</button>
        <button
          className="button-next icon-caret-right"
          onClick={this.nextSlide}
        >Next</button>
        <div className="bg-track"
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
        >
          {
            this.props.slides.map((item, index) => {

              const {
                image,
                mobileImage
              } = item.fields;

              return (
                <div className="bg-item" key={index}>
                  {
                    hasProp(image) &&
                    <div className="bg-item-image">
                      <DualImage desktop={image} mobile={mobileImage} />
                    </div>
                  }
                </div>
              );
            })
          }
        </div>
        <div className="content-track"
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
        >
          {
            this.props.slides.map((item, index) => {

              const {
                title,
                description,
                attribution,
                icon,
                giphy
              } = item.fields;

              return (
                <div className="content-item" key={index}>
                  {
                    hasProp(giphy) &&
                    <div className="content-item-giphy">
                      <div className="content-item-giphy-inner">
                        <video src={giphy.value.src} loop muted autoPlay playsInline></video>
                      </div>
                    </div>
                  }
                  {
                    hasProp(title) &&
                    <Text tag="h2" className="content-item-title" field={title} />
                  }
                  {
                    hasProp(description) &&
                    <Text tag="p" className="content-item-description" field={description} />
                  }
                  {
                    hasProp(attribution) &&
                    <Text tag="div" className="content-item-attribution" field={attribution} />
                  }
                  {
                    hasProp(icon) &&
                    <div className={`content-item-icon icon-${icon.value}`}></div>
                  }
                </div>
              );
            })
          }
        </div>
      </StyledMobileCarousel>
    );
  }
}


const StyledMobileCarousel = styled.div`

  .bg-track,
  .content-track {
    display: flex;
    transform: translateX(calc(-${props => props.activeIndex * 100}vw));
    transition: ${t.t} ${t.e};

    & > * {
      width: 100vw;
      flex: none;
    }
  }

  .bg-track {
    display: flex;
    ${position('absolute', 0, 'auto', 'auto', 0)};
    height: 100%;

    .bg-item {

      &-image {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }
  }

  .content-track {
    position: relative;
    ${negatePadding()};

    .content-item {
      position: relative;
      padding: 0 60px;

      &-title {
        font-size: 20px;
        text-transform: uppercase;
      }

      &-giphy {
        position: relative;
        margin-bottom: 30px;
        box-shadow: 10px 15px 15px 0 rgba(0, 0, 0, .5);
        ${contentConstraint(300)};

        &::before {
          content: '';
          display: block;
          padding-bottom: 71%;
        }

        &-inner {
          ${position('absolute', 0, 0, 0, 0)};
          overflow: hidden;

          & > video {
            ${position('absolute', 0, 'auto', 'auto', 0)};
            min-width: 100%;
            min-height: 100%;
            width: 100%;
            height: auto;
            object-fit: cover;
            box-shadow: 10px 15px 15px 0 rgba(0, 0, 0, .5);
          }
        }
      }

      &-description {
        font-size: 20px;
        line-height: 1.6;
      }

      &-attribution {
        font-size: 18px;

        &::before {
          content: "@";
        }
      }
    }
  }

  .button {

    &-prev,
    &-next {
      ${position('absolute', '50%', 'auto', 'auto', 'auto', 0, '-50%')};
      font-size: 0;
      z-index: 1;

      &::before {
        color: white;
        font-size: 20px;
      }
    }

    &-prev {
      display: ${props => props.disablePrev ? 'none' : 'block'};
      left: 24px;

      @media ${at('desktop')} {
        display: none;
      }
    }

    &-next {
      display: ${props => props.disableNext ? 'none' : 'block'};
      right: 24px;
    }
  }
`;

export default MobileCarousel;
