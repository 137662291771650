import React from 'react';
import Component from '../Component';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// animations
import pageScroll from '../../animations/general/page-scroll';

// components
import StickyMenu from '../_internal/StickyMenu';
import ContactPanel from '../_internal/ContactPanel';
import Footer from '../_internal/Footer';
import Observe from '../_internal/Observe';

import { throttle } from '../../assets/utilities';

class StaticScrollPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      panelIndex: 0,
      scrollIndex: 0
    }
  }

  handleStickyClick = (e) => {
    const { index } = e.target.dataset;
    this.setState({
      panelIndex: index,
      scrollIndex: index
    }, () => {
      const { panelIndex, scrollDown } = this.state,
            destPanel = this.panels[panelIndex];
      pageScroll(window, destPanel, scrollDown);
    });
  }

  handleObserved = throttle((el) => {
    const index = this.panels.indexOf(el);
    this.setState({ scrollIndex: index });
  }, 50);

  componentDidMount() {
    this.panels = Array.from(document.querySelectorAll('.panel'));
    this.window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    
    const {
      stickyTitle
    } = this.props.fields;

    return (
      <StyledStaticScrollPage>
        <Observe
          className="components"
          callback={this.handleObserved}
          options={{ threshold: .8 }}
        >
          {this.props['static-panels']}
        </Observe>
        <StickyMenu
          title={stickyTitle.value}
          components={this.props['static-panels']}
          activeIndex={this.state.scrollIndex}
          handleClick={this.handleStickyClick}
        />
        <ContactPanel />
        <Footer />
      </StyledStaticScrollPage>
    );
  }
}

const StyledStaticScrollPage = styled.div`
  position: relative;
`;

export default withPlaceholder('static-panels')(StaticScrollPage);
