import React, { Component } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

// assets
import { at, hasProp, position } from '../../assets/utilities';

// context
import MobileContext from '../../context/mobile';

// components
import StaticLeft from '../_internal/StaticLeft';
import StaticRight from '../_internal/StaticRight';
import MobileSlider from '../_internal/MobileSlider';
import DualImage from '../_internal/DualImage';

class HappyPanel extends Component {

  static contextType = MobileContext;

  render() {

    const {
      image,
      title1,
      title2,
      listTitle,
      detail,
      values
    } = this.props.fields;

    const happyItems = values.map((item, index) => {
      const { textField } = item.fields;
      return (
        <li className="slider-item happy-list-item" key={index}>
          <div className="slider-item-inner">
            <Text field={textField} />
          </div>
        </li>
      );
    });

    return (
      <StyledHappyPanel
        className="panel"
      >
        <StaticLeft>
          <div className="text">
            <Text tag="div" className="text-title" field={title1} />
          </div>
          <Text tag="p" className="detail" field={detail} />
        </StaticLeft>
        <StaticRight>
          {
            hasProp(image) &&
            <DualImage desktop={image} />
          }
          <div className="text">
            <span className="title-left"><Text field={title1} /> </span><span className="title-right"><Text field={title2} /></span>
          </div>
          <Text tag="p" className="detail" field={detail} />
          <div className="content">
            {
              this.context ?
              <MobileSlider>
                {happyItems}
              </MobileSlider>
              :
              <div className="happy-list">
                <div className="happy-list-title">{hasProp(listTitle) || 'Happiness means we:'}</div>
                <ul>
                  {happyItems}
                </ul>
              </div>
            }
          </div>
        </StaticRight>
      </StyledHappyPanel>
    );
  }
}

const StyledHappyPanel = styled.div`
  display: flex;
  text-align: center;

  @media ${at('desktop')} {
    height: 90vh;
    text-align: left;
  }

  .content {
    margin: 0 -30px;

    @media ${at('desktop')} {
      flex: 0 0 250px;
      margin: 0 60px 0 auto;
    }

    .slider-item-inner {
      font-size: 20px;

      @media ${at('desktop')} {
        font-size: inherit;
      }
    }
  }

  .happy-list {

    @media ${at('desktop')} {
      position: relative;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    &-title,
    &-item {
      font-weight: 600;
      line-height: 1.9;

      @media ${at('desktop')} {
        margin-bottom: 30px;
      }
    }

    &-title {
      font-size: 20px;
      letter-spacing: .5px;
    }

    &-item {
      font-size: 20px;
      text-transform: uppercase;

      @media ${at('desktop')} {
        font-size: 16px;
        text-transform: none;
        position: relative;
        padding-left: 30px;

        &::before {
          content: '';
          width: 8px;
          height: 8px;
          background-color: white;
          ${position('absolute', 10, 'auto', 'auto', 0)};
        }
      }
    }
  }
`;

export default HappyPanel;
