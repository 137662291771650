import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

// assets
import { colors, fonts, spacing, t, clamp } from '../../../assets/settings';
import { icon, at, position, hover } from '../../../assets/utilities';
import logoOutline from '../../../assets/img/is_outline.svg';

// data maps
import navItems from '../../../maps/nav-items';

// components
import SocialLinks from '../SocialLinks';

const MobileMenu = (props) => {

  const {
    isOpen,
    toggleMobileMenu
  } = props;

  return (
    <StyledMobileMenu
      isOpen={isOpen}
    >
      <div className="container">
        <img className="background-img" src={logoOutline} alt="IS Logo Outline" />
        <div className="container-inner">
          <button className="mobile-close" onClick={() => toggleMobileMenu(false)}></button>
          <nav className="nav">
            <ul className="nav-list">
              {navItems.map((item, index) => {
                return (
                  <li key={index} className="nav-list-item">
                    <NavLink
                      to={item.path}
                      onClick={() => toggleMobileMenu(false)}
                    >
                      {item.name}
                    </NavLink>
                  </li>
                )
              })}
            </ul>
          </nav>
          <div className="footer-content">
            <SocialLinks color={'white'}/>
            <p className="disclaimer">Interactive Strategies is a full-service web design agency and digital marketing firm serving Washington DC, VA, MD and Charlotte, NC</p>
          </div>
        </div>
      </div>
    </StyledMobileMenu>
  );
}


const StyledMobileMenu = styled.div`
  ${position('fixed', 0, 0, 0, 0)};
  font-family: ${fonts.header};
  color: white;
  transform: ${props => props.isOpen ? 'none' : 'translateY(-100%)' };
  transition: ${t.t} ${t.e};
  z-index: 10;

  &::before {
    content: '';
    ${position('absolute', 0, 0, 0, 0)};
    background-color: ${colors.teal};
    opacity: .98;
  }

  .container {
    position: relative;
    overflow: hidden;
    height: 100%;
    z-index: 2;

    .background-img {
      ${position('absolute', 0, 0, 'auto', 'auto', '45vh', '-20vh')};
      width: 80vh;
      height: 80vh;
      min-height: 500px;
      min-width: 500px;
      opacity: .12;
      z-index: 1;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      overflow-y: scroll;

      & > * {
        flex: none;
      }
    }
  }

  .nav {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10vh;
    margin-bottom: 30px;
    z-index: 2;


    &-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      text-align: center;

      &-item {
        transition: ${t.t} ${t.e};

        a {
          color: inherit;
          font-size: ${clamp(36, 60)};
          line-height: 1.7;
          font-weight: bold;
        }

        ${hover()} {
          transform: scale(1.3);
          transition: ${t.t} ${t.e};
        }
      }
    }
  }

  .footer-content {

    & > div {
      display: flex;
      justify-content: center;

      a {
        margin: 0 10px;
        font-size: 26px;
      }
    }

    .disclaimer {
      font-size: 13px;
      font-weight: 300;
      line-height: 1.8;
      margin: 0 auto;
      text-align: center;
      padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
    }
  }

  .mobile-close {
    ${position('absolute', 50)};
    right: ${spacing.mobile.x}px;
    height: 18px;
    width: 18px;
    transform: translateY(-50%);
    padding: 0;
    z-index: 3;

    &::before {
      ${icon('close')};
      color: white;
    }
  }

  @media ${at('desktop')} {
    display: none;
  }
`;

export default MobileMenu;
