import { Expo } from 'gsap';
import enterDefault from './enter-default';
import exitDefault from './exit-default';

export default ({ type, node, mask, exitType, isMobile, timeline }) => {

  if (type === 'enter') {

    if (isMobile || exitType !== 'work') {
      timeline = enterDefault(timeline, mask);
    }

    const heroContent = node.querySelectorAll('.hero .hero-eyebrow, .hero .heading'),
          overview = node.querySelector('.overview');

    if (heroContent && overview) {
      timeline
        .from(heroContent[0], 1, { y: '100%', ease: Expo.easeOut }, 0)
        .from(heroContent[1], 1, { y: '100%', ease: Expo.easeOut }, 0)
        .from(overview, .8, { y: '100%', ease: Expo.easeOut }, .2);
    }
  }

  if (type === 'exit') {
    timeline = exitDefault(timeline);
  }

  return timeline;
}
