import { TimelineMax as Timeline, Expo, ScrollToPlugin } from 'gsap/all';

import { isWindow } from '../../assets/utilities';

if (isWindow()) {
  window.ScrollToPlugin = ScrollToPlugin;
}

export default (scrollContainer, destNode, scrollDown) => {

  const timeline = new Timeline({ paused: true });

  timeline
    .to(scrollContainer, 1.3,
      {
        scrollTo: { y: destNode, autoKill: false },
        ease: Expo.easeInOut
      });

  const destText = destNode && destNode.querySelector('.text');

  if (destText) {
    if (scrollDown) {
      timeline
        .from(destText, .1,
          { y: '100px', autoAlpha: 0, ease: Expo.easeInOut }, .7)
        .to(destText, .1,
          { autoAlpha: 1, ease: Expo.easeOut }, .7)
    } else {
      timeline
        .from(destText, .1,
          { y: '-100px', autoAlpha: 0, ease: Expo.easeInOut }, .7)
        .to(destText, .1,
          { autoAlpha: 1, ease: Expo.easeOut }, .7);
    }
  }


  timeline.play();
}
