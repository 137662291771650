import React from 'react';
import SiteLink from '../SiteLink';

const DynamicTag = ({ Tag, children, ...props }) => {

  if (Tag === 'a') {

    if (props.href && props.href.indexOf('http') > -1) {
      // external link
      return <a href={props.href} target="_blank" rel="noopener noreferrer" {...props}>{children}</a>;
    }
    // internal link
    return <SiteLink to={props.href} {...props}>{children}</SiteLink>;
  } else {
      return <Tag {...props}>{children}</Tag>;
  }
}

export default DynamicTag;
