import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

//assets
import { t, spacing, gradients, clamp } from '../../assets/settings';
import { at, position, hover } from '../../assets/utilities';

// components
import DualImage from '../_internal/DualImage';
import DynamicTag from '../_internal/DynamicTag';

const GridSixItem = (props) => {

  const {
    image,
    title,
    text,
    link
  } = props.fields;

  return (
    <StyledGridSixItem
      className="grid-item"
      href="/"
    >

      <DynamicTag
        Tag={link ? 'a' : 'div'}
        className="grid-item-inner"
        href={link ? link.value.href : null}
      >
        <DualImage desktop={image} />
        <Text tag="h1" className="title" field={title} />
        <Text tag="h2" className="text" field={text} />
      </DynamicTag>
    </StyledGridSixItem>
  );
}

const StyledGridSixItem = styled.div`
  min-height: 320px;
  max-height: calc(100vh - 105px);
  height: 100vw;
  color: white;
  transition: ${t.t} ${t.e};

  .grid-item-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: ${spacing.mobile.y}px ${spacing.mobile.x}px;
    flex: none;
    overflow: hidden;

    &::before {
      content: '';
      ${position('absolute', 0, 0, 0, 0)};
      background-color: ${gradients.overlay};
      opacity: 0;
      z-index: 1;
      transition: ${t.t} ${t.e};
    }
  }

  .title,
  .text {
    margin: 0;
    z-index: 3;
    font-weight: 300;
    line-height: 1.125;
    transition: ${t.t} ${t.e};
    pointer-events: none;
  }

  .title {
    ${position('absolute', 'auto', 'auto', spacing.mobile.y, spacing.mobile.x)}
    font-size: ${clamp(30, 40)};
  }

  .text {
    position: relative;
    text-align: center;
    font-size: 42px;
    opacity: 0;
  }

  .-ready & {

    @media ${at('tablet')} {

      ${hover()} {
        transform: scale(1.05);
        z-index: 4;

        .grid-item-inner {

          &::before {
            opacity: 1;
          }
        }

        .text {
          opacity: 1;
        }
      }

      /* tablet hover origins */

      &:nth-child(1) {
        transform-origin: top left;
      }

      &:nth-child(2) {
        transform-origin: top right;
      }

      &:nth-child(2n + 3) {
        transform-origin: center left;
      }

      &:nth-child(2n + 4) {
        transform-origin: center right;
      }

      &:nth-child(5) {
        transform-origin: bottom left;
      }

      &:nth-child(6) {
        transform-origin: bottom right;
      }
    }

    /* desktop hover origins */
    @media ${at('desktop')} {

      &:nth-child(1) {
        transform-origin: top left;
      }

      &:nth-child(2) {
        transform-origin: top center;
      }

      &:nth-child(3) {
        transform-origin: top right;
      }

      &:nth-child(4) {
        transform-origin: bottom left;
      }

      &:nth-child(5) {
        transform-origin: bottom center;
      }

      &:nth-child(6) {
        transform-origin: bottom right;
      }
    }
  }

  @media ${at('tablet')} {
    width: calc(100% / 2);
    height: calc(100vh / 2);
    padding: ${spacing.desktop.y} ${spacing.desktop.x};
  }


  @media ${at('desktop')} {
    width: calc(100% / 3);
    height: calc(100vh / 2);

    .text {
      opacity: 0;
      transition: ${t.t} ${t.e};
    }

    .title {
      bottom: 30px;
      left: 30px;
    }
  }


`;

export default GridSixItem;
